import Vue from 'vue'
import LocalStorageController from './controller'

let instance

export const getInstance = () => instance

export const useLocalStorage = ({}) => {
  if(instance) return instance;

  instance = new Vue({
    data() {
      return {
        ctrl: null,
        theme: this.$basil.get(this.ctrl, 'theme', 'dark')
      }
    },

    methods: {
      setLang(value) {
        this.ctrl.lang = value
      },

      getLang() {
        return this.ctrl.lang
      },

      setTheme(value) {
        this.theme = value
        this.ctrl.theme = value
      },

      getTheme() {
        return this.ctrl.theme
      },

      setSelectedWallet(value) {
        this.ctrl.selectedWallet = value
      },

      getSelectedWallet() {
        return this.ctrl.selectedWallet
      }
    },

    created() {
      this.ctrl = new LocalStorageController()

      if(this.ctrl.theme === 'light') {
        this.theme = 'light'
      }
    },

    mounted() {

    }
  })

  return instance
}

export const LocalStoragePlugin = {
  install(Vue, options) {
    Vue.prototype.$localStorage = useLocalStorage(options)
  }
}
