import { LocalStoragePlugin } from './localStorage/instance'
import { MessagePlugin } from './message/instance'
import { NotificationPlugin } from './notification/instance'
import { UserPlugin } from './user/instance'
import { WalletPlugin } from './wallet/instance'
import { PusherPlugin } from './pusher/instance'
import { ExternalWalletPlugin } from './externalWallet/instance'
import { MessagingPlugin } from './messaging/instance'
import { PoapPlugin } from './poap/instance'
import { BrandsPlugin } from './brands/instance'
import { DashboardPlugin } from './dashboard/instance'
import { VouchersPlugin } from './vouchers/instance'
import { ChallengesPlugin } from './challenges/instance'

const datalayer = {}

datalayer.install = (Vue, { transports, router }) => {
  // Install LocalStoragePlugin
  Vue.use(LocalStoragePlugin, {})

  // Install PostMessagePlugin
  Vue.use(MessagePlugin, { transports, router })

  // Install UserPlugin
  Vue.use(UserPlugin, { transports, router })

  // Install PusherPlugin
  Vue.use(PusherPlugin, { router, transports })

  // Install WalletPlugin
  Vue.use(WalletPlugin, { transports, router })

  // Install NotificationPlugin
  Vue.use(NotificationPlugin, { transports })

  Vue.use(ExternalWalletPlugin, { transports })

  Vue.use(MessagingPlugin, { transports })

  Vue.use(PoapPlugin, { transports })

  Vue.use(BrandsPlugin, { transports })

  Vue.use(DashboardPlugin, { transports })

  Vue.use(VouchersPlugin, { transports })

  Vue.use(ChallengesPlugin, { transports })
}

export default datalayer
