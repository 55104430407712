import Vue from 'vue'
import Config from '../../config'
import Pusher from 'pusher-js'

let instance

export const getInstance = () => instance

export const usePusher = ({ router, transports }) => {
  if(instance) {
    return instance
  }

  instance = new Vue({
    data() {
      return {
        pusher: null,
        channel: null,
        channelName: null
      }
    },

    watch: {},

    methods: {
      connect(appKey, cluster) {
        if(this.$basil.isNil(appKey) || this.$basil.isNil(cluster)) {
          return;
        }

        const csrfToken = this.$basil.get(transports, 'cp.defaults.headers.common["X-CSRF-TOKEN"]')
        Pusher.Runtime.createXHR = () => {
          const xhr = new XMLHttpRequest();
          xhr.withCredentials = true;
          return xhr;
        };

        this.pusher = new Pusher(appKey, {
          cluster,
          authEndpoint: Config.pusherEndpoint,
          auth: {
            headers: {
              'X-CSRF-TOKEN': csrfToken,
            }
          }
        });

        this.channelName = `presence-${this.$user.walletAddress}`;

        this.channel = this.$pusher.pusher.subscribe(this.channelName);
        this.channel.bind('authorization_requested', (data) => {
          router.push({
            name: 'sayl-connect_user-sign',
            query: {
              claims: (this.$basil.get(data, 'claims', []) || []).join(','),
              origin: 'pusher',
            }
          })
        })
      },

      disconnect() {
        if(!this.$basil.isNil(this.pusher)) {
          this.pusher.unsubscribe(this.channelName);
          this.pusher = null;
        }
      }
    }
  })

  return instance
}

export const PusherPlugin = {
  install(Vue, options) {
    Vue.prototype.$pusher = usePusher(options)
  }
}
