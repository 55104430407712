import { basil } from '@spices/basil'
import { DateTime } from 'luxon'
import Nfts from './nft/nfts'

/**
 * @class
 */
export default class Wallet {
  /**
   *
   * @param {Object} data
   * @param {Object} data.last_updated
   * @param {Array} data.nfts
   * @param {Object} data.sayl_token
   */
  constructor({ last_updated, nfts, sayl_token }) {
    this._nfts = new Nfts(nfts)
    this._saylToken = sayl_token

    this._created = DateTime.now()
  }

  requireReload() {
    if(!this._created) return true

    let diff = DateTime.now().diff(this._created, 'minutes').toObject()

    if(diff.minutes >= 2) {
      return true
    }

    return false
  }

  ////////////////////////////////
  // Getters
  /**
   * Get the categories for the filters based on the brand NFTS
   * @property {Array} categories
   * @readonly
   * @return {Array}
   */
  get categories() {
    let ret = []

    this._nfts.forEach((nft) => {
      let brand = basil.get(nft, 'saylAppBrand.name', null)
      if (!basil.isNil(brand) && !ret.includes(brand)) {
        ret.push(basil.get(nft, 'saylAppBrand.name'))
      }
    })

    return ret.map((r) => {
      return {
        label: r,
        value: r
      }
    })
  }

  /**
   * @property {Object}
   */
  get saylToken() {
    return this._saylToken
  }

  get nfts() {
    return this._nfts
  }

  // Methods
  getDuplicate(nft) {
    let ret = this.nfts.find(n => {
      $console.info({
        n,
        nft,
        'n.tokenId': n.tokenId,
        'nft.nft.tokenId': nft.nft.token_id,
        'n.assetContract.token_id': n.assetContract.token_id,
        'nft.nft.asset_contract': nft.nft.asset_contract
      })
      return (n.tokenId === nft.nft.token_id && n.assetContract.token_id === nft.nft.asset_contract)
    });
    return ret;
  }

  /**
   * Get a NFT by it's id
   *
   * @param {String} id
   * @returns
   */
  getNft(id) {
    let ret = this.nfts.find(n => n.id === id)
    return ret
  }

  /**
   * Sort alphabeticly the nfts
   *
   * @param {Object} a
   * @param {Object} b
   * @param {String} direction
   * @returns
   */
  abcSort(a, b, direction) {
    if (direction === "DESC" && a.name.toLowerCase() > b.name.toLowerCase()) { return -1 }
    if (direction === "DESC" && a.name.toLowerCase() < b.name.toLowerCase()) { return 1 }
    if (direction === "ASC" && a.name.toLowerCase() < b.name.toLowerCase()) { return -1 }
    if (direction === "ASC" && a.name.toLowerCase() > b.name.toLowerCase()) { return 1 }
    return 0
  }

  invalidate() {
    this._created = null
  }
}
