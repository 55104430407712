import HouseIcon from '@/icons/house.vue'
import GraphIcon from '@/icons/graph.vue'
import MessageDotsIcon from '@/icons/message-dots.vue'
import TicketIcon from '@/icons/ticket.vue'
import EggIcon from '@/icons/egg.vue'
import AwardIcon from '@/icons/award.vue'
import CogIcon from '@/icons/cog.vue'
import UserIcon from '@/icons/user.vue'

export default [
  {
    slug: 'dashboard',
    route: 'sayl-connect_dashboard-dashboard',
    label: 'conn3ct-wallet.route_home',
    icon: HouseIcon,
    desktop: true,
    mobile: true,
    burger: true,
  },

  {
    slug: 'claims',
    route: 'sayl-connect_wallet-claimables',
    label: 'conn3ct-wallet.route_collect',
    icon: GraphIcon,
    desktop: true,
    mobile: true,
    dashboard: true,
    burger: true,
  },

  {
    slug: 'messages',
    route: 'sayl-connect_messaging-messaging',
    label: 'conn3ct-wallet.route_messages',
    icon: MessageDotsIcon,
    desktop: true,
    mobile: false,
    dashboard: true,
    burger: true,
  },

  {
    slug: 'vouchers',
    route: 'sayl-connect_vouchers-vouchers',
    label: 'conn3ct-wallet.route_vouchers',
    icon: TicketIcon,
    desktop: true,
    mobile: true,
    dashboard: true,
    burger: true,
  },

  {
    slug: 'poaps',
    route: 'sayl-connect_poaps-poaps',
    label: 'conn3ct-wallet.route_poaps',
    icon: EggIcon,
    desktop: true,
    mobile: true,
    dashboard: true,
    burger: true,
  },

  {
    slug: 'challenges',
    route: 'sayl-connect_challenges-challenges',
    label: 'conn3ct-wallet.route_challenges',
    icon: AwardIcon,
    desktop: true,
    mobile: false,
    dashboard: true,
    burger: true,
  },

  {
    slug: 'profile',
    route: 'sayl-connect_user-profile',
    label: 'conn3ct-wallet.route_profile',
    icon: UserIcon,
    desktop: true,
    mobile: false,
    dashboard: true,
    burger: true,
  },

  {
    slug: 'settings',
    route: 'sayl-connect_user-settings',
    label: 'conn3ct-wallet.route_settings',
    icon: CogIcon,
    desktop: false,
    mobile: false,
    dashboard: true,
    burger: true,
  },
]
