import Actions from './actions'
import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import config from './config'

/**
 * @class
 */
export default class MessageController {
  /**
   * @constructor
   */
  constructor({ transports, router }) {
    this._api = new CurryApi({ config, transports })

    this._origin = null
    this._originStatus = null
    this._linked = false
    this._data = {}
    this._name = null

    this._ctx = this
    this._router = router
  }

  //////////////////////////////
  // Methods
  init() {
    window.addEventListener('message', (e) => {
      this._validateMessage(e)
        .then((data) => {
          switch(basil.get(data, 'type')) {
            case '@conn3ct/data':
              this._data[basil.get(data, 'payload.field')] = basil.get(data, 'payload.data')
              break

            case '@conn3ct/action':
              let name = basil.get(data, 'payload.name')
              let uuid = basil.get(data, 'payload.uuid')
              let action = Actions.ALL.find(a => a.name === name)

              if(!basil.isNil(action)) {
                action.event(data, this._ctx, e)
                  .then((res) => this.onAction({ name, ...data, result: res, uuid }))
                  .catch((e) => this.onAction({ name, ...data, error: e, uuid }))
              }
              break
          }
        })
        .catch(() => {})
    })
  }

  _validateMessage(e) {
    return new Promise((resolve, reject) => {
      try {
        const data = JSON.parse(e.data)

        if(this._originStatus === 'blocked') {
          return reject('origin blocked')
        }

        if(basil.get(data, 'payload.name') === 'enable') {
          this._origin = e.origin

          this.checkOrigin(e.origin)
            .then(({ status, name }) => {
              this._originStatus = status
              this._name = name

              if(this._originStatus === 'blocked') {
                return reject('origin blocked')
              } else {
                resolve(data)
              }
            })
        } else {
          resolve(data)
        }
      } catch(e) {
        return reject(e)
      }
    })
  }

  checkOrigin(origin) {
    return new Promise((resolve, reject) => {
      const payload = { origin }

      this._api.get({ type: 'origin', payload })
        .then((s) => Promise.resolve(s.data))
        .then((s) => resolve(s))
        .catch((e) => reject(e))
    })
  }

  onAction(payload) {
    if(basil.isNil(window.opener)) {
      return
    }

    window.opener.postMessage(JSON.stringify({
      type: '@conn3ct/action',
      payload,
    }), '*')
  }

  sendData(field, data) {
    if(basil.isNil(window.opener)) {
      return
    }

    window.opener.postMessage(JSON.stringify({
      type: '@conn3ct/data',
      payload: {
        field,
        data
      }
    }), '*');
  }

  getData(field) {
    if(basil.isNil(window.opener)) {
      return
    }

    window.opener.postMessage(JSON.stringify({
      type: '@conn3ct/request-data',
      payload: { field }
    }), '*');
  }
}
