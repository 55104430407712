<script>
export default {
  name: 'LayoutPage',

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    back: {
      type: Object
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasTitle() {
      return !!this.title
    },

    hasSubtitle() {
      return !!this.subtitle
    }
  },

  methods: {
    onBack() {
      this.$router.push(this.back).catch(() => {})
    }
  },
}
</script>

<template>
  <div class="layout-page">
    <header
      v-if="back || title"
      class="layout-page__header"
    >
      <div
        v-if="back"
        class="layout-page__back"
        :class="{
          '-no-subtitle': !hasSubtitle
        }"
      >
        <router-link
          :to="back"
          class="link"
        >
          <icon-arrow />
        </router-link>
      </div>

      <h1
        v-if="title"
        class="layout-page__title"
        :class="{
          '-no-subtitle': !hasSubtitle,
          '-no-back': !back
        }"
      >
        <span v-if="hasTitle">{{ title }}</span>
      </h1>

      <p
        v-if="hasSubtitle"
        class="layout-page__subtitle"
      >{{ subtitle }}</p>
    </header>

    <main class="layout-page__body">
      <div
        v-if="loading"
        class="layout-page__loader"
      >
        <ui-loader :size="$pepper.Size.L" />
      </div>

      <slot v-else></slot>
    </main>
  </div>
</template>
