
/**
 * @class
 */
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { getCountryCallingCode } from 'libphonenumber-js'

export default class Country {
  /**
   *
   * @param {Object} options
   * @param {Object} options.created
   * @param {String} options.id
   * @param {String} options.iso_code_2
   * @param {String} options.iso_code_3
   * @param {Object} options.modified
   * @param {String} options.name
   */
  constructor({
    created = null,
    id = null,
    iso_code_2 = null,
    iso_code_3 = null,
    modified = null,
    name = null
  }) {
    try {
      this._created = created
      this._id = id
      this._isoCode2 = iso_code_2
      this._isoCode3 = iso_code_3
      this._modified = modified
      this._name = name
      this._callingCode = `+${getCountryCallingCode(this._isoCode2)}`
    } catch(e) {
      this._callingCode = null
      $console.info(e)
    }
  }

  ////////////////////////////////
  // Getters
  /**
   * @property {Object} created
   * @readonly
   * @return {Object}
   */
  get created() {
    return this._created
  }

  get callingCode() {
    return this._callingCode
  }

  /**
   * @property {String} id
   * @readonly
   * @return {String}
   */
  get id() {
    return this._id
  }

  /**
   * @property {String} isoCode2
   * @readonly
   * @return {String}
   */
  get isoCode2() {
    return this._isoCode2
  }

  /**
   * @property {String} isoCode3
   * @readonly
   * @return {String}
   */
  get isoCode3() {
    return this._isoCode3
  }

  /**
   * @property {String} label
   * @readonly
   * @return {String}
   */
  get label() {
    return this._name
  }

  /**
   * @property {Object} modified
   * @readonly
   * @return {Object}
   */
  get modified() {
    return this._modified
  }

  /**
   * @property {String} name
   * @readonly
   * @return {String}
   */
  get name() {
    return this._name
  }

  /**
   * @property {String} value
   * @readonly
   * @return {String}
   */
  get value() {
    return this._id
  }

  get flag() {
    return getUnicodeFlagIcon(this.isoCode2)
  }
}
