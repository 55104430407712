import Nft from "./nft/nft"
import { DateTime } from 'luxon'

export default class NftTransfer {
  constructor({
    id = null,
    nft_id = null,
    token_id = null,
    serial = null,
    nft = null,
    status = null,
    from_wallet_address = null,
    to_email = null,
    to_wallet_address = null,
    revert_transfer_on = null,
    escrow_date = null,
    revert_date = null,
    created = null
  }) {
    this._id = id
    this._nftId = nft_id
    this._tokenId = token_id
    this._serial = serial
    this._nft = nft ? new Nft(nft) : null
    this._status = status
    this._fromWalletAddress = from_wallet_address
    this._toEmail = to_email
    this._toWalletAddress = to_wallet_address
    this._revertTransferOn = revert_transfer_on ? DateTime.fromISO(revert_transfer_on) : null
    this._escrowDate = escrow_date ? DateTime.fromISO(escrow_date) : null
    this._revertDate = revert_date ? DateTime.fromISO(revert_date) : null
    this._created = created ? DateTime.fromISO(created) : null
  }

  static statuses = {
    QUEUED: 10,
    PENDING_WALLET_CREATION: 20,
    PENDING_CLAIM: 30,
    CLAIMED_PENDING_TRANSFER: 35,
    EXECUTED: 40,
    CONFIRMED: 50,
    NOT_ENOUGH_FUNDS: 60,
    ERROR: 70,
    EXPIRED: 80,
    REVERTED: 90,
  }

  get id() {
    return this._id
  }

  get nftId() {
    return this._nftId
  }

  get tokenId() {
    return this._tokenId
  }

  get serial() {
    return this._serial
  }

  get nft() {
    return this._nft
  }

  get status() {
    return this._status
  }

  get fromWalletAddress() {
    return this._fromWalletAddress
  }

  get toEmail() {
    return this._toEmail
  }

  get toWalletAddress() {
    return this._toWalletAddress
  }

  get revertTransferOn() {
    return this._revertTransferOn
  }

  get escrowDate() {
    return this._escrowDate
  }

  get revertDate() {
    return this._revertDate
  }

  get created() {
    return this._created
  }
}
