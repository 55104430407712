export default class Brand {
  /**
   *
   * @param {Object} data
   * @param {String} data.banner
   * @param {Array} data.country_ids
   * @param {Object} data.created
   * @param {String} data.description
   * @param {String} data.id
   * @param {String} data.logo
   * @param {Object} data.modified
   * @param {String} data.name
   * @param {String} data.project_id
   */
  constructor({
    banner = null,
    country_ids = [],
    created = null,
    description = null,
    id = null,
    logo = null,
    modified = null,
    name = null,
    project_id = null,
    images = []
  }) {
    this._banner = banner
    this._countryIds = country_ids
    this._created = created
    this._description = description
    this._id = id
    this._logo = logo
    this._modified = modified
    this._name = name
    this._projectId = project_id
    this._images = images
  }

  ////////////////////////////////
  // Getters
  /**
   * @property {String} banner
   * @readonly
   * @return {String} banner
   */
  get banner() {
    return this._images.find(i => i.field_name === 'banner') ?? this._banner
  }

  /**
   * @property {Array} countryIds
   * @readonly
   * @return {Array} countryIds
   */
  get countryIds() {
    return this._countryIds
  }

  /**
   * @property {Object} created
   * @readonly
   * @return {Object} created
   */
  get created() {
    return this._created
  }

  /**
   * @property {String} description
   * @readonly
   * @return {String} description
   */
  get description() {
    return this._description
  }

  /**
   * @property {String} id
   * @readonly
   * @return {String} id
   */
  get id() {
    return this._id
  }

  /**
   * @property {String} logo
   * @readonly
   * @return {String} logo
   */
  get logo() {
    return this._images.find(i => i.field_name === 'logo') ?? this._logo
  }

  /**
   * @property {Object} modified
   * @readonly
   * @return {Object} modified
   */
    get modified() {
    return this._modified
  }

  /**
   * @property {String} name
   * @readonly
   * @return {String} name
   */
  get name() {
    return this._name
  }

  /**
   * @property {String} projectId
   * @readonly
   * @return {String} projectId
   */
  get projectId() {
    return this._projectId
  }
}
