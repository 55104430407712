
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33325 9.1665V9.99984C8.33325 11.3807 9.45242 12.4998 10.8333 12.4998H12.4999C13.8808 12.4998 14.9999 11.3807 14.9999 9.99984V4.1665C14.9999 2.78567 13.8808 1.6665 12.4999 1.6665H10.8333C9.45242 1.6665 8.33325 2.78567 8.33325 4.1665V4.1665" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.6667 9.99984V9.1665C11.6667 7.78567 10.5475 6.6665 9.16667 6.6665H7.5C6.11917 6.6665 5 7.78567 5 9.1665V14.9998C5 16.3807 6.11917 17.4998 7.5 17.4998H9.16667C10.5475 17.4998 11.6667 16.3807 11.6667 14.9998V14.9998" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'LinkIcon'
}
</script>
