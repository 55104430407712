import Vue from 'vue'
import { CurryApi } from '@spices/curry'
import config from './config'
import Dashboard from './model'

let instance

export const getInstance = () => instance

export const useDashboard = ({ transports }) => {
  if(instance) {
    return instance
  }

  instance = new Vue({
    data() {
      return {
        api: null,

        dashboard: null
      }
    },

    methods: {
      async get(invalidate = false) {
        if(invalidate && this.dashboard) {
          this.dashboard.invalidate()
        }

        if(this.dashboard && !this.dashboard.requireReload()) {
          return this.dashboard
        }

        try {
          let { data } = await this.api.get({ type: 'entity' })
          this.dashboard = new Dashboard(data)

          return this.dashboard
        } catch(e) {
          this.reset()
          throw e
        }
      },

      reset() {
        this.dashboard = null
      }
    },

    created() {
      this.api = new CurryApi({ config, transports })
    }
  })

  return instance
}

export const DashboardPlugin = {
  install(Vue, options) {
    Vue.prototype.$dashboard = useDashboard(options)
  }
}
