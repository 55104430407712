export default [
  {
    component: () => import('./views/dashboard.vue'),
    path: '/dashboard',
    name: 'sayl-connect_dashboard-dashboard',
    meta: {
      module: 'dashboard',
      requireAuth: true
    }
  }
]
