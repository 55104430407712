export default [
  {
    component: () => import('./views/login'),
    path: '/login',
    name: 'sayl-connect_landing-login',
  },

  {
    component: () => import('./views/register'),
    path: '/register',
    name: 'sayl-connect_landing-register',
  },

  {
    component: () => import('./views/register-phone'),
    path: '/register/phone',
    name: 'sayl-connect_landing-register-phone',
  },

  {
    component: () => import('./views/reset-password'),
    path: '/reset-password',
    name: 'sayl-connect_landing-reset-password',
  },

  {
    component: () => import('./views/reset-password-confirmation'),
    path: '/reset-password-confirmation',
    name: 'sayl-connect_landing-reset-password-confirmation',
  },

  {
    component: () => import('../user/views/blocked-origin.vue'),
    path: '/blocked-origin',
    name: 'sayl-connect_blocked-origin',
    meta: {
      module: 'user',
    }
  },
]
