export default {
  fqn: 'ifm.datalayer.localStorage',

  items: {
    lang: {},

    theme: {},

    selectedWallet: {}
  }
}
