export default class Message {
  constructor({
    id = null,
    nft_id = null,
    message = null,
    transaction_id = null,
    transaction_timestamp = null,
    created = null,
    is_read = false,
    subject = null,
    pixel = null
  }) {
    this._created = created
    this._id = id
    this._nftId = nft_id
    this._message = message
    this._transactionId = transaction_id
    this._transactionTimestamp = transaction_timestamp
    this._isRead = is_read
    this._subject = subject
    this._pixel = pixel
  }

  get created() {
    return this._created
  }

  get id() {
    return this._id
  }

  get nftId() {
    return this._nftId
  }

  get message() {
    return this._message
  }

  get transactionId() {
    return this._transactionId
  }

  get transactionTimestamp() {
    return this._transactionTimestamp
  }

  get isRead() {
    return this._isRead
  }

  get subject() {
    return this._subject
  }

  get pixel() {
    return this._pixel
  }
}
