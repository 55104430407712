
<template>
  <svg class="icon-burger-menu" :class="classes" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect class="icon-burger-menu__line top" id="burger-menu-top" y="12" width="20" height="2" fill="black"/>
    <rect class="icon-burger-menu__line mid" id="burger-menu-mid" y="6" width="20" height="2" fill="black"/>
    <rect class="icon-burger-menu__line bot" id="burger-menu-bot" y="0" width="20" height="2" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'BurgerMenuIcon',

  props: {
    openned: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    openned() {
      if(this.openned) {
        document.getElementById('burger-menu-top').setAttribute('y', '6')
        document.getElementById('burger-menu-bot').setAttribute('y', '6')

        return
      }

      document.getElementById('burger-menu-top').setAttribute('y', '12')
      document.getElementById('burger-menu-bot').setAttribute('y', '0')
    }
  },

  computed: {
    classes() {
      return {
        '-openned': this.openned
      }
    }
  }
}
</script>
