import { getInstance as UserInstance } from "../user/instance"
import { getInstance as WalletInstance } from "../wallet/instance"

/**
 * @readonly
 * @property
 */
const Data = {}

const ADDRESS = {
  name: 'address',
  path: 'user.walletAddress',
  instance: UserInstance
}
Data.ADDRESS = ADDRESS

const CHAIN = {
  name: 'chain',
  path: 'user.chain',
  instance: UserInstance
}
Data.CHAIN = CHAIN

const TOKEN = {
  name: 'sayl_token',
  path: 'wallet.saylToken.balance',
  instance: WalletInstance
}
Data.TOKEN = TOKEN

const JWT = {
  name: 'jwt',
  path: 'jwt',
  user: WalletInstance
}
Data.JWT = JWT;

Data.ALL = [
  ADDRESS,
  CHAIN,
  TOKEN
]

export default Data
