<script>

export default {
  name: 'LayoutEntity',

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    back: {
      type: Object
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    attrs() {
      return {
        title: this.title,
        subtitle: this.subtitle,
        back: this.back,
        loading: this.loading
      }
    },

    hasBanner() {
      return this.$slots?.banner
    },

    hasInformations() {
      return this.$slots?.informations
    }
  },
}
</script>

<template>
  <div class="layout-entity">
    <div
      v-if="hasBanner"
      class="layout-entity__banner"
    >
      <slot name="banner"></slot>
    </div>

    <layout-page v-bind="attrs">
      <div class="layout-entity__body">
        <div
          class="layout-entity__entity"
          :class="{
            '-no-banner': !hasBanner
          }"
        >
          <slot></slot>
        </div>

        <div
          v-if="hasInformations"
          class="layout-entity__informations"
          :class="{
            '-no-banner': !hasBanner
          }"
        >
          <slot name="informations"></slot>
        </div>
      </div>
    </layout-page>
  </div>
</template>
