export default {
  fqn: 'ifm.conn3ct.challenges',

  endpoints: {
    list: {
      get: '/user/challenges'
    },

    entity: {
      get: '/user/challenge/$id'
    },

    count: {
      get: '/user/challenges/count'
    },

    challengeCompletions: {
      get: '/user/challenge/$id/completions'
    }
  },

  transport: 'cp'
}
