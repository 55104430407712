import Vue from 'vue'
import { CurryApi } from '@spices/curry'
import config from './config'
import Challenges from './models/challenges'
import Challenge from './models/challenge'
import Completion from './models/completion'

let instance

export const getInstance = () => instance

export const useChallenges = ({ transports }) => {
  if(instance) return instance

  instance = new Vue({
    data: () => {
      return {
        api: null,
        challenges: null,
      }
    },

    computed: {
      isEmpty() {
        return !this.challenges?.length
      }
    },

    methods: {
      async getChallenges(invalidate = false) {
        if(!invalidate && this.challenges?.isCacheValid) {
          return this.challenges.list ?? []
        }

        try {
          const { data } = await this.api.get({ type: 'list' })

          this.challenges = new Challenges(data)

          return this.challenges
        } catch(e) {
          throw e
        }
      },

      async getChallenge(id) {
        try {
          const { data } = await this.api.get({ type: 'entity', payload: { id } })

          return new Challenge(data)
        } catch(e) {
          throw e
        }
      },

      async count() {
        try {
          const { data } = await this.api.get({ type: 'count' })

          return data?.count ?? 0
        } catch(e) {
          throw e
        }
      },

      async getCompletions(id) {
        try {
          const { data } = await this.api.get({ type: 'challengeCompletions', payload: { id }})

          return data?.map(c => new Completion(c)) ?? []
        } catch(e) {
          throw e
        }
      },

      reset() {
        this.challenges = null
      }
    },

    created() {
      this.api = new CurryApi({ config, transports })
    }
  })

  return instance
}

export const ChallengesPlugin = {
  install(Vue, options) {
    Vue.prototype.$challenges = useChallenges(options)
  }
}
