import Vue from 'vue'
import ExternalWalletController from './controller'

let instance

export const getInstance = () => instance

export const useExternalWallet = ({ transports }) => {
  if(instance) {
    return instance
  }

  instance = new Vue({
    data() {
      return {
        ctrl: null,
        error: null,
        loading: false,
        wallets: null
      }
    },

    methods: {
      generateToken({ redirectUrl }) {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.generateToken({ redirectUrl })
            .then((token) => resolve(token))
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      getLinkMessage({ token, chainId, provider }) {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.getLinkMessage({ token, chainId, provider })
            .then(({ message }) => resolve(message))
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      signMessage({ token, chainId, signature, provider, address }) {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.signMessage({ token, chainId, signature, provider, address })
            .then((r) => resolve(r))
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      updateNameNoSession({ token, name, id }) {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.updateNameNoSession({ token, name, id })
            .then((r) => resolve(r))
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      updateName({ name, id }) {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.updateName({ name, id })
            .then((r) => resolve(r))
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      deleteWallet({ id }) {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.deleteWallet({ id })
            .then((r) => resolve(r))
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      getWallets() {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.getWallets()
            .then((r) => {
              this.wallets = r
              resolve(r)
            })
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      reset() {
        this.wallets = null
      }
    },

    created() {
      this.ctrl = new ExternalWalletController({ transports })
    }
  })

  return instance
}

export const ExternalWalletPlugin = {
  install(Vue, options) {
    Vue.prototype.$externalWallet = useExternalWallet(options)
  }
}
