export default {
  fqn: 'ifm.conn3ct.user',
  endpoints: {
    entity: {
      get: '/user'
    },

    update: {
      put: '/user'
    },

    login: {
      post: '/user/login'
    },

    loginPhone: {
      post: '/user/login/phone'
    },

    register: {
      post: '/user/register'
    },

    registerPhone: {
      get: '/verify_phone/$code',
      post: '/user/register/phone?force_override=$override'
    },

    registerPassword: {
      post: '/user/password'
    },

    password: {
      post: '/user/password/reset',
      put: '/user/password/change/$token'
    },

    email: {
      get: '/user/resend-verification',
      post: '/user/resend-verification'
    },

    phone: {
      post: '/user/resend-verification/phone'
    },

    auth0: {
      post: '/user/auth0/token_auth'
    },

    countries: {
      get: '/user/countries'
    },

    country: {
      post: '/user/country'
    },

    createPin: {
      post: '/user/pin'
    },

    logout: {
      get: '/logout'
    },

    pin: {
      post: '/user/auth/pin',
      put: '/user/pin'
    },

    terms: {
      get: '/user/terms',
      post: '/user/terms'
    },

    wallet: {
      get: '/user/wallet',
    },

    tutorial: {
      post: '/user/tutorial'
    },

    onboarding: {
      post: '/user/onboarding'
    },

    walletDownload: {
      post: '/user/dismiss_wallet_download'
    },

    privateKey: {
      post: '/user/private_key'
    },
  },

  transport: 'cp'
}
