<script>
export default {
  name: 'IconCross',
}
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8475 10.0001L15.8275 5.02757C15.9231 4.911 15.972 4.763 15.9646 4.61237C15.9572 4.46174 15.8941 4.31924 15.7874 4.2126C15.6808 4.10596 15.5383 4.0428 15.3877 4.0354C15.237 4.028 15.089 4.0769 14.9725 4.17257L9.99996 9.15257L5.02746 4.17257C4.91088 4.0769 4.76288 4.028 4.61225 4.0354C4.46162 4.0428 4.31912 4.10596 4.21248 4.2126C4.10584 4.31924 4.04268 4.46174 4.03528 4.61237C4.02788 4.763 4.07678 4.911 4.17246 5.02757L9.15246 10.0001L4.17246 14.9726C4.05979 15.0863 3.99658 15.24 3.99658 15.4001C3.99658 15.5602 4.05979 15.7138 4.17246 15.8276C4.28714 15.9385 4.44043 16.0005 4.59996 16.0005C4.75949 16.0005 4.91277 15.9385 5.02746 15.8276L9.99996 10.8476L14.9725 15.8276C15.0871 15.9385 15.2404 16.0005 15.4 16.0005C15.5595 16.0005 15.7128 15.9385 15.8275 15.8276C15.9401 15.7138 16.0033 15.5602 16.0033 15.4001C16.0033 15.24 15.9401 15.0863 15.8275 14.9726L10.8475 10.0001Z" fill="black"/>
  </svg>
</template>
