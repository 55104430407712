import Vue from 'vue'
import MessagingController from './controller'
import Overview from './models/overview'
import Message from './models/message'
import { CurryApi } from '@spices/curry'
import config from './config'

let instance

export const getInstance = () => instance

export const useMessaging = ({ transports }) => {
  if(instance) {
    return instance
  }

  instance = new Vue({
    data() {
      return {
        api: null,

        ctrl: null,
        error: null,
        loading: true,
        overview: [],
        unreadCount: 0,
        selectedTopic: null
      }
    },

    computed: {

    },

    methods: {
      async getOverview() {
        try {
          this.loading = true
          const { data } = await this.api.get({ type: 'overview' })
          let res = []

          for(let id in data) {
            data[id].forEach(topic => {
              let item = new Overview(topic)

              this.unreadCount += item.unreadsCount
              res.push(item)
            })
          }

          this.overview = res.sort((a, b) => b.lastMessage.transactionTimestamp - a.lastMessage.transactionTimestamp)

          return this.overview
        } catch(e) {
          throw e
        } finally {
          this.loading = false
        }
      },

      async getMessagesList({ nftId, tokenId, limit, page }) {
        try {
          let r = await this.ctrl.getMessagesList({ nftId, tokenId, limit, page })

          r.data = r.data.map(msg => new Message(msg))

          return r
        } catch(e) {
          $console.error(e)
        }
      },
    },

    created() {
      this.api = new CurryApi({ config, transports })
      this.ctrl = new MessagingController({ transports })
    }
  })

  return instance
}

export const MessagingPlugin = {
  install(Vue, options) {
    Vue.prototype.$messaging = useMessaging(options)
  }
}
