import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'

import config from './config'

export default class ExternalWalletController {
  constructor({ transports }) {
    this._api = new CurryApi({ config, transports })
  }

  generateToken({ redirectUrl }) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'token', payload: { item: { redirectUrl }}})
        .then((r) => Promise.resolve(r.data))
        .then((t) => resolve(t))
        .catch((e) => reject(e))
    })
  }

  getWallets() {
    return new Promise((resolve, reject) => {
      this._api.get({ type: 'externalWallet' })
        .then((r) => Promise.resolve(r.data))
        .then((w) => resolve(w))
        .catch((e) => reject(e))
    })
  }

  deleteWallet({ id }) {
    return new Promise((resolve, reject) => {
      this._api.delete({ type: 'externalWallet', payload: { id }})
        .then((r) => Promise.resolve(r.data))
        .then((w) => resolve(w))
        .catch((e) => reject(e))
    })
  }

  updateName({ id, name }) {
    return new Promise((resolve, reject) => {
      this._api.put({ type: 'externalWallet', payload: { id, item: { name }}})
        .then((r) => Promise.resolve(r.data))
        .then((w) => resolve(w))
        .catch((e) => reject(e))
    })
  }

  getLinkMessage({ token, chainId, provider }) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'link', payload: { token, item: { chain_id: chainId, provider }}})
        .then((r) => Promise.resolve(r.data))
        .then((m) => resolve(m))
        .catch((e) => reject(e))
    })
  }

  updateNameNoSession({ token, name, id }) {
    return new Promise((resolve, reject) => {
      this._api.put({ type: 'link', payload: { token, id, item: { name }}})
        .then((r) => Promise.resolve(r.data))
        .then((w) => resolve(w))
        .catch((e) => reject(e))
    })
  }

  signMessage({ token, signature, chainId, provider, address }) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'sign', payload: { token, item: { signature, chain_id: chainId, provider, address }}})
        .then((r) => Promise.resolve(r.data))
        .then((w) => resolve(w))
        .catch((e) => reject(e))
    })
  }
}
