<template>
  <div class="error">
    <div class="error__card">
      <h1 class="error__title">There was an error loading this app</h1>
      <p class="error__description">Try again in few minutes please</p>

      <actions-button
        class="error__action"
        :appearance="$pepper.Appearance.PRIMARY"
        icon-post="redo"
        :size="$pepper.Size.S"
        @click="onReload"
      >Reload</actions-button>
    </div>

    <div class="error__card">
      <h1 class="error__title">Il y a eu une erreur durant le chargement de cette app</h1>
      <p class="error__description">Reessayer dans quelques minutes, ou contacter votre administateur</p>

      <actions-button
        class="error__action"
        :appearance="$pepper.Appearance.PRIMARY"
        icon-post="redo"
        :size="$pepper.Size.S"
        @click="onReload"
      >Recharger</actions-button>
    </div>

    <div class="error__card">
      <h1 class="error__title">Er is een fout opgetreden bij het laden van deze app</h1>
      <p class="error__description">Probeer het over een paar minuten opnieuw a.u.b.</p>

      <actions-button
        class="error__action"
        :appearance="$pepper.Appearance.PRIMARY"
        icon-post="redo"
        :size="$pepper.Size.S"
        @click="onReload"
      >Herladen</actions-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onReload() {
      window.location.href = '/conn3ct-wallet'
    }
  }
}
</script>
