export default {
  fqn: 'ifm.conn3ct.messaging',
  endpoints: {
    overview: {
      get: '/user/messages'
    },

    messages: {
      get: '/user/messages/$nftId/$tokenId?page=$page&limit=$limit'
    }
  },

  transport: 'cp'
}
