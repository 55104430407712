<script>
import { ClickOutside } from '@pepper/pepper'
import LocaleSelect from '@/components/actions/locale-select.vue'
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import navigationItems from '@/data/navigation'
import Config from '@/config'

export default {
  name: 'NavHeader',

  components: {
    LocaleSelect,
  },

  directives: {
    ClickOutside
  },

  data() {
    return {
      burgerOpenned: false,
      notificationsOpened: false,
      notificationLoading: false,
      notificationTimeout: null,
      Config,
      counts: {
        claims: 0,
        messages: 0,
        vouchers: 0,
        poaps: 0,
        challenges: 0
      },
      burgerKey: 0
    }
  },

  watch: {
    '$route.name'() {
      this.burgerOpenned = false
    },

    burgerOpenned(val) {
      if(val) {
        this.countEntities()
      }
    }
  },

  computed: {
    ...mapState({
      bootstrap: state => state.bootstrap,
    }),

    actionsItems() {
      return navigationItems.filter(i => i.burger)
    },

    hasNotifications() {
      return !this.$basil.isNil(this.$notifications.unreadCount) && this.$notifications.unreadCount > 0
    },

    username() {
      return this.$user.user?.firstname
    },

    merchantLogo() {
      return this.bootstrap?.data?.brand?.logo
    },

    notifications() {
      return this.$notifications?.unread ?? []
    },

    logo() {
      if(this.merchantLogo) {
        return this.translate(this.merchantLogo.url)
      }

      return this.$root.getSrc('statics/images/sayl-logo-color.svg')
    },

    logoAlt() {
      if(this.merchantLogo) {
        return this.translate(this.merchantLogo.alt)
      }

      return 'Logomark Sayl Connect'
    }
  },

  methods: {
    async countEntities() {
      try {
        const proms = [
          this.$wallet.getClaimables({}),
          this.$wallet.getToClaimNft({}),
          this.$vouchers.getWeb3Vouchers(),
          this.$vouchers.getWeb2Vouchers(),
          this.$challenges.getChallenges(),
          this.$poaps.getCollected(),
        ]

        const res = await Promise.all(proms)

        this.counts.claims = res[0].length + res[1].length
        this.counts.vouchers = res[2].length + res[3].length
        this.counts.challenges = res[4].length
        this.counts.poaps = res[5].length

        burgerKey++
      } catch(e) {
        $console.error(e)
      }
    },

    formatDate(date) {
      return date.toLocaleString(DateTime.DATETIME_SHORT)
    },

    getMessage(notification) {
      return this.$t(`conn3ct-wallet.${notification.message}`, notification.variables)
    },

    async init() {
      try {
        this.notificationLoading = true
        this.$notifications.page = 1

        await this.$notifications.getUnreadNotifications()
      } catch(e) {
        $console.error(e)
      } finally {
        this.notificationLoading = false
      }
    },

    async onAction(notification) {
      try {
        this.$wallet.newInteraction({ name: 'notifications-mark-as-read' })

        await this.$notifications.markOneAsRead({ id: notification.id })
        this.init()

        switch(notification.action) {
          case 'home':
            this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {})
            break

          case 'claim':
            let route = { name: 'sayl-connect_wallet-claimable', params: { id: this.$basil.get(notification, 'relatedEntityId') }}

            if(notification.relatedEntity === 'nft_transfer') {
              route =  { name: 'sayl-connect_wallet-transfer', params: { id: this.$basil.get(notification, 'relatedEntityId') }}
            }

            this.$router.push(route).catch(() => {})
            break

          case 'view':
            const id = `${this.$basil.get(notification, 'variables[nftTransfer.nft_id]')}_${this.$basil.get(notification, 'variables[nftTransfer.serial]')}`
            this.$router.push({ name: 'sayl-connect_wallet-nft', params: { id }}).catch(() => {})
            break

          case 'message':
            this.$router.push({ name: 'sayl-connect_messaging-messaging' }).catch(() => {})
            break

          case 'vouchers':
            const tab = notification.relatedEntity === 'nft_vouchers' ? 'web3' : 'web2'

            this.$router.push({ name: 'sayl-connect_vouchers-vouchers', query: { tab }}).catch(() => {})
        }

        this.notificationsOpened = false
      } catch(e) {
        $console.error(e)
      }
    },

    async onMarkAllAsRead() {
      try {
        this.$wallet.newInteraction({ name: 'notifications-mark-all-as-read' });
        await this.$notifications.markAllAsRead()
        this.init()

        this.notificationsOpened = false
      } catch(e) {
        $console.error(e)
      }
    },

    onLogout() {
      this.$confirm({
        description: this.$t('conn3ct-wallet.logout_description'),
        onPrimary: () => this.$router.push({ name: 'logout' }).catch(() => {}),
        primaryAction: this.$t('conn3ct-wallet.settings_logout'),
      })
    },

    onProfileClick() {
      this.$router.push({ name: 'sayl-connect_user-profile' }).catch(() => {})
    },

    openNotifications() {
      this.$wallet.newInteraction({ name: 'header-show-notifications' })
      this.notificationsOpened = true
    },

    openBurger() {
      this.$wallet.newInteraction({ name: 'header-show-burger' })
      this.burgerOpenned = !this.burgerOpenned
    },
  },

  mounted() {
    this.init()

    this.$notifications.getUnreadNotificationsCount()
  },

  beforeDestroy() {
    if(this.notificationTimeout) {
      clearTimeout(this.notificationTimeout)
    }
  }
}
</script>

<template>
  <nav class="nav-header">
    <router-link
      :to="{ name: 'sayl-connect_dashboard-dashboard' }"
      class="nav-header__branding"
    >
      <img
        :alt="logoAlt"
        :src="logo"
      />
    </router-link>

    <div class="nav-header__body">
      <!-- <div class="nav-header__balance">
        0 $SAYL
      </div> -->

      <actions-button
        @click="openNotifications"
        class="nav-header__button notification rounded"
      >
        <icon-notification-bell />

        <div
          v-show="hasNotifications"
          class="notification__alert"
        >
          <div class="notification__alert-inner">
            <div class="outer"></div>
            <div class="inner"></div>
          </div>
        </div>
      </actions-button>

      <locale-select
        class="nav-header__button locales"
        :label="lang"
        :options="locales"
      />

      <actions-button
        @click="openBurger"
        class="nav-header__button burger rounded"
      >
        <icon-burger-menu :openned="burgerOpenned" />
      </actions-button>

      <actions-button
        @click="onProfileClick"
        class="nav-header__button profile"
      >{{ username }}</actions-button>
    </div>

    <div
      class="nav-header__dropdown"
      :class="{
        '-openned': burgerOpenned
      }"
    >
      <div
        class="nav-header__dropdown-actions"
        :key="burgerKey"
      >
        <router-link
          v-for="action in actionsItems"
          class="nav-header__dropdown-action"
          :key="action.slug"
          :to="{ name: action.route }"
        >
          <component
            class="icon"
            :is="action.icon"
          />

          <span>{{ $t(action.label) }} {{ counts[action.slug] ? `(${ counts[action.slug] })` : '' }}</span>
        </router-link>
      </div>

      <div class="nav-header__dropdown-meta">
        <div class="nav-header__dropdown-version">
          <span>{{ $t('conn3ct-wallet.settings_version_label') }}</span>
          <span>{{ `v. ${Config.version.client}` }}</span>
        </div>

        <actions-button
          @click="onLogout"
          :appearance="$pepper.Appearance.PRIMARY"
          class="nav-header__dropdown-logout"
        >
          <span>{{ $t('conn3ct-wallet.settings_logout') }}</span>

          <icon-logout />
        </actions-button>
      </div>

      <popins-modal
        :closable="false"
        position="middle-center"
        :size="$pepper.Size.S"
        :visible="notificationsOpened"
        scrollLocked
      >
        <template v-slot:header>
          <div class="notifications-modal__header">
            <h2 class="notifications-modal__title">{{ $t('conn3ct-wallet.notifications_title') }}</h2>

            <button
              @click="notificationsOpened = false"
              class="notifications-modal__close"
            >
              <icon-cross />
            </button>
          </div>
        </template>

        <div
          v-if="!notifications.length"
          class="notifications-modal__empty"
        >{{ $t('conn3ct-wallet.no_notifications_title') }}</div>

        <div
          v-else
          class="notifications-modal__list"
        >
          <actions-button
            @click="onMarkAllAsRead"
            :appearance="$pepper.Appearance.SUBTLE"
            class="notifications-modal__action"
            :size="$pepper.Size.S"
          >{{ $t('conn3ct-wallet.notifications_mark_all_as_read') }}</actions-button>

          <div
            v-for="notification in notifications"
            @click="onAction(notification)"
            class="notifications-modal__item notification"
            :key="notification.id"
          >
            <div class="notification__content">
              <header class="notification__header">
                <h4 class="notification__title">{{ $t(`conn3ct-wallet.${ notification.title }`) }}</h4>
                <span class="notification__date">{{ formatDate(notification.created) }}</span>
              </header>

              <p class="notification__message">{{ getMessage(notification) }}</p>
            </div>
          </div>
        </div>
      </popins-modal>
    </div>
  </nav>
</template>
