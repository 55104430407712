<script>
export default {
  name: 'CopyIcon'
}
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 5H6V14H14V5Z" stroke="#24282B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 5V2H2V10H5" stroke="#24282B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
