import api from './api/index'
import http from './api/transports/http'

export default class DataLayer {
  bootstrapClientProject({ locale, store }) {
    return new Promise((resolve, reject) => {
      api.bootstrap({ locale })
        .then(response => {
          let data = response.data
          // Start the keep alive
          this.keepAlive()
          // Save the config in the store
          store.commit('bootstrap/setConfig', data)
          resolve(data)
        })
        .catch((e) => reject(e))
    })
  }

  bootstrapLocal({ store }) {
    return new Promise(resolve => {
      http.get('/statics/json/bootstrap.json')
        .then(response => {
          let data = response.data
          // Save the config in the store
          store.commit('bootstrap/setConfig', data)
          return resolve(data)
        })
    })
  }

  keepAlive() {
    setInterval(() => api.keepAlive(), 1000 * 60 * 5)
  }
}
