import Nft from './nft'

export default class Nfts extends Array {
  constructor(nfts) {
    super()

    nfts.forEach((nft, i) => {
      this[i] = new Nft(nft)
    })
  }
}
