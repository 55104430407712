import { DateTime } from 'luxon'

export default class Challenge {
  constructor(args) {
    this._id = args.id ?? null
    this._completionsCount = args.completions_count ?? 0
    this._descr = args.descr ?? null
    this._descrMini = args.descr_mini ?? null
    this._from = args.from ? DateTime.fromISO(args.from) : null
    this._to = args.to ? DateTime.fromISO(args.to) : null
    this._globalAmountRewards = args.global_amount_rewards ?? null
    this._images = args.images ?? []
    this._name = args.name ?? null
    this._rewardDescr = args.reward_descr ?? null
    this._rewardType = args.reward_type ?? null
    this._rewardTypeSettings = args.reward_type_settings ?? null
    this._userAmountRewards = args.user_amount_rewards ?? null
    this._userCompletionsCount = args.user_completions_count ?? 0
    this._projectId = args.project_id ?? null

    this._brand = null
  }

  get id() {
    return this._id
  }

  get completionsCount() {
    return this._completionsCount
  }

  get descr() {
    return this._descr
  }

  get descrMini() {
    return this._descrMini
  }

  get from() {
    return this._from
  }

  get to() {
    return this._to
  }

  get globalAmountRewards() {
    return this._globalAmountRewards
  }

  get images() {
    return this._images
  }

  get name() {
    return this._name
  }

  get rewardDescr() {
    return this._rewardDescr
  }

  get rewardType() {
    return this._rewardType
  }

  get rewardTypeSettings() {
    return this._rewardTypeSettings
  }

  get userAmountRewards() {
    return this._userAmountRewards
  }

  get userCompletionsCount() {
    return this._userCompletionsCount
  }

  get logo() {
    return this.images.find(i => i.field_name === 'logo')
  }

  set brand(brand) {
    this._brand = brand
  }

  get brand() {
    return this._brand
  }

  get projectId() {
    return this._projectId
  }
}
