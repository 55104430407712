export default [
  {
    component: () => import('./views/challenges.vue'),
    path: '/challenges',
    name: 'sayl-connect_challenges-challenges',
    meta: {
      module: 'challenges',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/challenge.vue'),
    path: '/challenge/:id',
    name: 'sayl-connect_challenges-challenge',
    meta: {
      module: 'challenges',
      requireAuth: true
    }
  },
]
