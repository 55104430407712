import Vue from 'vue'
import Web3Vouchers from './models/web3-vouchers'
import Web2Vouchers from './models/web2-vouchers'
import { CurryApi } from '@spices/curry'
import config from './config'

let instance

export const getInstance = () => instance

export const useVouchers = ({ transports }) => {
  if(instance) return instance

  instance = new Vue({
    data: () => {
      return {
        api: null,
        web2Vouchers: null,
        web3Vouchers: null,
      }
    },

    methods: {
      async getWeb3Vouchers(invalidate = false) {
        if(!invalidate && this.web3Vouchers?.isCacheValid) {
          return this.web3Vouchers.list ?? []
        }

        try {
          const { data } = await this.api.get({ type: 'web3Vouchers' })

          const list = []

          for(let id in data) {
            data[id]?.forEach(elem => {
              list.push(elem)
            })
          }

          this.web3Vouchers = new Web3Vouchers(list)

          return this.web3Vouchers?.list ?? []
        } catch(e) {
          throw e
        }
      },

      async getWeb2Vouchers(invalidate = false) {
        if(!invalidate && this.web2Vouchers?.isCacheValid) {
          return this.web2Vouchers.list ?? []
        }

        try {
          const { data } = await this.api.get({ type: 'web2Vouchers' })

          this.web2Vouchers = new Web2Vouchers(data)

          return this.web2Vouchers?.list ?? []
        } catch(e) {
          throw e
        }
      }
    },

    created() {
      this.api = new CurryApi({ config, transports })
    }
  })

  return instance
}

export const VouchersPlugin = {
  install(Vue, options) {
    Vue.prototype.$vouchers = useVouchers(options)
  }
}
