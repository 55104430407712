import Vue from 'vue'
import NotificationController from './controller'
import Config from '@/config'

let instance

/** Returns the current instance */
export const getInstance = () => instance

/**
 * Creates an instance of the notification controller.
 * If one has already been created, it returns that instance
 */
export const useNotification = ({ transports }) => {
  if (instance) {
    return instance
  }

  instance = new Vue({
    data() {
      return {
        // State
        ctrl: null,
        error: null,
        loading: false,

        // Data
        notifications: [],
        unread: null,
        limit: 15,
        page: 1,
        debounce: null
      }
    },

    computed: {
      pagination() {
        let ret = {}
        if(!this.$basil.isNil(this.ctrl.notificationsMeta)) {
          return this.ctrl.notificationsMeta.pagination
        }
        return ret
      },

      unreadCount() {
        return this.ctrl.unreadCount
      }
    },

    // watch: {
    //   '$user.isAuthenticated': {
    //     immediate: true,
    //     handler(n, o) {
    //       if(n === true) {
    //         this.getUnreadNotificationsCount()
    //       }
    //     }
    //   }
    // },

    methods: {
      getNotifications() {
        return new Promise((resolve, reject) => {
          this.loading = true

          this.ctrl.getNotifications({ page: this.page, limit: this.limit })
            .then((n) => {
              if(this.page === 1) {
                resolve(this.notifications = n)
              } else {
                this.notifications = this.notifications.concat(n)
                resolve()
              }
            })
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      getUnreadNotifications() {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.getUnreadNotifications()
            .then((n) => resolve(this.unread = n))
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        })
      },

      getUnreadNotificationsCount() {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.getUnreadNotificationsCount()
            .then(() => this.getUnreadNotifications())
            .then(() => this.$wallet.getClaimables({}))
            .then(() => this.$wallet.getToClaimNft({}))
            .then(() =>{
              let refreshTime = 60

              try{
                refreshTime = parseInt(this.$basil.get(Config, 'notifications.refresh', 60))
              } catch(e) {
                refreshTime = 60
              }

              clearTimeout(this.debounce)
              this.debounce = setTimeout(() => {
                this.getUnreadNotificationsCount()
                  .catch((e) => $console.error(e))
              }, refreshTime * 1000)
              resolve()
            })
            .catch((e) => reject(e))
            .finally(() => {
              this.loading = false
            })
        })
      },

      stopNotificationPolling() {
        return new Promise((resolve) => {
          clearTimeout(this.debounce)

          return resolve()
        })
      },

      markAllAsRead() {
        return new Promise((resolve, reject) => {
          this.ctrl.markAllAsRead()
            .then(() => this.getUnreadNotificationsCount())
            // .then(() => this.getNotifications())
            .then((r) => resolve(r))
            .catch((e) => reject(e))
        })
      },

      markOneAsRead({ id }) {
        return new Promise((resolve, reject) => {
          this.ctrl.markOneAsRead({ id })
            .then(() => this.getUnreadNotificationsCount())
            // .then(() => this.getNotifications())
            .then((r) => resolve(r))
            .catch((e) => reject(e))
        })
      },
    },

    created() {
      this.ctrl = new NotificationController({ transports })
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const NotificationPlugin = {
  install(Vue, options) {
    Vue.prototype.$notifications = useNotification(options)
  }
}
