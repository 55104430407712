<template>
  <div class="icon-sayl" :class="{ '-inverted': inverted }">
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.16684 1.88086C1.45981 1.88086 0.196533 3.56463 0.196533 3.56463H6.20432C6.42269 3.56463 6.61218 3.33182 6.66813 3.14594L7.05433 1.88266L4.16684 1.88086Z" :fill="color" />
      <path d="M7.05518 1.88209L7.44138 0.438346C7.46563 0.335481 7.52592 0.244704 7.61134 0.182464C7.69675 0.120224 7.80164 0.0906371 7.90699 0.099065C9.8019 0.290361 11.0255 1.8857 11.0255 1.8857L7.05518 1.88209Z" :fill="color" />
      <path d="M12.1083 5.97265C12.1083 5.97265 11.0255 4.16797 8.01886 4.16797H6.94507C6.83855 4.16846 6.73517 4.2041 6.65097 4.26937C6.56678 4.33463 6.50649 4.42587 6.47946 4.52891L6.09326 5.97265H12.1083Z" :fill="color" />
      <path d="M0.0776367 5.97266C0.0776367 5.97266 1.34092 7.77734 3.687 7.77734H5.24084C5.34744 7.77715 5.45097 7.74163 5.53523 7.67631C5.61948 7.611 5.67969 7.5196 5.70645 7.4164L6.09265 5.97266H0.0776367Z" :fill="color" />
      <path d="M8.49889 10.3027C10.6645 10.3027 11.9891 8.37891 11.9891 8.37891H5.98857C5.88158 8.37838 5.77748 8.41361 5.6928 8.479C5.60811 8.54439 5.54771 8.6362 5.52116 8.73985L5.13135 10.2991L8.49889 10.3027Z" :fill="color" />
      <path d="M5.13046 10.3024L4.74426 11.7462C4.71971 11.849 4.6594 11.9398 4.57411 12.0023C4.48882 12.0647 4.3841 12.0949 4.27865 12.0873C2.3332 11.9068 1.16016 10.2988 1.16016 10.2988L5.13046 10.3024Z" :fill="color" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SaylIcon',

  props: {
    inverted: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      color: 'white',
    }
  },

  mounted() {
    if(this.inverted) {
      this.color = '#DDAF28'
    }
  }
}
</script>

<style scoped>
.icon-sayl {
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #DDAF28;
}

.icon-sayl.-inverted {
  background-color: white;
}
</style>
