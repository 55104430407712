import { basil } from '@spices/basil'
import { getInstance as AuthInstance } from "../user/instance"
import { getInstance as WalletInstance } from "../wallet/instance"

import Data from './data'

/**
 *
 */
const Actions = {}

/**
 *
 */
let CLOSE = {
  event: (data, ctx, event) => {
    $console.info('Conn3ct:Wallet:PostMessage:Actions:CLOSE')
    Promise.resolve()
  },
  name: 'close',
}
Actions.CLOSE = CLOSE

/**
 *
 */
let DISCONNECT = {
  event: (data, ctx, event) => {
    $console.info('Conn3ct:Wallet:PostMessage:Actions:DISCONNECT')

    return new Promise((resolve, reject) => {
      AuthInstance().logout()
        .then(() => resolve())
        .catch((e) => reject(e))
    })
  },
  name: 'disconnect'
}
Actions.DISCONNECT = DISCONNECT

/**
 *
 */
let ENABLE = {
  event: async (data, ctx, event) => {
    $console.info('Conn3ct:Wallet:PostMessage:Actions:ENABLE')

    if(!ctx._linked) {
      ctx._origin = basil.get(event, event.origin, null)
      ctx._linked = true

      return
    }
  },
  name: 'enable',
}
Actions.ENABLE = ENABLE

/**
 *
 */
let REQUEST = {
  event: async (data, ctx, event) => {
    $console.info('Conn3ct:Wallet:PostMessage:Actions:REQUEST')

    let limit = basil.get(data, 'payload.timeout')
    if(basil.isNil(limit)) {
      limit = 120
    }

    return new Promise((resolve, reject) => {
      let field = basil.get(data, 'payload.field')
      let found = Data.ALL.find(d => d.name === field)

      if(found == null) {
        reject({ error: 'forbidden', status: 403 })
        return
      }

      let timeout = null

      const getData = () => {
        if(found.instance().loading) {
          setTimeout(getData, 1000)
          return
        }

        let d = basil.get(found.instance(), found.path)
        resolve(d)
      }

      $console.info('Request:GetData', limit, AuthInstance().isAuthenticated)
      limit -= 1

      if(!AuthInstance().isAuthenticated) {
        AuthInstance().$watch('isAuthenticated', (isAuthenticated) => {
          if(isAuthenticated === true) {
            clearTimeout(timeout)
            getData()
          }
        })
      } else {
        getData()
      }

      timeout = setTimeout(() => {
        reject({ status: 401, error: 'not_authenticated' })
      }, limit * 1000)
    })
  },
  name: 'request'
}
Actions.REQUEST = REQUEST

/**
 *
 */
let SIGN = {
  event: async (data, ctx, event) => {
    $console.info('Conn3ct:Wallet:PostMessage:Actions:SIGN')

    const claims = (basil.get(data, 'payload.claims', []) || []).join(',')

    return new Promise((resolve, reject) => {
      const route = { name: 'sayl-connect_user-sign', query: { claims }}
      window.sessionStorage.setItem('redirect', JSON.stringify(route))
      WalletInstance().router.push(route).catch(() => {})
      WalletInstance().$watch('jwt', (jwt) => {
        resolve(jwt)
      });
    });
  },
  name: 'sign'
}
Actions.SIGN = SIGN

Actions.ALL = [
  CLOSE,
  DISCONNECT,
  ENABLE,
  REQUEST,
  SIGN
]

export default Actions
