import { DateTime } from 'luxon'
import { basil } from '@spices/basil'
import VoucherTemplate from './voucher-template'

export default class Web3Voucher {
  constructor(
    {
      id = null,
      last_generation_date = null,
      next_due_date = null,
      template = {},
      name = { all: null }
    },
    options = { locale: 'all' }
  ) {
    this._id = id
    this._lastGenerationDate = last_generation_date ? DateTime.fromISO(last_generation_date) : null
    this._nextDueDate = next_due_date ? DateTime.fromISO(next_due_date) : null
    this._template = !basil.isNil(template) ? new VoucherTemplate(template) : {}
    this._name = name

    this._locale = options.locale
  }

  get id() {
    return this._id
  }

  get lastGenerationDate() {
    return this._lastGenerationDate
  }

  get nextDueDate() {
    return this._nextDueDate
  }

  get template() {
    return this._template
  }

  get name() {
    let lang = localStorage.getItem('lang') ?? 'all'

    return this._name.hasOwnProperty(lang) ?
      this._name[lang] :
      this._name
  }
}
