<template>
  <div class="locale-select">
    <actions-button
      @click="showLocales = !showLocales"
      :appearance="$pepper.Appearance.DEFAULT"
      icon-post="angle-down"
      ref="locale"
    >
      <span class="locale__label">{{ lang.toUpperCase() }}</span>
    </actions-button>

    <popins-list-dropdown
      @change="onChangeLang"
      class="locales-dropdown"
      :visible="showLocales"
      :options="locales"
      :layout="['field', 'label']"
      :target="$refs.locale"
    ></popins-list-dropdown>
  </div>
</template>

<script>
export default {
  name: 'ActionsLocaleSelect',

  data: () => {
    return {
      showLocales: false
    }
  },

  methods: {
    async onChangeLang(langs) {
      let { value } = langs.find(l => l.active)
      this.showLocales = false;
      this.setLocale(value)

      if(this.$user.user) {
        this.$user.user.lang = value
        await this.$user.update(this.$user.user)
      }
    },
  }
}
</script>

<style>
.locale-select .actions-button__icon.icon-angle-down {
  padding-bottom: 7px;
}
</style>
