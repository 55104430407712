import { mix } from '@/factories'
import Cached from '@/factories/cached'
import Voucher from '../../wallet/models/wallet/nft/voucher'

export default class Web3Vouchers extends mix().with(Cached) {
  constructor(vouchers) {
    super()

    this.list = vouchers.map(v => new Voucher(v))
  }
}
