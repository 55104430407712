import ErrorsRoutes from './errors'
import Logout from './views/utils/logout'
// import WalletRoutes from '@/modules/wallet/views'
import Router from '@/modules'

let routes = [
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  }
]

// routes = WalletRoutes().concat(routes)
routes = Router.concat(routes)
export default routes.concat(ErrorsRoutes)
