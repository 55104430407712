<template>
  <figure class="ui-illustration">
    <video
      v-if="imgType === 'video' && src"
      class="ui-illustration__asset"
      :class="{
        '-is-contained': fit === 'contain' || !src,
      }"
      :src="image"
      autoplay
      loop
      muted
      playsinline
      loading="lazy"
      :alt="alt"
    ></video>

    <img
      v-else
      :alt="alt"
      class="ui-illustration__asset"
      :class="{
        '-is-contained': fit === 'contain' || !src,
      }"
      loading="lazy"
      :src="image"
    />
  </figure>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UiIllustration',

  props: {
    alt: String,

    fit: {
      type: String,
      default: 'cover',
    },

    src: {
      type: String
    },

    type: {
      type: String | Promise,
      default: 'image'
    },
  },

  data() {
    return {
      imgType: 'image',
    }
  },

  computed: {
    ...mapState({
      bootstrap: state => state.bootstrap,
    }),

    image() {
      if(this.src) {
        return this.src
      }

      return this.$root.getSrc('statics/images/sayl-logo-color.svg')
    },

    merchantLogo() {
      return this.bootstrap?.data?.brand?.logo
    },
  },

  methods: {
    async initType() {
      this.imgType = await this.type
    },
  },

  created() {
    if(typeof this.type === 'object') {
      this.initType()
    } else {
      this.imgType = this.type
    }
  },
}
</script>
