<script>
export default {
  name: 'IconCarret',

  props: {
    orientation: {
      type: String,
      default: 'right'
    }
  },

  computed: {
    classes() {
      return {
        'icon-carret': true,
        '-left': this.orientation === 'left',
        '-right': this.orientation === 'right',
        '-top': this.orientation === 'top',
        '-bottom': this.orientation === 'bottom'
      }
    }
  }
}
</script>

<template>
  <svg :class="classes" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Rectangle 53" d="M1 11.2427L6.24265 6.00003L1 0.757385" stroke="black" stroke-width="2"/>
  </svg>
</template>
