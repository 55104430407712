import { parsePhoneNumber } from 'libphonenumber-js'
import { basil } from '@spices/basil'

/**
 * @class
 */
export default class User {
  constructor({
    chain = null,
    created = null,
    country_id = null,
    current_tc_version = null,
    date_of_birth = null,
    devices = null,
    email = null,
    email_verified = null,
    firstname = null,
    id = null,
    is_kyc_approved = false,
    is_quarantined = false,
    kyc_approval_date = null,
    modified = null,
    lang = null,
    lastname = null,
    last_login = null,
    must_validate_tc = false,
    originating_project_id = null,
    pin_set = false,
    phone_number = null,
    sayl_account_id = null,
    seen_tutorial = false,
    tc_optin = false,
    tc_optin_version = null,
    username = null,
    user_profile_engine = null,
    user_profile_id = null,
    wallet_engine = null,
    wallet_id = null,
    wallet_address = null,
    registration_identifier = 'email',
    phone_number_verified = null,
    onboarding_completed = false,
    wallet_pass_download_dismissed = false
  }) {
    this.parsePhone(phone_number)
    this._chain = chain
    this._created = created
    this._countryId = country_id
    this._currentTcVersion = current_tc_version
    this._dateOfBirth = date_of_birth
    this._devices = devices
    this._email = email
    this._emailVerified = email_verified
    this._firstname = firstname
    this._id = id
    this._isKycApproved = is_kyc_approved
    this._isQuarantined = is_quarantined
    this._kycApprovalDate = kyc_approval_date
    this._modified = modified
    this._lang = lang
    this._lastname = lastname
    this._lastLogin = last_login
    this._mustValidateTc = must_validate_tc
    this._originatingProjectId = originating_project_id
    this._pinSet = pin_set
    this._saylAccountId = sayl_account_id
    this._seenTutorial = seen_tutorial
    this._tcOptin = tc_optin
    this._tcOptinVersion = tc_optin_version
    this._username = username
    this._userProfileEngine = user_profile_engine
    this._userProfileId = user_profile_id
    this._walletEngine = wallet_engine
    this._walletId = wallet_id
    this._walletAddress = wallet_address
    this._registrationIdentifier = registration_identifier
    this._phoneVerified = phone_number_verified
    this._onboardingCompleted = onboarding_completed ?? false
    this._walletPassDownloadDismissed = wallet_pass_download_dismissed ?? false
  }

  parsePhone(phoneNumber) {
    if(basil.isNil(phoneNumber)) {
      this._phoneNumber = null
    }

    try {
      let { nationalNumber, country } = parsePhoneNumber(phoneNumber)
      this._phoneNumber = nationalNumber
      this._phoneCountryCode = country
    } catch(e) {
      this._phoneNumber = phoneNumber
      this._phoneCountryCode = 'BE'
    }
  }

  static formatPhone({ phoneCountryCode, phoneNumber }) {
    try {
      let phone = parsePhoneNumber(phoneNumber, phoneCountryCode).formatInternational()
      return phone.replace(' ', '')
    } catch(e) {
      $console.error(e)
      return phoneNumber
    }
  }

  get walletPassDownloadDismissed() {
    return this._walletPassDownloadDismissed
  }

  get onboardingCompleted() {
    return this._onboardingCompleted
  }

  get phoneCountryCode() {
    return this._phoneCountryCode
  }

  set phoneCountryCode(code) {
    this._phoneCountryCode = code
  }

  ////////////////////////////////
  // Getters
  /**
   * @property {String} chain
   * @readonly
   * @return {String}
   */
  get chain() {
    return this._chain
  }

  get phoneVerified() {
    return this._phoneVerified
  }

  get registrationIdentifier() {
    return this._registrationIdentifier
  }

  /**
   * @property {Object} created
   * @readonly
   * @return {Object}
   */
  get created() {
    return this._created
  }

  /**
   * @property {String} countryId
   * @return {String}
   */
  get countryId() {
    return this._countryId
  }

  /**
  * @property {String} currentTcVersion
  * @return {String}
  */
  get currentTcVersion() {
    return this._currentTcVersion
  }

  /**
   * @property {String} dateOfBirth
   * @return {String}
   */
  get dateOfBirth() {
    return this._dateOfBirth
  }

  /**
   * @property {Array} devices
   * @readonly
   * @return {Array}
   */
  get devices() {
    return this._devices
  }

  /**
   * @property {String} email
   * @return {String}
   */
  get email() {
    return this._email
  }

  /**
   * @property {Boolean} emailVerified
   * @readonly
   * @return {Boolean}
   */
  get emailVerified() {
    return this._emailVerified
  }

  /**
   * @property {String} firstname
   * @return {String}
   */
  get firstname() {
    return this._firstname
  }

  /**
   * @property {String} id
   * @readonly
   * @return {String}
   */
  get id() {
    return this._id
  }

  /**
   * @property {Boolean} isKycApproved
   * @readonly
   * @return {Boolean}
   */
  get isKycApproved() {
    return this._isKycApproved
  }

  /**
   * @property {Boolean} isQuarantined
   * @readonly
   * @return {Boolean}
   */
  get isQuarantined() {
    return this._isQuarantined
  }

  /**
   * @property {Boolean} kycApprovalDate
   * @readonly
   * @return {Boolean}
   */
  get kycApprovalDate() {
    return this._kycApprovalDate
  }

  /**
   * @property {Object} modified
   * @readonly
   * @return {Object}
   */
  get modified() {
    return this._modified
  }

  /**
   * @property {String} lang
   * @return {String}
   */
  get lang() {
    return this._lang
  }

  /**
   * @property {String} lastname
   * @return {String}
   */
  get lastname() {
    return this._lastname
  }

  /**
   * @property {Object} lastLogin
   * @readonly
   * @return {Object}
   */
  get lastLogin() {
    return this._lastLogin
  }

  /**
   * @property {Boolean} mustValidateTc
   * @readonly
   * @return {Boolean}
   */
  get mustValidateTc() {
    return this._mustValidateTc
  }

  /**
   * @property {String} originatingProjectId
   * @readonly
   * @return {String}
   */
  get originatingProjectId() {
    return this._originatingProjectId
  }

  /**
   * @property {Boolean} pinSet
   * @readonly
   * @return {Boolean}
   */
  get pinSet() {
    return this._pinSet
  }

  /**
   * @property {Boolean} phoneNumber
   * @return {Boolean}
   */
  get phoneNumber() {
    return this._phoneNumber
  }

  /**
   * @property {String} saylAccountId
   * @readonly
   * @return {String}
   */
  get saylAccountId() {
    return this._saylAccountId
  }

  /**
   * @property {Boolean} seenTutorial
   * @readonly
   * @return {Boolean}
   */
  get seenTutorial() {
    return this._seenTutorial
  }

  /**
   * @property {String} tcOptin
   * @readonly
   * @return {String}
   */
  get tcOptin() {
    return this._tcOptin
  }

  /**
   * @property {String} tcOptinVersion
   * @readonly
   * @return {String}
   */
  get tcOptinVersion() {
    return this._tcOptinVersion
  }

  /**
   * @property {String} username
   * @return {String}
   */
  get username() {
    return this._username
  }

  /**
   * @property {String} userProfileEngine
   * @readonly
   * @return {String}
   */
  get userProfileEngine() {
    return this._userProfileEngine
  }

  /**
   * @property {String} userProfileId
   * @readonly
   * @return {String}
   */
  get userProfileId() {
    return this._userProfileId
  }

  /**
   * @property {String} walletEngine
   * @readonly
   * @return {String}
   */
  get walletEngine() {
    return this._walletEngine
  }

  /**
   * @property {String} walletId
   * @readonly
   * @return {String}
   */
  get walletId() {
    return this._walletId
  }

  /**
   * @property {String} walletAddress
   * @readonly
   * @return {String}
   */
  get walletAddress() {
    return this._walletAddress
  }

  ////////////////////////////////
  // Setters
  /**
   * @property {String}
   * @return {String}
   */
  set countryId(value) {
    this._countryId = value
  }

  /**
   * @property {String}
   * @return {String}
   */
  set dateOfBirth(value) {
    this._dateOfBirth = value
  }

  /**
   * @property {String}
   * @return {String}
   */
  set email(value) {
    this._email = value
  }

  /**
   * @property {String}
   * @return {String}
   */
  set firstname(value) {
    this._firstname = value
  }

  /**
   * @property {String} lang
   * @return {String}
   */
  set lang(value) {
    this._lang = value
  }

  /**
   * @property {String} lastname
   * @return {String}
   */
  set lastname(value) {
    this._lastname = value
  }

  /**
   * @property {Boolean} phoneNumber
   * @return {Boolean}
   */
  set phoneNumber(value) {
    this._phoneNumber = value
  }

  /**
   * @property {String} username
   * @return {String}
   */
  set username(value) {
    this._username = value
  }
}
