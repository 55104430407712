import { basil } from '@spices/basil'
import { version as app } from '../package.json'

function getEnvValue(name, defaultValue){
  let ret = defaultValue // default value for the mutualised

  const isArgument = (e) => typeof e === 'object' && e !== null && e.toString() === '[object Arguments]'
  let dl = basil.get(window, 'dataLayer', []);
  let v = dl.find(e => {
    let ret = false

    if (isArgument(e) && (e.hasOwnProperty('length') && e.length == 2)) {
      let [n, value] = e
      ret = name === n
    }

    return ret
  })
  ret = basil.get(v, '[1]', ret)

  return ret
}

const config = {
  baseURL : [document.location.origin].join('/'),
  cdn: process.env.VUE_APP_CDN || '/',
  embedId: getEnvValue('connect.embedId', null),
  env: process.env.VUE_APP_ENV || 'production',
  injekt: {
    debug: process.env.VUE_APP_INJEKT_DEBUG || false,
    env: process.env.VUE_APP_INJEKT_ENV || 'production'
  },
  libs: process.env.VUE_APP_LIBS || '/',
  notifications: {
    refresh: process.env.VUE_APP_NOTIFICATIONS_REFRESH_TIME || '60'
  },
  publicPath: '/',
  state: 0,
  transports: {
    cp: {
      api: process.env.VUE_APP_API,
      oauth: process.env.VUE_APP_OAUTH,
    },

    module: process.env.VUE_APP_MODULE,
    timeout: process.env.VUE_APP_TIMEOUT || 5000
  },
  version: {
    client: app,
  },
  pusherEndpoint: process.env.VUE_APP_PUSHER_AUTH,
  hederaExplorer: process.env.VUE_APP_HEDERA_EXPLORER
};

const isDevelopment = () => config.env === 'development'
const isStaging = () => config.env === 'staging'
const isProduction = () => config.env === 'production'

if(isStaging()) {
  config.cdn = 'https://cdn-apps.sayl.cloud/staging/conn3ct-wallet/'
}

export default config
export {
  isDevelopment,
  isProduction,
  isStaging,
}
