export default {
  fqn: 'ifm.conn3ct.dashboard',
  endpoints: {
    entity: {
      get: '/user/wallet/dashboard'
    },
  },

  transport: 'cp'
}
