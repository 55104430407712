<script>
import navigationData from '@/data/navigation'
import IconQr from '@/icons/qr.vue'

export default {
  name: "NavPrimary",

  computed: {
    menuItems() {
      const items = navigationData.filter(i => i.mobile)

      return [
        ...items.slice(0, 2),
        {
          slug: 'qr',
          action: 'showQr',
          icon: IconQr,
        },
        ...items.slice(2, 4),
      ]
    },
    emailVerified() {
        return this.$user.user?.emailVerified ?? false
    },
  },

  methods: {
    showQr() {
      this.$emit('showQr')
    },

    triggerAction(action) {
      if(action) {
        this[action]()
      }
    }
  }
}
</script>

<template>
  <nav class="nav-primary">
    <component
      v-for="(item, i) in menuItems"
      @click="triggerAction(item.action)"
      class="nav-primary__menu-item"
      :class="{
        '-qr': item.action === 'showQr',
      }"
      :is="item.route ? 'router-link' : 'button'"
      :key="i"
      :to="{ name: item.route }"
    >
      <component
        class="icon"
        :is="item.icon"
      />

      <span
        v-if="item.label"
        class="label"
      >{{ $t(item.label) }}</span>
    </component>
  </nav>
</template>
