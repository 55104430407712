
<template>
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.4891 2V4.1875V2ZM32.6059 28.25H36.9781H32.6059ZM23.8613 28.25H19.4891V37L23.8613 28.25ZM19.4891 12.9375V19.5V12.9375ZM19.4891 19.5H19.5109H19.4891ZM19.4891 19.5H28.2555H19.4891ZM28.2336 37H36.9781H28.2336ZM2 19.5H10.7445H2ZM36.9781 19.5H37H36.9781ZM4.18613 10.75H8.5584C9.1382 10.75 9.69425 10.5195 10.1042 10.1093C10.5142 9.69906 10.7445 9.14266 10.7445 8.5625V4.1875C10.7445 3.60734 10.5142 3.05094 10.1042 2.6407C9.69425 2.23047 9.1382 2 8.5584 2H4.18613C3.60633 2 3.05028 2.23047 2.6403 2.6407C2.23032 3.05094 2 3.60734 2 4.1875V8.5625C2 9.14266 2.23032 9.69906 2.6403 10.1093C3.05028 10.5195 3.60633 10.75 4.18613 10.75ZM30.4197 10.75H34.792C35.3718 10.75 35.9279 10.5195 36.3378 10.1093C36.7478 9.69906 36.9781 9.14266 36.9781 8.5625V4.1875C36.9781 3.60734 36.7478 3.05094 36.3378 2.6407C35.9279 2.23047 35.3718 2 34.792 2H30.4197C29.8399 2 29.2839 2.23047 28.8739 2.6407C28.4639 3.05094 28.2336 3.60734 28.2336 4.1875V8.5625C28.2336 9.14266 28.4639 9.69906 28.8739 10.1093C29.2839 10.5195 29.8399 10.75 30.4197 10.75ZM4.18613 37H8.5584C9.1382 37 9.69425 36.7695 10.1042 36.3593C10.5142 35.9491 10.7445 35.3927 10.7445 34.8125V30.4375C10.7445 29.8573 10.5142 29.3009 10.1042 28.8907C9.69425 28.4805 9.1382 28.25 8.5584 28.25H4.18613C3.60633 28.25 3.05028 28.4805 2.6403 28.8907C2.23032 29.3009 2 29.8573 2 30.4375V34.8125C2 35.3927 2.23032 35.9491 2.6403 36.3593C3.05028 36.7695 3.60633 37 4.18613 37Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'QrIcon'
}
</script>
