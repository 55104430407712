<template>
  <div
    v-if="$message.originStatus !== 'blocked'"
    id="app"
    :class="classes"
  >
    <section
      v-if="isLoading && !isErrored"
      class="app-loader container">

      <div class="app-loader__loading">
        <ui-loader size="l" />
      </div>
    </section>

    <router-view v-if="!isLoading" />
  </div>

  <blocked-origin v-else />
</template>

<script>
import { mapState } from 'vuex'

import BlockedOrigin from '@/modules/user/views/blocked-origin.vue'

export default {
  name: "SaylConnect",

  components: {
    BlockedOrigin,
  },

  watch: {
    '$user.isAuthenticated'(val) {
      if(val && !this.$pusher.pusher && this.data) {
        this.$pusher.connect(this.$basil.get(this.data, 'pusher.key'), this.$basil.get(this.data, 'pusher.cluster'));
      }
    },

    data(val) {
      if(val && this.$user.isAuthenticated && !this.$pusher.pusher) {
        this.$pusher.connect(this.$basil.get(val, 'pusher.key'), this.$basil.get(val, 'pusher.cluster'));
      }
    }
  },

  computed: {
    ...mapState({
      loading: state => state.bootstrap.loading,
      errors: state => state.bootstrap.errors,
      data: state => state.bootstrap.data,
    }),

    classes() {
      return {
        '-is-loading': this.isLoading,
        '-is-active': !this.isLoading,
      }
    },

    isLoading() {
      return this.loading
    },

    isErrored() {
      return this.errors && this.errors.length > 0
    },
  },

  mounted() {
    if(this.$basil.get(this.$user, 'user.lang')) {
      this.setLocale(this.$user.user.lang)
    }
    if(this.$basil.get(this.$user, 'isAuthenticated') === true) {
      this.$pusher.disconnect();
      this.$pusher.connect(this.$basil.get(this.pusher, 'key'), this.$basil.get(this.pusher, 'cluster'));
    }
    this.$message.send('ready', true)
  }
}
</script>
