export default {
  // dashboard_edit_wallet_quick_list: 'Edit quick list',
  // dashboard_download_wallet_pass: 'Download your wallet pass on your device',
  // dashboard_wallets_list_title: 'Your Wallets',
  // dashboard_connect_external_wallet: 'Connect an external wallet',
  // dashboard_wallet_more_nfts: '+{count} pcs.',
  // wallet_content_title: 'Your NFTs',
  // wallet_search_placeholder: 'Search in collection',
  // nft_info_id: 'ID info:',
  // nft_info_token_address: 'Address:',
  // nft_info_chain: 'Blockchain:',
  // nft_sender: 'Send by {sender}',
  // transfer_accept_action: 'Accept transfer',
  // claim_accept_action: 'Claim NFT',
  // transfer_success_title: 'Transfer successful',
  // claim_success_title: 'Claim successful',
  // settings_about_title: 'About',
  // settings_version_label: 'Current version:',
  // private_key_summary_title: 'Your private key',
  // settings_wallet_delete: 'Delete',
  // edit_wallet_cancel_lbl: 'Cancel',
  // edit_wallet_submit_lbl: 'Save',
  // external_wallet_edit_error: 'Error while editing wallet',
  // external_wallet_delete_error: 'Error while deleting wallet',
  // external_wallet_updated: 'Wallet updated',
  // challenge_progress_title: 'Challenge progress',
  // wallet_description_close: 'Close',
  // logout_description: 'Do you want to logout?',
  // dashboard_more_wallets: 'You have {count} more wallets, see them',
  // invalid_phone_number_format: 'Invalid phone number',
  // settings_wallet_delete_description: 'Do you want to delete this wallet?',
  // cancel: 'Cancel',
  // dasboard_nfts: 'Your NFTs',
  // view: 'View',
  // brands_filter_placeholder: 'All brands',
  // dashboard_wallet_empty: 'You have no NFTs yet',
  // open_profile: 'Go to profile',
  // no_nfts_result_title: 'No Result',
  // no_nfts_result_description: 'No NFTs fitting your filters have been found',
  // nft_sort_by_date: 'By date',
  // nft_sort_by_name: 'By name',
  // in_transfer: 'In transfer',
  // wallet_brands: 'All your brands',
  // nft_vouchers_tab_title: 'Vouchers to generate',
  // nft_perks_tab_title: 'Perks',
  // nft_card_go_to_vouchers: 'Go to vouchers ({count})',
  // nft_card_go_to_messages: 'Go to messages ({count})',
  // voucher_transfer_done: 'Voucher transfered successfully',
  // voucher_transfer_revoked: 'Voucher transfer revoked',
  // poap_info_token_transaction: 'Blockchain transaction:',
  // poap_already_collected_within_timeframe: 'Poap already collected within timeframe. Try again later',
  // wallet_show_transfers: 'Go to transfers',
  // transfered_to: 'Send to {destination}',
  // transfer_created_on: 'Created on:',
  // transfer_expire_on: 'Expires on:',
  // transfer_cannot_be_reverted: 'Failled to revert the transfer',
  // origin_blocked_title: 'Origin blocked',
  // origin_blocked_description: 'The URL {origin} is not allowed to access this wallet',
  // pin_title: 'Enter your pin code',
  // cancel_pin: 'Cancel',
  // download_pass_instruction: 'Instructions',
  // qr_modal_title: 'Your wallet address',
  // nft_peer_transfer_expîred_title: 'Peer transfer expired',
}
