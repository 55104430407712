import Vue from 'vue'
import { CurryApi } from '@spices/curry'
import config from './config'
import Brand from './models/brand'
import Brands from './models/brands'

let instance

export const getInstance = () => instance

export const useBrands = ({ transports }) => {
  if(instance) {
    return instance
  }

  instance = new Vue({
    data() {
      return {
        api: null,

        brands: new Brands([])
      }
    },

    methods: {
      async getBrands(invalidate = true) {
        try {
          if(!invalidate && !this.brands?.isCacheValid) {
            return this.brands.list ?? []
          }

          let { data } = await this.api.get({ type: 'list' })

          this.brands = new Brands(data)

          return this.brands.list
        } catch(e) {
          throw e
        }
      },

      async getBrand({ id }) {
        try {
          let { data } = await this.api.get({ type: 'entity', payload: { id } })

          const brand = new Brand(data)

          return brand
        } catch(e) {
          throw e
        }
      },

      reset() {
        this.brands = new Brands([])
      }
    },

    created() {
      this.api = new CurryApi({ config, transports })
    }
  })

  return instance
}

export const BrandsPlugin = {
  install(Vue, options) {
    Vue.prototype.$brands = useBrands(options)
  }
}
