export default class {
  constructor({
    frequency_count = null,
    frequency_unit = null,
    trigger_amount = null,
  }) {
    this._frequencyCount = frequency_count
    this._frequencyUnit = frequency_unit
    this._triggerAmount = trigger_amount
  }

  get frequencyCount() {
    return this._frequencyCount
  }

  get frequencyUnit() {
    return this._frequencyUnit
  }

  get triggerAmount() {
    return this._triggerAmount
  }
}
