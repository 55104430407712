import IconArrow from './arrow.vue'
import IconAward from './award.vue'
import IconBurgerMenu from './burger-menu.vue'
import IconCarret from './carret.vue'
import IconCog from './cog.vue'
import IconCopy from './copy.vue'
import IconCross from './cross.vue'
import IconEgg from './egg.vue'
import IconGraph from './graph.vue'
import IconHandbag from './handbag.vue'
import IconHouse from './house.vue'
import IconLink from './link.vue'
import IconLogout from './logout.vue'
import IconMessageDots from './message-dots.vue'
import IconNotificationBell from './notification-bell.vue'
import IconPen from './pen.vue'
import IconQr from './qr.vue'
import IconSayl from './sayl.vue'
import IconSearch from './search.vue'
import IconTicket from './ticket.vue'
import IconTrash from './trash.vue'
import IconUser from './user.vue'
import IconWallet from './wallet.vue'
import IconTransfer from './transfer.vue'

const icons = {}

icons.install = Vue => {
  Vue.component('icon-arrow', IconArrow)
  Vue.component('icon-award', IconAward)
  Vue.component('icon-burger-menu', IconBurgerMenu)
  Vue.component('icon-carret', IconCarret)
  Vue.component('icon-cog', IconCog)
  Vue.component('icon-copy', IconCopy)
  Vue.component('icon-cross', IconCross)
  Vue.component('icon-egg', IconEgg)
  Vue.component('icon-graph', IconGraph)
  Vue.component('icon-handbag', IconHandbag)
  Vue.component('icon-house', IconHouse)
  Vue.component('icon-link', IconLink)
  Vue.component('icon-logout', IconLogout)
  Vue.component('icon-message-dots', IconMessageDots)
  Vue.component('icon-notification-bell', IconNotificationBell)
  Vue.component('icon-pen', IconPen)
  Vue.component('icon-qr', IconQr)
  Vue.component('icon-sayl', IconSayl)
  Vue.component('icon-search', IconSearch)
  Vue.component('icon-ticket', IconTicket)
  Vue.component('icon-trash', IconTrash)
  Vue.component('icon-user', IconUser)
  Vue.component('icon-wallet', IconWallet)
  Vue.component('icon-transfer', IconTransfer)
}

export default icons
