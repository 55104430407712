export default Object.assign({}, {
  BOOTSTRAP_ERROR: { code: 425, slug: 'bootstrap-error' },
  SHOP_NOACTIVESERVICE: { code: 200, slug: 'shop-noactiveservice' },
  
  SERVICE_NOTACTIVE: { code: 201, slug: 'service-notactive' },
  SERVICE_NOCATALOG: { code: 202, slug: 'service-nocatalog'},
  SERVICE_NOTFOUND: {code : 203, slug: 'service-notfound'},
  SHOP_NOT_PUBLISHED: { code: 204, slug: 'shop-not-published' },

  SERVICE_EATIN_NO_TABLE: { code: 250, slug: 'service-eatin-no-table'},
  SERVICE_EATIN_TABLE_INACTIVE: { code: 251, slug: 'service-eatin-table-inactive'},
  SERVICE_EATIN_TABLE_NOTFOUND: { code: 252, slug: 'service-eatin-table-notfound'},

  ORDER_NOTFOUND: { code: 300, slug: 'order-notfound' },
  ORDER_PAYMENT_NOTVALID: { code: 301, slug: 'order-payement-notvalid' }
})
