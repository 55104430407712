export default [
  {
    component: () => import('./views/messaging.vue'),
    path: '/messaging',
    name: 'sayl-connect_messaging-messaging',
    meta: {
      module: 'messaging',
      requireAuth: true
    },

    children: [
      {
        component: () => import('./views/details.vue'),
        path: ':id',
        name: 'sayl-connect_messaging-details',
        meta: {
          module: 'messaging',
          requireAuth: true
        }
      }
    ]
  },
]
