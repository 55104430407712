import Vue from 'vue'
import MessageController from './controller'

let instance

export const getInstance = () => instance

export const useMessage = ({ transports, router }) => {
  if(instance) return instance;

  instance = new Vue({
    data() {
      return {
        ctrl: null
      }
    },

    computed: {
      linked() {
        return this.$basil.get(this.ctrl, '_linked', false)
      },

      origin() {
        return this.ctrl._origin
      },

      originStatus() {
        return this.ctrl._originStatus
      },

      name() {
        return this.ctrl._name
      },
    },

    methods: {
      action(payload) {
        this.ctrl.onAction(payload)
      },

      send(field, msg) {
        this.ctrl.sendData(field, msg)
      },

      close() {
        this.ctrl.close()
      },

      get(field) {
        return this.ctrl.getData(field)
      },
    },

    created() {
      this.ctrl = new MessageController({ transports, router })
      this.ctrl.init()
    },
  })

  return instance
}

export const MessagePlugin = {
  install(Vue, options) {
    Vue.prototype.$message = useMessage(options);
  }
}
