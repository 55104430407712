import VueI18n from 'vue-i18n'
import Locale from './locale'
import { basil } from '@spices/basil'
import { isDevelopment } from '@/config'

export default class i18n {
  constructor({ locale, transports, Vue, missing }) {
    let l = locale || navigator.language
    l = l.length === 2 ? `${l}_${l.toUpperCase()}` : l // Cheap convertion to RFC 5646 if only language is provided. nl -> nl_NL

    this.locale = new Locale(l)
    this.locales = [ this.locale ]
    this.transports = transports
    this.config = {}
    this.messages = {}

    Vue.use(VueI18n)
    Vue.prototype.$date = this.formatDate.bind(this)
    this.i18n = new VueI18n({
      locale: l,
      fallbackLocale: l,
      messages: this.messages,
      silentTranslationWarn: true,
      missing: missing ? this.missingTranslationHandler : null
    })
    // this.setBrowserLocale(this.locale)
  }

  missingTranslationHandler(locale, key, _target, params) {
    try {
      let missingTranslations = sessionStorage.getItem('missing_translations')

      if(basil.isNil(missingTranslations)) missingTranslations = []
      else missingTranslations = JSON.parse(missingTranslations)

      if(missingTranslations.find(t => t.key === key)) return

      missingTranslations.push({ key, params: basil.get(params, '[0]', null), locale })

      sessionStorage.setItem('missing_translations', JSON.stringify(missingTranslations))
    } catch(e) {
      console.error(e)
    }
  }

  //////////////////////////////////////////////////////////////////

  /**
   * List all the locale string available
   * @property {Array<String>}
   */
  get localeStrings(){
    return this.locales.map(l => l.toString())
  }

  /**
   * Get the locale in the local storage
   * @property {Locale}
   */
  get localeStored() {
    let v = localStorage.getItem('locale')
    return !!v ? new Locale(v) : null
  }

  //////////////////////////////////////////////////////////////////

  /**
   * Find the locale associated with the given value
   *
   * @param {String} value
   * @returns {Locale}
   */
  getLocaleForValue(value) {
    return this.locales.find(l => (l.langtag == value || l.iso == value || l.lang.toString() == value))
  }

  /**
   * Whether or not the locale is valid
   *  - Allowed by the list of locales
   * @param {Locale} locale
   * @returns {Boolean}
   */
  isValid(locale) {
    return  !basil.isNil(locale) && // Must be set
            locale instanceof Locale && // Must be a locale
            locale.valid && // Must be a valid locale
            this.localeStrings.includes(locale.toString()) // Must be part of the list of locales
  }

  /**
   * Make sure the appropriate language is set
   * - Check if the stored locale is valid
   * - Or check if the requested locale is valid
   * - Or use the fallback
   * - Or report an error
   */
  update(){
    let locale = null;

    let l = [this.localeStored, this.locale, this.fallback]
    l = l.map(e => e instanceof Locale ? e.toString() : e)
         .filter(i => !basil.isNil(i))

    let n = l.length
    let i = -1
    while(i++ < n){
      let v = l[i]
      if (this.isValid(new Locale(v))){
        locale = v
        break
      }
    }

    if (!locale){
      console.error('[i18n] No valid languages for the current configuration')
      console.info(`stored: ${this.localeStored}`)
      console.info(`locale: ${this.locale}`)
      console.info(`fallback: ${this.fallback}`)
      console.info(`locales: ${this.localeStrings}`)
      return
    }

    // console.info(`stored: ${this.localeStored}`)
    // console.info(`locale: ${this.locale}`)
    // console.info(`fallback: ${this.fallback}`)
    // console.info(`locales: ${this.localeStrings}`)
    // console.info(`-> locale: ${locale}`)

    this.setLocale(locale)
  }

  /**
   * Define the configuration
   *
   * @param {*} value
   */
  setConfig(value) {
    this.config = Object.assign(this.config, value);

    // DateTime
    if (value.datetime) {
      value.datetime.forEach(({ iso, ...formats }) => {
        this.i18n.setDateTimeFormat(new Locale(iso).toString(), formats)
      });
    }

    // Number
    if (value.number) {
      value.number.forEach(({ iso, ...formats }) => {
        this.i18n.setNumberFormat(new Locale(iso).toString(), formats)
      })
    }

    // fallback
    if (value.fallback_language) {
      this.fallback = new Locale(value.fallback_language)
      this.i18n.fallbackLocale = this.fallback.toString()
    }

    // languages
    this.locales = value.languages.map(l => new Locale(l))
    // this.i18n.availableLocales = value.languages;

    // Update the current local based on the list of locale
    // e.g
    // 1. Anise.lang provide nl.
    // 2. We cheap convert it to nl_NL
    // 3. The server provide nl_BE instead of nl_NL. Convert the current local to nl_BE.
    this.locale = this.getLocaleForValue( this.locale.lang.toString() )


    // messages
    if (value.messages) {
      value.messages.forEach(({ iso, messages }) => {
        let m = messages

        // TODO: improve system to get local translations
        // !! made to have translation in local only !!
        if(isDevelopment && iso === 'en_GB') {
          const { default: translations } = require('./dev-translations')

          m['conn3ct-wallet'] = {
            ...m['conn3ct-wallet'],
            ...translations
          }
        }

        let v = new Locale(iso)

        if (!this.messages.hasOwnProperty(v.toString())) {
          this.messages[v.toString()] = {}
        }

        let entry = Object.assign(this.messages[v.toString()], m)
        this.i18n.setLocaleMessage(v.toString(), entry)
      });
    }

    // this.setLocale(this.locale.toString());
  }

  /**
   * Set the current locale
   *
   * @param {String} value
   */
  setLocale(value) {
    let l = value instanceof Locale ? value : new Locale(value)

    // Not valid
    if (!l.valid || !this.isValid(l)){
      console.warn(`[i18n] Unknown locale ${value}. Allowed values ${this.localeStrings}`)
      return
    }

    // Not in the allowed set
    // let locale = this.locales.find(nl => nl.iso === l.iso);
    this.locale = this.getLocaleForValue(l.iso)

    localStorage.setItem('locale', this.locale.toString())
    localStorage.setItem('lang', this.locale.lang)
    this.i18n && (this.i18n.locale = this.locale.toString())

    // Injekt
    let tracker = basil.get(sayl, 'injekt.sp')
    if (tracker){
      tracker.lang = this.locale.lang;
    }

    // Update browser
    this.setBrowserLocale(this.locale)
  }

  /**
   * Update the browser information
   * @param {*} locale
   */
  setBrowserLocale(locale){
    // Transports
    let k = Object.keys(this.transports);
    k.forEach(t => {
      t = this.transports[t];
      if (t.hasOwnProperty('defaults')) {
        t.defaults.headers.common['Accept-Language'] = locale.iso
      }
    })

    // Document language
    document.querySelector('html').setAttribute('lang', locale.toString())
  }

  formatDate(value, format) {
    let values = this.config.datetime || []
    let setting = values.find(v => v.iso === this.locale.langtag);
    let opts = basil.get(setting, format, {
      "year": "numeric",
      "month": "numeric",
      "day": "numeric",
      "hour": "numeric",
      "minute": "numeric",
      "hour12": false
    })

    let ret =  new Intl.DateTimeFormat(this.locale.langtag, opts).format(value)
    return ret;
  }



  toCurrency(value, symbol = '€') {
    let symbols = [
      'лв', 'CHF', 'Kč', 'kr', '€', '£', 'kn', '₾', 'ft', 'kr', 'zł', '₽', 'lei', '₺', '₴',
      'د.إ', '₪', 'Ksh', '.د.م', '₦', 'R',
      'R$', '$', 'S/.',
      '৳', '¥', '元', 'HK$', 'Rp', '₹', '¥', 'RM', '₱', 'Rs', '₩', '฿', '₫',
      '₿', 'XRP', 'ɱ', 'Ł', 'Ξ'
    ]

    let current = this.config.number.find(c => c.iso === this.locale.toString())
    let norm = current && current.currency && current.currency.currency ? current.currency.currency : 'EUR'
    let formatter = new Intl.NumberFormat(this.locale.toString(), { style: 'currency', currency: norm })
    let ret = formatter.format(value)

    origin = symbols.find(s => ret.includes(s))
    ret = ret.replace(origin, symbol)

    return ret
  }

}
