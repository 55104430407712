export default [
  {
    component: () => import('./views/wallet.vue'),
    path: '/wallet/:id',
    name: 'sayl-connect_wallet-wallet',
    meta: {
      module: 'wallet',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/nft.vue'),
    path: '/nft/:id',
    name: 'sayl-connect_wallet-nft',
    meta: {
      module: 'wallet',
      requireAuth: true,
      mode: 'view'
    }
  },

  {
    component: () => import('./views/nft.vue'),
    path: '/transfer/:id',
    name: 'sayl-connect_wallet-transfer',
    meta: {
      module: 'wallet',
      requireAuth: true,
      mode: 'transfer'
    }
  },

  {
    component: () => import('./views/nft.vue'),
    path: '/claim/:id',
    name: 'sayl-connect_wallet-claim',
    meta: {
      module: 'wallet',
      requireAuth: true,
      mode: 'claim'
    }
  },

  {
    component: () => import('./views/claimables.vue'),
    path: '/collectibles',
    name: 'sayl-connect_wallet-claimables',
    meta: {
      module: 'wallet',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/transfers.vue'),
    path: '/transfers',
    name: 'sayl-connect_wallet-transfers',
    meta: {
      module: 'wallet',
      requireAuth: true
    }
  },
]
