/**
 * Application status
 */
import states from '@/bootstrap/states'
import Brand from '@/datalayer/brands/models/brand'

export default {
  name: 'bootstrap',
  namespaced: true,

  state: {
    fqn: null,
    model: null,

    // Loading
    errors: [],
    status: 0,
    states: states,

    // i18n
    i18n: null,

    // Config (cp bootstrap)
    data: null,
    args: null,
    loading: true,

    navigationParams: null,
  },

  getters: {},

  actions: {
    error({ commit }, value){
      commit('addError', value)
    },

    getConfig({ state }){
      return Promise.resolve( state.data )
    },

    locale({ state }, value){
      state.i18n.setLocale(value.locale)
    },

    setNavigationParams({ commit }, value) {
      commit('setNavigationParams', value);
    },

    status({ commit }, status){
      commit('setStatus', status)
    },
  },

  mutations: {
    addError(state, value){
      state.errors.push(value)
    },

    setArgs(state, value){
      state.args = value
    },

    setConfig(state, value) {
      if(value.sayl_app_brand) {
        value.brand = new Brand(value.sayl_app_brand)
      }

      state.data = value
    },

    setI18n(state, value){
      state.i18n = value
      state.locale = value.locale
      state.locales = value.locales
    },

    setLoading(state, value) {
      state.loading = value
    },

    setNavigation(state, value){
      state.navigation = value
    },

    setNavigationParams(state, value) {
      state.navigationParams = value;
    },

    setStatus(state, value){
      state.status = value
    },
  }
}
