import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'

import config from './config'
import Notification from './model'

/**
 * @class
 */
export default class NotificationController {
  /**
   * @constructor
   * @param {Object} options
   * @param {Object} options.transports
   */
  constructor({ transports }) {
    this._api = new CurryApi({ config, transports })

    // Data
    this._debounce = null

    this._notifications = null
    this._notificationsMeta = null

    this._unread = null
    this._unreadCount = null
    this._unreadMeta = null
  }

  //////////////////////////////
  // Getters
  /**
   * @property {Array<Notification>}
   * @readonly
   * @return {Array<Notification>}
   */
  get notifications() {
    return this._notifications
  }

  /**
   * @property {Object}
   * @readonly
   * @return {Object}
   */
  get notificationsMeta() {
    return this._notificationsMeta
  }

  /**
   * @property {Array<Notification>}
   * @readonly
   * @return {Array<Notification>}
   */
  get unread() {
    return this._unread
  }

  /**
   * @property {Number}
   * @readonly
   * @return {Number}
   */
  get unreadCount() {
    return this._unreadCount
  }

  /**
   * @property {Object}
   * @readonly
   * @return {Object}
   */
  get unreadMeta() {
    return this._unreadMeta
  }

  //////////////////////////////
  // Methods
  /**
   * Get the notifications from the server
   * @returns {Promise}
   */
  getNotifications(payload) {
    return new Promise((resolve, reject) => {
      this._api.get({ type: 'notifications', payload })
        .then((r) => Promise.resolve(r.data))
        .then((n) => {
          this._notifications = n.data ? n.data.map((notif) => new Notification(notif)) : []
          this._notificationsMeta = n.meta ? n.meta : { pagination: {}}

          resolve(this._notifications)
        })
        .catch((e) => reject(e))
    })
  }

  /**
   * Get the unread notifications from the server
   * @returns {Promise}
   */
  getUnreadNotifications() {
    return new Promise((resolve, reject) => {
      this._api.get({ type: 'unreadNotifications', payload: {} })
        .then((r) => Promise.resolve(r.data))
        .then((n) => {
          this._unread = n.data ? n.data.map((notif) => new Notification(notif)) : []
          this._unreadMeta = n.meta ? n.meta.pagination : {}

          resolve(this._unread)
        })
        .catch((e) => reject(e))
    })
  }

  /**
   * Get the unread notifications count from the server
   * @returns {Promise}
   */
  getUnreadNotificationsCount() {
    return new Promise((resolve, reject) => {
      this._api.get({ type: 'unreadNotificationsCount', payload: {} })
        .then((r) => Promise.resolve(basil.get(r, 'data', r)))
        .then((n) => resolve(this._unreadCount = n))
        .catch((e) => reject(e))
    })
  }

  /**
   * Mark a notification as read
   * @returns {Promise}
   */
  markAllAsRead() {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'notifications', payload: { item: {} }})
        .then((r) => Promise.resolve(r.data))
        .then((n) => resolve(n))
        .catch((e) => reject(e))
    })
  }

  /**
   * Mark all of the notifications as read
   * @returns {Promise}
   */
  markOneAsRead({ id }) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'unreadNotifications', payload: { id, item: {} }})
        .then((r) => Promise.resolve(r.data))
        .then((n) => resolve(n))
        .catch((e) => reject(e))
    })
  }
}
