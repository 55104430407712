<script>
export default {
  name: 'UiCard',

  props: {
    illustrationFormat: {
      type: String,
    },


    subtitle: {
      type: String
    },

    title: {
      type: String
    },

    tags: {
      type: Array,
      default: () => []
    },

    to: {
      type: Object,
    },
  },

  computed: {
    hasFooter() {
      return !!this.$slots?.footer
    },

    hasIllustration() {
      return !!this.$slots?.illustration
    },

    hasSubtitleSlot() {
      return !!this.$slots?.subtitle
    },

    wrapperComponent() {
      if(this.to) return 'router-link'

      return 'div'
    }
  },
}
</script>

<template>
  <component
    class="ui-card"
    :class="{
      '-is-link': to,
    }"
    :is="wrapperComponent"
    :to="to"
  >
    <ui-panel class="ui-card__panel">
      <div
        v-if="hasIllustration"
        class="ui-card__illustration"
        :class="{
          '-is-banner': illustrationFormat === 'banner',
        }"
      >
        <slot name="illustration"></slot>

        <div class="ui-card__overlay">
          <span
            v-for="(tag, i) in tags"
            class="ui-card__tag"
            :key="$basil.uniqId(i)"
          >{{ tag }}</span>
        </div>
      </div>

      <div class="ui-card__main">
        <div class="ui-card__body">
          <h4 class="ui-card__title">{{ title }}</h4>

          <template v-if="hasSubtitleSlot || subtitle">
            <slot
              v-if="hasSubtitleSlot"
              name="subtitle"
            ></slot>

            <span
              v-else
              class="ui-card__subtitle"
            >{{ subtitle }}</span>
          </template>
        </div>

        <slot></slot>
      </div>

      <template
        v-if="hasFooter"
        v-slot:footer
      >
        <div class="ui-card__footer">
          <slot name="footer"></slot>
        </div>
      </template>
    </ui-panel>
  </component>
</template>
