import { DateTime } from 'luxon'

export default class {
  constructor(args) {
    this._id = args.id
    this._challengeId = args.challenge_id
    this._created = args.created ? DateTime.fromISO(args.created) : null
  }

  get id() {
    return this._id
  }

  get challengeId() {
    return this._challengeId
  }

  get created() {
    return this._created
  }
}
