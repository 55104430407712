<script>
export default {
  name: 'TrashIcon'
}
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 5H6M14 5H10M6.5 7.5V11.5M9.49999 7.5V11.5M12.5 5.00001C12.3875 6.7245 12 14 12 14H4.00001C4.00001 14 3.60303 6.57986 3.49999 5.00001M6 5H10M6 5V2H10V5" stroke="#24282B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
