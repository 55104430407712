import TriggerLimit from './trigger-limit'
import { basil } from '@spices/basil'
import Grant from './grant'

/**
 * @class
 */
export default class Perk {
  /**
   * @param {Object} data
   * @param {Object} data.client_message
   * @param {Object} data.condition
   * @param {Object} data.grant
   * @param {Object} data.message
   * @param {Object} data.name
   *
   * @param {Object} options
   * @param {String} options.locale
   */
  constructor(
    {
      client_message = { all: null },
      name = { all: null },
      wallet_message = { all: null },
      trigger_limit = {},
      grant = {},
      id = null
    },
    options = { locale: 'all' }
  ) {
    this._clientMessage = client_message
    this._name = name
    this._walletMessage = wallet_message
    this._id = id

    this._locale = options.locale
    this._triggerLimit = new TriggerLimit(trigger_limit ?? {})
    this._grant = new Grant(grant, options)
  }

  ////////////////////////////////
  // Getters
  /**
   * @property {String} clientMessage
   * @readonly
   * @return {String} clientMessage
   */
  get clientMessage() {
    return this._clientMessage.hasOwnProperty(this._locale) ?
      this._clientMessage[this._locale] :
      this._clientMessage
  }

  get id() {
    return this._id
  }

  get grant() {
    return this._grant
  }

  get totalLimit() {
    return this._triggerLimit.triggerAmount
  }

  get frequencyLimit() {
    return this._triggerLimit.frequencyCount
  }

  get frequencyUnit() {
    return this._triggerLimit.frequencyUnit
  }

  get hasTotalLimit() {
    const count = basil.get(this._triggerLimit, 'triggerAmount')

    return basil.isNil(count) ? false : true
  }

  /**
   * @property {String} name
   * @readonly
   * @return {String} name
   */
  get name() {
    return this._name.hasOwnProperty(this._locale) ?
      this._name[this._locale] :
      this._name
  }

  get walletMessage() {
    let lang = localStorage.getItem('lang') ?? 'all'

    return this._walletMessage.hasOwnProperty(lang)
      ? this._walletMessage[lang]
      : this._walletMessage

  }
}
