import { basil } from '@spices/basil'
import Brand from './brand'
import Metadata from './nft_metadata'
import Web3Trigger from './web3-trigger'
import BlockchainConfig from '@/data/blockchain-config'
import { isDevelopment } from '@/config'
import axios from 'axios'
import Voucher from './voucher'
import SaylNft from './sayl-nft'
import Web3Voucher from './web3-voucher'

/**
 *
 * @class
 */
export default class Nft {
  static hederaNetwork = isDevelopment() ? 'testnet' : 'mainnet'
  static networks = {}

  constructor({
    asset_contract = null,
    balance = 0,
    chain = null,
    description = null,
    image_url = null,
    max_supply = 0,
    metadata = null,
    name = null,
    owner = null,
    sayl_app_brand = null,
    sayl_nft = null,
    sayl_nft_id = null,
    token_id = null,
    total_supply = 0,
    web3_triggers = [],
    optimized_image_url = null,
    vouchers = [],
    web3_vouchers = [],
    asset_url = null,
  }) {
    this._assetContract = asset_contract
    this._balance = balance
    this._chain = BlockchainConfig.find(c => c.values.includes(chain ? chain : `hedera_${Nft.hederaNetwork}`)) // chain ? chain : `hedera_${Nft.hederaNetwork}`
    this._description = description ? description : basil.get(sayl_nft, 'description')
    this._imageUrl = optimized_image_url || image_url || asset_url
    this._maxSupply = max_supply
    this._metadata = !basil.isNil(metadata) ? new Metadata(metadata) : new Metadata({})
    this._name = name
    this._owner = owner
    this._saylAppBrand = !basil.isNil(sayl_app_brand) ? new Brand(sayl_app_brand) : null
    this._saylNft = !basil.isNil(sayl_nft) ? new SaylNft(sayl_nft) : null
    this._saylNftId = sayl_nft_id
    this._tokenId = token_id
    this._totalSupply = total_supply
    this._web3Triggers = !basil.isNil(web3_triggers) ? web3_triggers.map((w) => new Web3Trigger(w)) : []
    this._imageType = null
    this._vouchers = !basil.isNil(vouchers) ? vouchers.map(v => new Voucher(v)) : []
    this._web3Vouchers = !basil.isNil(web3_vouchers) ? web3_vouchers.map(v => new Web3Voucher(v)) : []

    Nft.networks[this._chain.value] = this._chain.name
  }

  get web3Vouchers() {
    return this._web3Vouchers
  }

  get vouchers() {
    return this._vouchers
  }

  // Getters
  get address() {
    return basil.get(this.assetContract, 'token_id', this.assetContract)
  }

  /**
   * @property {String} assetContract
   * @readonly
   * @return {String} assetContract
   */
  get assetContract() {
    return this._assetContract
  }

  /**
   * @property {Number} balance
   * @readonly
   * @return {Number} balance
   */
  get balance() {
    return this._balance
  }

  get chain() {
    return this._chain
  }

  /**
   * @property {String} description
   * @readonly
   * @return {String} description
   */
  get description() {
    return this._description
  }

  get explorerLink() {
    if(!this._chain) {
      return null
    }

    return this._chain.nftLink(this.address)
  }

  get id() {
    return (this.saylNftId || this.address) + `_${this.tokenId}`
  }

  /**
   * @property {String} imageUrl
   * @readonly
   * @return {String} imageUrl
   */
  get imageUrl() {
    return this._imageUrl
  }


  get imageType() {
    return (async () => {
      try {
        if(!basil.isNil(this._imageType)) {
          return this._imageType
        }

        const { headers } = await axios.head(`${this._imageUrl}?not-from-cache-please`)

        if(basil.get(headers, 'content-type', '').includes('video')) {
          this._imageType = 'video'
        } else {
          this._imageType = 'image'
        }

        return this._imageType
      } catch(e) {
        $console.error(e)

        this._imageType = 'image'

        return this._imageType
      }
    })()
  }

  /**
   * @property {Number} maxSupply
   * @readonly
   * @return {Number} maxSupply
   */
  get maxSupply() {
    return this._maxSupply
  }

  /**
   * @property {Metadata} metadata
   * @readonly
   * @return {Metadata} metadata
   */
  get metadata() {
    return this._metadata
  }

  /**
   * @property {String} name
   * @readonly
   * @return {String} name
   */
  get name() {
    return this._name
  }

  get networks() {
    return Nft.networks
  }

  /**
   * @property {String} owner
   * @readonly
   * @return {String} owner
   */
  get owner() {
    return this._owner
  }

  /**
   * @property {String} saylAppBrand
   * @readonly
   * @return {String} saylAppBrand
   */
  get saylAppBrand() {
    return this._saylAppBrand
  }

  set saylAppBrand(brand) {
    this._saylAppBrand = brand
  }

  /**
   * @property {Object} saylNft
   * @readonly
   * @return {Object} saylNft
   */
  get saylNft() {
    return this._saylNft
  }

  /**
   * @property {String} saylNftId
   * @readonly
   * @return {String} saylNftId
   */
  get saylNftId() {
    return this._saylNftId
  }

  /**
   * @property {Number} tokenId
   * @readonly
   * @return {Number} tokenId
   */
  get tokenId() {
    return this._tokenId
  }

  /**
   * @property {Number} totalSupply
   * @readonly
   * @return {Number} totalSupply
   */
  get totalSupply() {
    return this._totalSupply
  }

  /**
   * @property {Array} web3Triggers
   * @readonly
   * @return {Array} web3Triggers
   */
  get web3Triggers() {
    return this._web3Triggers
  }
}
