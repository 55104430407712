import { mix } from '@/factories'
import Cached from '@/factories/cached'
import Voucher from '../../wallet/models/wallet/nft/voucher'
import Nft from '../../wallet/models/wallet/nft/nft'

export default class Web3Vouchers extends mix().with(Cached) {
  constructor(vouchers) {
    super()

    this.list = vouchers.map(v => {
      return {
        nft: new Nft(v.nft),
        tokenId: v.token_id,
        vouchers: v.vouchers?.map(v => new Voucher(v))
      }
    })
  }
}
