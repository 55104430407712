import VueBodyClass from '../router/body-class'

/**
 * Handle the body class
 *  => Use the body class meta to add the right ones on the body
 */
export default ({ logger, store, router }) => {
  return new Promise((resolve) => {
    logger.group('bootstrap.body');

    const routes = store.getters['ginger/getRoutes']
    const bodyClass = new VueBodyClass(routes)

    // Initialization
    setTimeout(() => {
      const list = [];
      const htmlList = []

      if(document.body.classList.contains('theme-conn3ct')) {
        list.push('theme-conn3ct')
      }

      if(document.documentElement.classList.contains('-no-scroll')) {
        htmlList.push('-no-scroll')
      }

      bodyClass.guard(router.currentRoute, () => {
        document.body.classList.add(...list)
        document.documentElement.classList.add(...htmlList)
      })
    }, 500)

    // Route change
    router.beforeEach((to, from, next) => {
      bodyClass.guard(to, () => {
        const list = []
        const htmlList = []

        if(document.body.classList.contains('theme-conn3ct')){
          list.push('theme-conn3ct')
        }
        if (document.documentElement.classList.contains('-no-scroll')){
          htmlList.push('-no-scroll')
        }

        document.body.classList.add(...list)
        document.documentElement.classList.add(...htmlList)
      })
      next()
    })


    logger.groupEnd('bootstrap.body');
    resolve();
  })
}
