import { basil } from '@spices/basil'
import {
  NftMetadata,
  Nft
} from './wallet'

/**
 * @class
 */
export default class Claimable {
  /**
   * @constructor
   * 
   * @param {Object} data
   * @param {Object} data.created 
   * @param {String} data.destination_address 
   * @param {String} data.email 
   * @param {Object} data.execution_date 
   * @param {String} data.id 
   * @param {Object} data.metadata 
   * @param {Object} data.modified 
   * @param {Object} data.nft 
   * @param {String} data.nft_id 
   * @param {String} data.project_id 
   * @param {String} data.sayl_app_user_id 
   * @param {Number} data.serial 
   * @param {Number} data.status 
   * @param {String} data.token_id 
   * @param {String} data.transaction_id 
   * @param {String} data.user_id 
   */
  constructor({
    created = null,
    destination_address = null,
    email = null,
    execution_date = null,
    id = null,
    metadata = null,
    modified = null,
    nft = null,
    nft_id = null,
    project_id = null,
    sayl_app_user_id = null,
    serial = null,
    status = null,
    token_id = null,
    transaction_id = null,
    user_id = null,
  }) {
    this._created = created
    this._destinationAddress = destination_address
    this._email = email
    this._executionDate = execution_date
    this._id = id
    this._metadata = !basil.isNil(metadata) ? new NftMetadata(metadata) : null
    this._modified = modified
    this._nft = !basil.isNil(nft) ? new Nft(nft) : null
    this._nftId = nft_id
    this._productId = project_id
    this._saylAppUserId = sayl_app_user_id
    this._serial = serial
    this._status = status
    this._tokenId = token_id
    this._transactionId = transaction_id
    this._userId = user_id
  }

  //////////////////////////////
  // Getters
  /**
   * @property {String} created
   * @readonly
   * @return {String} created
   */
  get created() {
    return this._created
  }

  /**
   * @property {String} destinationAddress
   * @readonly
   * @return {String} destinationAddress
   */
  get destinationAddress() {
    return this._destinationAddress
  }

  /**
   * @property {String} email
   * @readonly
   * @return {String} email
   */
  get email() {
    return this._email
  }

  /**
   * @property {String} executionDate
   * @readonly
   * @return {String} executionDate
   */
  get executionDate() {
    return this._executionDate
  }

  /**
   * @property {String} id
   * @readonly
   * @return {String} id
   */
  get id() {
    return this._id
  }

  /**
   * @property {String} metadata
   * @readonly
   * @return {String} metadata
   */
  get metadata() {
    return this._metadata
  }

  /**
   * @property {String} modified
   * @readonly
   * @return {String} modified
   */
  get modified() {
    return this._modified
  }

  /**
   * @property {String} nft
   * @readonly
   * @return {String} nft
   */
  get nft() {
    return this._nft
  }

  /**
   * @property {String} nftId
   * @readonly
   * @return {String} nftId
   */
  get nftId() {
    return this._nftId
  }

  /**
   * @property {String} productId
   * @readonly
   * @return {String} productId
   */
  get productId() {
    return this._productId
  }

  /**
   * @property {String} saylAppUserId
   * @readonly
   * @return {String} saylAppUserId
   */
  get saylAppUserId() {
    return this._saylAppUserId
  }

  /**
   * @property {String} serial
   * @readonly
   * @return {String} serial
   */
  get serial() {
    return this._serial
  }

  /**
   * @property {String} status
   * @readonly
   * @return {String} status
   */
  get status() {
    return this._status
  }

  /**
   * @property {String} tokenId
   * @readonly
   * @return {String} tokenId
   */
  get tokenId() {
    return this._tokenId
  }

  /**
   * @property {String} transactionId
   * @readonly
   * @return {String} transactionId
   */
  get transactionId() {
    return this._transactionId
  }

  /**
   * @property {String} userId
   * @readonly
   * @return {String} userId
   */
  get userId() {
    return this._userId
  }
}
