import { authGuard } from "@/datalayer/user/guard"

export default [
  {
    component: () => import('./views'),
    path: '/onboarding',
    name: 'sayl-connect_onboarding',
    beforeEnter: authGuard,
    children: [
      {
        component: () => import('./views/resend'),
        path: 'resend',
        name: 'sayl-connect_onboarding-resend',
        meta: {
          requireAuth: true,
          class: 'resend'
        }
      },

      {
        component: () => import('./views/confirm-phone'),
        path: 'confirm-phone',
        name: 'sayl-connect_onboarding-confirm-phone',
        meta: {
          requireAuth: true,
          class: 'confirm-phone'
        }
      },

      {
        component: () => import('./views/pin'),
        path: 'pin',
        name: 'sayl-connect_onboarding-pin',
        meta: {
          requireAuth: true,
          class: 'pin'
        }
      },

      {
        component: () => import('./views/info'),
        path: 'info',
        name: 'sayl-connect_onboarding-info',
        meta: {
          requireAuth: true,
          class: 'info'
        }
      },

      {
        component: () => import('./views/terms'),
        path: 'terms',
        name: 'sayl-connect_onboarding-terms',
        meta: {
          requireAuth: true,
          class: 'terms'
        }
      },

      {
        component: () => import('./views/optional-steps'),
        path: 'optionals',
        name: 'sayl-connect_onboarding-optional-steps',
        meta: {
          requireAuth: true,
          class: 'optional-steps'
        }
      },
    ]
  }
]
