import Axios from 'axios'
import { basil } from '@spices/basil'
import Config from '@/config'
import { instance } from '@/bootstrap/plugins/app'
import { getInstance } from '@/datalayer/notification/instance'

const cp = Axios.create({
  withCredentials: true,
  baseURL: Config.transports.cp.api,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
})

let cpt = 0

const response = (response) => {
  cpt = 0
  return response
}

const error = (error) => {
  let ret = {
    status: null,
    _items: {},
    orig: error
  }

  if(error && error.response && error.response.status) {
    ret.status = error.response.status
  }


  if(cpt < 2 && ([502, 504, 501].includes(ret.status))) {
    cpt++
    return cp.request(error.config)
  } else {
    cpt = 0
  }

  if(ret.status === 401) {
    const notificationService = getInstance()
    notificationService.stopNotificationPolling()

    const ignoredRoutes = [
      '/login',
      '/register',
      '/register/phone',
      '/blocked-origin',
      '/reset-password-confirmation',
      '/reset-password',
      '/welcome',
    ]

    cp.defaults.headers.common['X-CSRF-TOKEN'] = basil.get(error, 'response.data.csrf_token');
    if(!ignoredRoutes.includes(window.location.pathname)) {
      return instance.$router.push({ name: 'sayl-connect_landing-login' })
    }

    return;
  }

  if(ret.status === 422) {
    if(error && error.response && error.response.data && error.response.data.errors) {
      ret._items = error.response.data.errors
    } else if(error && error.response && error.response.data && error.response.data.error && error.response.data.error.dataPointer) {
      ret._items[error.response.data.error.dataPointer.join('.')] = ['field_is_required']
    }
  } else if(ret.status === 404) {
    ret._items = { global: ['not_found'] }
  } else if(ret.status === 500) {
    ret._items = { global: ['server_error'] }
  } else {
    ret._items = error
  }

  ret.get = (field)=> {
    return ret._items.hasOwnProperty(field) ?
        ret._items[field] : []
  }

  return [422, 429, 404].includes(ret.status) ? Promise.reject(ret) : Promise.reject(error)
}

cp.interceptors.response.use(response, error);

export default cp
