<script>
export default {
  name: 'UserIcon'
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M12 14C12 13.3333 12 12.6667 12 12C12 10.8954 11.1047 10 10.0001 10C8.81027 10 7.2866 10 5.99899 10C4.89442 10 4 10.8952 4 11.9998C4 12.6705 4 13.3999 4 14M10 5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5Z" stroke="#24282B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
