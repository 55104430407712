export default {
  fqn: 'ifm.conn3ct.poap',

  endpoints: {
    discover: {
      get: '/user/poap/discover?lat=$lat&long=$long&distance=$distance'
    },

    collected: {
      get: '/user/poaps'
    },

    collect: {
      post: '/user/poap/collect'
    }
  },

  transport: 'cp'
}
