import { getInstance } from './instance'

export const authGuard = async (to, from, next) => {
  if(!to.meta?.requireAuth) return next()

  try {
    const userService = getInstance()

    await userService.getUser()

    if(!userService.isAuthenticated) return next({ name: 'sayl-connect_landing-login' })

    if(!userService.user.onboardingCompleted) return next({ name: 'sayl-connect_onboarding' })

    let redirect = window.sessionStorage.getItem('redirect')

    if(redirect) {
      redirect = JSON.parse(redirect)
      window.sessionStorage.removeItem('redirect')
      return next(redirect)
    }

    return next()
  } catch(e) {
    next({ name: 'sayl-connect_landing-login' })
  }
}
