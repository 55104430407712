import Social from './social'
import Image from './image'

export default class Brand {
  constructor(
    {
      id = null,
      country_ids = [],
      name = null,
      description = { all: null },
      website_url = null,
      social_urls = [],
      images = [],
      project_id = null
    },
    options = { locale: 'all' }
  ) {
    this._id = id
    this._coutryIds = country_ids ?? []
    this._name = name
    this._description = description ?? { all: null }
    this._websiteUrl = website_url
    this._socialUrls = (social_urls ?? []).map(s => new Social(s))
    this._images = (images ?? []).map(i => new Image(i))
    this._logo = this.images.find(img => img.fieldName === 'logo')
    this._banner = this.images.find(img => img.fieldName === 'banner')
    this._projectId = project_id ?? null

    this._locale = options?.locale ?? 'all'
  }

  get images() {
    return this._images
  }

  get id() {
    return this._id
  }

  get countryIds() {
    return this._coutryIds
  }

  get name() {
    return this._name
  }

  get logo() {
    return this._logo
  }

  get banner() {
    return this._banner
  }

  get description() {
    let lang = localStorage.getItem('lang') ?? 'all'

    return this._description.hasOwnProperty(lang)
      ? this._description[lang]
      : this._description.all
  }

  get websiteUrl() {
    return this._websiteUrl
  }

  get socialUrls() {
    return this._socialUrls
  }

  get projectId() {
    return this._projectId
  }
}
