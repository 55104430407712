
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5.25V18.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.25001 15.675C2.24906 15.5023 2.3085 15.3346 2.41807 15.2011C2.52763 15.0676 2.68042 14.9765 2.85001 14.9437C3.52456 14.8005 4.12946 14.43 4.56349 13.8941C4.99752 13.3583 5.23435 12.6896 5.23435 12C5.23435 11.3104 4.99752 10.6417 4.56349 10.1059C4.12946 9.57002 3.52456 9.19948 2.85001 9.05625C2.68042 9.02346 2.52763 8.93242 2.41807 8.79889C2.3085 8.66535 2.24906 8.49773 2.25001 8.325V6C2.25001 5.80109 2.32903 5.61032 2.46968 5.46967C2.61033 5.32902 2.8011 5.25 3.00001 5.25H21C21.1989 5.25 21.3897 5.32902 21.5303 5.46967C21.671 5.61032 21.75 5.80109 21.75 6V8.325C21.751 8.49773 21.6915 8.66535 21.582 8.79889C21.4724 8.93242 21.3196 9.02346 21.15 9.05625C20.4755 9.19948 19.8706 9.57002 19.4365 10.1059C19.0025 10.6417 18.7657 11.3104 18.7657 12C18.7657 12.6896 19.0025 13.3583 19.4365 13.8941C19.8706 14.43 20.4755 14.8005 21.15 14.9437C21.3196 14.9765 21.4724 15.0676 21.582 15.2011C21.6915 15.3346 21.751 15.5023 21.75 15.675V18C21.75 18.1989 21.671 18.3897 21.5303 18.5303C21.3897 18.671 21.1989 18.75 21 18.75H3.00001C2.8011 18.75 2.61033 18.671 2.46968 18.5303C2.32903 18.3897 2.25001 18.1989 2.25001 18V15.675Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'TicketIcon'
}
</script>
