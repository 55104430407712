import { default as Config, isStaging, isProduction } from './config'
import UiPanel from '@/components/ui/panel.vue'
import UiCard from '@/components/ui/card.vue'
import UiIllustration from '@/components/ui/illustration.vue'
import lineClamp from 'vue-line-clamp'

if (isStaging()){
  __webpack_public_path__ = 'https://cdn-apps.sayl.cloud/staging/conn3ct-wallet/'
}
if (isProduction()){
  __webpack_public_path__ = 'https://cdn-apps.sayl.cloud/conn3ct-wallet/'
}

import Vue from 'vue'
import VueBootstrap from '@/bootstrap/'
import '@/styles/app.scss'

Vue.config.productionTip = false;

Vue.use(VueBootstrap)
Vue.use(lineClamp)

Vue.component('ui-panel', UiPanel)
Vue.component('ui-illustration', UiIllustration)
Vue.component('ui-card', UiCard)
