
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41067 13.0157L13.0157 4.41074C13.3407 4.08574 13.8682 4.08574 14.1932 4.41074L15.5898 5.80741C15.9148 6.13241 15.9148 6.65991 15.5898 6.98491L6.984 15.5891C6.82817 15.7457 6.6165 15.8332 6.39567 15.8332H4.1665V13.6041C4.1665 13.3832 4.254 13.1716 4.41067 13.0157Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.4585 5.9668L14.0335 8.5418" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'PenIcon'
}
</script>
