import Perk from './perk'
import { DateTime } from 'luxon'
import Config from '@/config'

export default class Voucher {
  constructor(
    {
      id = null,
      code = null,
      name = { all: null },
      perks = [],
      from = null,
      to = null,
      usage_count = 0,
      amount = null,
      amount_type = null,
      type = null,
      used = false,
      last_usage_at = null,
      is_transferable = false,
      child_id = null,
      child = null,
      is_transferred = false,
    },
    options = { locale: 'all' }
  ) {
    this._id = id
    this._name = name
    this._code = code
    this._perks = perks.map(p => new Perk(p, options))
    this._from = from ? DateTime.fromISO(from) : null
    this._to = to ? DateTime.fromISO(to) : null
    this._id = id
    this._qr = `${Config.transports.cp.api}qr?text=${code}&size=105`
    this._usageCount = usage_count
    this._amount = amount
    this._amountType = amount_type
    this._type = type
    this._used = used
    this._lastUsage = last_usage_at ? DateTime.fromISO(last_usage_at) : null
    this._isTransferable = is_transferable
    this._isTransferred = is_transferred
    this._childId = child_id
    // Do not create empty voucher !!!! Lead to infinity loop
    this._child = child ? new Voucher(child) : null

    this.locale = options.locale
  }

  get child() {
    return this._child
  }

  get childId() {
    return this._childId
  }

  get isTransferred() {
    return this._isTransferred
  }

  get isTransferable() {
    return this._isTransferable
  }

  get lastUsage() {
    return this._lastUsage
  }

  get used() {
    return this._used
  }

  get type() {
    return this._type
  }

  get amount() {
    return this._amount
  }

  get amount_type() {
    return this._amountType
  }

  get usageCount() {
    return this._usageCount
  }

  get qr() {
    return this._qr
  }

  get from() {
    return this._from
  }

  get to() {
    return this._to
  }

  get code() {
    return this._code
  }

  get name() {
    let lang = localStorage.getItem('lang') ?? 'all'

    return this._name.hasOwnProperty(lang) ?
      this._name[lang] :
      this._name['all']
  }

  get perks() {
    return this._perks
  }

  get id() {
    return this._id
  }
}
