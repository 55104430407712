export default {
  fqn: 'ifm.connect.externalWallet',
  endpoints: {
    externalWallet: {
      get: '/user/external-wallets',
      put: '/user/external-wallet/$id',
      delete: '/user/external-wallet/$id',
    },

    token: {
      post: '/user/external-wallet/token'
    },

    link: {
      post: '/user/$token/external-wallet/message',
      put: '/user/$token/external-wallet/$id'
    },

    sign: {
      post: '/user/$token/external-wallet/sign',
    }
  },

  transport: 'cp'
}
