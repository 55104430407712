import Brand from '../../brands/models/brand'

export default class PoapLocation {
  constructor(
    {
      id = null,
      slug = null,
      name = { all: null },
      description = { all: null },
      collectible_image = null,
      collection_delay = null,
      validate_geolocation = null,
      geolocation_validation_radius = null,
      discovery_radius = null,
      distance = null,
      in_range_for_collection = null,
      sayl_app_brand = null,
    },
    options = { locale: 'all' }
  ) {
    this._id = id
    this._slug = slug
    this._name = name ?? { all: null }
    this._description = description ?? { all: null }
    this._collectibleImage = collectible_image
    this._collectionDelay = collection_delay
    this._validateGeolocation = validate_geolocation
    this._geolocationValidationRadius = geolocation_validation_radius
    this._discoveryRadius = discovery_radius
    this._distance = distance
    this._inRangeForCollection = in_range_for_collection
    this._saylAppBrand = sayl_app_brand ? new Brand(sayl_app_brand) : new Brand({})

    this._locale = options?.locale ?? 'all'
  }

  get id() {
    return this._id
  }

  get slug() {
    return this._slug
  }

  get name() {
    let lang = localStorage.getItem('lang') ?? 'all'

    return this._name.hasOwnProperty(lang)
      ? this._name[lang]
      : this._name.all
  }

  get description() {
    let lang = localStorage.getItem('lang') ?? 'all'

    return this._description.hasOwnProperty(lang)
      ? this._description[lang]
      : this._description.all
  }

  get collectibleImage() {
    return this._collectibleImage
  }

  get validateGeolocation() {
    return this._validateGeolocation
  }

  get geolocationValidationRadius() {
    return this._geolocationValidationRadius
  }

  get discoveryRadius() {
    return this._discoveryRadius
  }

  get distance() {
    return this._distance
  }

  get isCollectible() {
    return this._inRangeForCollection
  }

  get saylAppBrand() {
    return this._saylAppBrand
  }
}
