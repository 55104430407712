import PoapLocation from './models/poap-location'
import Brand from '../brands/models/brand'
import { DateTime } from 'luxon'
import { isDevelopment } from '@/config'
import BlockchainConfig from '@/data/blockchain-config'

export default class Poap {
  static hederaNetwork = isDevelopment() ? 'testnet' : 'mainnet'

  constructor({
    id = null,
    poap_location = null,
    sayl_app_brand = null,
    created = null,
    transaction_id = null,
  }) {
    this._id = id
    this._poapLocation = poap_location ? new PoapLocation({...poap_location, sayl_app_brand}) : new PoapLocation({})
    this._saylAppBrand = sayl_app_brand ? new Brand(sayl_app_brand) : new Brand({})
    this._created = created ? DateTime.fromISO(created) : null
    this._transactionId = transaction_id
    this._chain = BlockchainConfig.find(c => c.values.includes(`hedera_${Poap.hederaNetwork}`))
  }

  get id() {
    return this._id
  }

  get poapLocation() {
    return this._poapLocation
  }

  get saylAppBrand() {
    return this._saylAppBrand
  }

  get created() {
    return this._created
  }

  get transactionId() {
    return this._transactionId
  }

  get explorerLink() {
    return this._chain.transactionLink(this.transactionId)
  }
}
