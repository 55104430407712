<script>
export default {
  name: 'ArrowIcon',

  props: {
    orientation: {
      type: String,
      default: 'left'
    }
  },

  computed: {
    classes() {
      return {
        'icon-arrow': true,
        '-left': this.orientation === 'left',
        '-right': this.orientation === 'right',
        '-top': this.orientation === 'top',
        '-bottom': this.orientation === 'bottom'
      }
    }
  }
}
</script>

<template>
  <svg :class="classes" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 1L2 7L8 13" stroke="black" stroke-width="2"/>
    <rect x="2" y="6" width="14" height="2" fill="black"/>
  </svg>
</template>
