export default [
  {
    component: () => import('./views/settings.vue'),
    path: '/settings',
    name: 'sayl-connect_user-settings',
    meta: {
      module: 'user',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/pin.vue'),
    path: '/settings/pin',
    name: 'sayl-connect_user-pin',
    meta: {
      module: 'user',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/key.vue'),
    path: '/settings/key',
    name: 'sayl-connect_user-key',
    meta: {
      module: 'user',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/external-wallets.vue'),
    path: '/settings/external-wallets',
    name: 'sayl-connect_user-external-wallets',
    meta: {
      module: 'user',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/profile.vue'),
    path: '/profile',
    name: 'sayl-connect_user-profile',
    meta: {
      module: 'user',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/sign.vue'),
    path: '/sign',
    name: 'sayl-connect_user-sign',
    meta: {
      module: 'user',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/link-wallet.vue'),
    path: '/settings/external-wallet/link',
    name: 'sayl-connect_user-link-wallet',
    meta: {
      module: 'user',
      requireAuth: true
    }
  },
]
