<script>
export default {
  name: 'IconHandbag',
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Handbag">
  <path id="Vector" d="M19.5749 6.75H4.42488C4.24033 6.75087 4.06242 6.81891 3.92439 6.94141C3.78636 7.06391 3.69766 7.23249 3.67488 7.41562L2.34363 19.4156C2.33179 19.5202 2.34208 19.6261 2.37384 19.7264C2.4056 19.8267 2.45811 19.9192 2.52796 19.9979C2.59781 20.0766 2.68344 20.1397 2.77928 20.1831C2.87513 20.2266 2.97903 20.2494 3.08426 20.25H20.9155C21.0207 20.2494 21.1246 20.2266 21.2205 20.1831C21.3163 20.1397 21.4019 20.0766 21.4718 19.9979C21.5416 19.9192 21.5942 19.8267 21.6259 19.7264C21.6577 19.6261 21.668 19.5202 21.6561 19.4156L20.3249 7.41562C20.3021 7.23249 20.2134 7.06391 20.0754 6.94141C19.9373 6.81891 19.7594 6.75087 19.5749 6.75V6.75Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path id="Vector_2" d="M8.25 9.75V6.75C8.25 5.75544 8.64509 4.80161 9.34835 4.09835C10.0516 3.39509 11.0054 3 12 3C12.9946 3 13.9484 3.39509 14.6517 4.09835C15.3549 4.80161 15.75 5.75544 15.75 6.75V9.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  </svg>
</template>
