export default {
  fqn: 'ifm.conn3ct.wallet',
  endpoints: {
    claimables: {
      get: '/user/nft/to_claim?limit=$limit',
      post: '/user/nft_transfer/$id/claim'
    },

    qr: {
      get: '/user/qr.svg'
    },

    wallet: {
      get: '/user/wallet?external_wallet_id=$externalWalletId&sayl_app_brand_id=$saylAppBrandId',
    },

    unconfirmed: {
      get: '/user/nft/unconfirmed?sayl_app_brand_id=$saylAppBrandId'
    },

    interactions: {
      post: '/user/interaction'
    },

    claims: {
      post: '/user/claims'
    },

    claimsPusher: {
      post: '/user/pusher/claims',
      delete: '/user/pusher/claims'
    },

    vouchers: {
      get: '/user/nft/vouchers'
    },

    transferVoucher: {
      post: '/user/nft/voucher/transfer',
      delete: '/user/nft/voucher/transfer',
    },

    getTransfersNft: {
      get: '/user/nft/transfers/revertable',
      post: '/user/nft/transfers?limit=$limit'
    },

    claimTransferNft: {
      get: '/user/nft/transfers/to_claim?limit=$limit',
      post: '/user/nft/transfer/$transferId/claim',
    },

    transferNft: {
      post: '/user/nft/$nftId/$tokenId/transfer'
    },

    cancelTransferNft: {
      post: '/user/nft/transfer/$transferId/cancel'
    }
  },

  transport: 'cp'
}
