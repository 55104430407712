
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 21H15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.26855 9.74998C5.2673 8.86084 5.44197 7.98023 5.78251 7.15888C6.12305 6.33753 6.62272 5.59167 7.25275 4.96426C7.88277 4.33685 8.6307 3.84028 9.45346 3.50317C10.2762 3.16605 11.1575 2.99503 12.0467 2.99998C15.7592 3.02811 18.731 6.11248 18.731 9.83436V10.5C18.731 13.8562 19.4342 15.8062 20.0529 16.875C20.1186 16.9888 20.1533 17.1179 20.1534 17.2493C20.1535 17.3808 20.1191 17.5099 20.0536 17.6238C19.9881 17.7378 19.8938 17.8325 19.7801 17.8985C19.6665 17.9645 19.5375 17.9995 19.406 18H4.59355C4.46212 17.9995 4.33312 17.9645 4.21948 17.8985C4.10583 17.8325 4.01153 17.7378 3.94601 17.6238C3.8805 17.5099 3.84608 17.3808 3.84619 17.2493C3.84631 17.1179 3.88096 16.9888 3.94667 16.875C4.56542 15.8062 5.26855 13.8562 5.26855 10.5V9.74998Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'NotificationBellIcon'
}
</script>
