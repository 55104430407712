import Vue from 'vue'
import { getInstance as getExternalWalletInstance } from '../externalWallet/instance'
import { getInstance as getLocalStorageInstance } from '../localStorage/instance'
import Nft from './models/wallet/nft/nft'
import { CurryApi } from '@spices/curry'
import config from './config'
import Wallet from './models/wallet/wallet'
import Claimable from './models/claimable'
import Voucher from './models/wallet/nft/voucher'
import NftTransfer from './models/wallet/nft-transfer'

let instance

const SAYL_WALLET = 'sayl'

/** Returns the current instance */
export const getInstance = () => instance

/** Creates an instance of the wallet controller. If one has already been created, it returns that instance */
export const useWallet = ({ transports, router }) => {
  if (instance) {
    return instance
  }

  instance = new Vue({
    data() {
      return {
        api: null,
        router: null,

        wallets: {},
        selectedWallet: null,
        claimables: [],
        unconfirmed: [],
        transfers: [],
        revertableTransfers: [],
        transfersToClaim: [],

        jwt: null,
        qr: null,
      }
    },

    computed: {
      nfts() {
        return this.wallet?.nfts ?? []
      },
    },

    methods: {
      async claim({ id }) {
        try {
          return await this.api.post({ type: 'claimables', payload: { id }})
        } catch(e) {
          throw e
        }
      },

      async claimAccess({ pin, claims }) {
        try {
          this.newInteraction({ name: 'api-claim-access' })

          let { data } = await this.api.post({ type: 'claims', payload: { item: { pin, claims }}})
          this.jwt = data

          return this.jwt
        } catch(e) {
          throw e
        }
      },

      async claimAccessPusher({ pin, claims }) {
        try {
          return await this.api.post({ type: 'claimsPusher', payload: { item: { pin, claims }}})
        } catch(e) {
          throw e
        }
      },

      async declineClaimAccessPusher() {
        try {
          return await this.api.delete({ type: 'claimsPusher', payload: {}})
        } catch(e) {
          throw e
        }
      },

      async getClaimables({ limit = 9999 }) {
        try {
          let { data } = await this.api.get({ type: 'claimables', payload: { limit }})
          this.claimables = this.$basil.get(data, 'data', []).map(c => new Claimable(c))

          return this.claimables
        } catch(e) {
          throw e
        }
      },

      async getQrCode() {
        try {
          this.newInteraction({ name: 'api-get-qr' })

          let { data } = await this.api.get({ type: 'qr' })
          this.qr = data

          return this.qr
        } catch(e) {
          throw e
        }
      },

      async getUnconfirmed() {
        try {
          this.newInteraction({ name: 'api-get-unconfirmed' })

          const saylAppBrandId = sessionStorage.getItem('saylAppBrandId')

          let { data } = await this.api.get({ type: 'unconfirmed', payload: { saylAppBrandId } })
          let unconfirmed = data?.filter(item => !this.wallet?.getDuplicate(item)) ?? []

          unconfirmed = unconfirmed.map(u => {
            u.nft = new Nft(u.nft)
            return u
          }).filter(u => !this.unconfirmed.find(v => v.id === u.id))

          this.unconfirmed = [...this.unconfirmed, ...unconfirmed]

          return this.unconfirmed
        } catch(e) {
          throw e
        }
      },

      async getVouchers() {
        try {
          let { data } = await this.api.get({ type: 'vouchers' })
          let res = []

          for(let id in data) {
            data[id]?.forEach(elem => {
              res.push({
                nft: new Nft(elem.nft),
                tokenId: elem.token_id,
                vouchers: elem.vouchers?.map(v => new Voucher(v))
              })
            })
          }

          return res
        } catch(e) {
          throw e
        }
      },

      async getWallet(invalidate = false, id) {
        try {
          const walletId = id ?? this.selectedWallet ?? SAYL_WALLET // To avoid to have null key in object
          const targetWallet = this.wallets[walletId]

          if(targetWallet && !invalidate && !targetWallet.requireReload()) {
            return targetWallet
          }

          const _id = walletId === SAYL_WALLET ? null : walletId
          const saylAppBrandId = sessionStorage.getItem('saylAppBrandId')

          let { data } = await this.api.get({ type: 'wallet', payload: { externalWalletId: _id, saylAppBrandId }})

          if(this.selectedWallet) {
            const externalWallets = getExternalWalletInstance()

            await externalWallets.getWallets()
          }

          this.wallets[walletId] = new Wallet(data)

          return this.wallets[walletId]
        } catch(e) {
          this.reset()
          throw e
        }
      },

      reset() {
        this.wallet = null
        this.wallets = {}
        this.selectedWallet = null

        this.transfers = []
        this.revertableTransfers = []
        this.transfersToClaim = []

        const externalWallets = getExternalWalletInstance()
        externalWallets.reset()
      },

      setWallet(walletId) {
        this.selectedWallet = walletId

        const localStorage = getLocalStorageInstance()
        localStorage.setSelectedWallet(walletId)
      },

      async transferVoucher({ id }) {
        try {
          let { data } = await this.api.post({ type: 'transferVoucher', payload: { item: { voucher_id: id }}})

          data = new Voucher(data)

          return data
        } catch(e) {
          throw e
        }
      },

      async revokeVoucherTransfer({ id }) {
        try {
          let { data } = await this.api.delete({ type: 'transferVoucher', payload: { data: { voucher_id: id }}})

          data = new Voucher(data)

          return data
        } catch(e) {
          throw e
        }
      },

      async transferNft({ nftId, tokenId, destination }) {
        try {
          let { data } = await this.api.post({ type: 'transferNft', payload: {
            nftId,
            tokenId,
            item: {
              destination
            }
          }})

          return data
        } catch(e) {
          throw e
        }
      },

      async getTransferNft({ limit = 9999 }) {
        try {
          let { data } = await this.api.post({ type: 'getTransfersNft', payload: { limit }})

          this.transfers = data?.map(t => new NftTransfer(t)) ?? []

          return this.transfers
        } catch(e) {
          throw e
        }
      },

      async getRevertableTransferNft() {
        try {
          let { data } = await this.api.get({ type: 'getTransfersNft' })

          this.revertableTransfers = data?.data?.map(t => new NftTransfer(t)) ?? []

          return this.revertableTransfers
        } catch(e) {
          throw e
        }
      },

      async getToClaimNft({ limit = 9999 }) {
        try {
          let { data } = await this.api.get({ type: 'claimTransferNft', payload: { limit }})

          this.transfersToClaim = data.data?.map(t => new NftTransfer(t)) ?? []

          return this.transfersToClaim
        } catch(e) {
          throw e
        }
      },

      async claimTransferNft({ transferId }) {
        try {
          let { data } = await this.api.post({ type: 'claimTransferNft', payload: { transferId }})

          return data
        } catch(e) {
          throw e
        }
      },

      async cancelTransferNft({ transferId }) {
        try {
          let { data } = await this.api.post({ type: 'cancelTransferNft', payload: { transferId }})

          return data
        } catch(e) {
          throw e
        }
      },

      async newInteraction({ name }) {
        try {
          return

          return await this.api.post({ type: 'interactions', payload: { item: { name }}})
        } catch(e) {
          throw e
        }
      },
    },

    created() {
      this.router = router
      this.api = new CurryApi({ config, transports })
    }
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const WalletPlugin = {
  install(Vue, options) {
    Vue.prototype.$wallet = useWallet(options)
  }
}
