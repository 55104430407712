<script>
export default {
  name: 'LogoutIcon'
}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.7844 12.5344L16.8469 16.4719C16.7029 16.6095 16.5117 16.6867 16.3125 16.6875C16.2159 16.6878 16.1203 16.6686 16.0312 16.6312C15.893 16.5753 15.7746 16.4794 15.6911 16.3559C15.6076 16.2323 15.5628 16.0866 15.5625 15.9375V12.75H9.75C9.55109 12.75 9.36032 12.671 9.21967 12.5303C9.07902 12.3897 9 12.1989 9 12C9 11.8011 9.07902 11.6103 9.21967 11.4697C9.36032 11.329 9.55109 11.25 9.75 11.25H15.5625V8.0625C15.5628 7.91336 15.6076 7.7677 15.6911 7.64413C15.7746 7.52055 15.893 7.42468 16.0312 7.36875C16.1682 7.31436 16.3179 7.30047 16.4625 7.32872C16.6071 7.35698 16.7405 7.42619 16.8469 7.52813L20.7844 11.4656C20.9252 11.6078 21.0042 11.7999 21.0042 12C21.0042 12.2001 20.9252 12.3922 20.7844 12.5344ZM9.75 19.5H4.5V4.5H9.75C9.94891 4.5 10.1397 4.42098 10.2803 4.28033C10.421 4.13968 10.5 3.94891 10.5 3.75C10.5 3.55109 10.421 3.36032 10.2803 3.21967C10.1397 3.07902 9.94891 3 9.75 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H9.75C9.94891 21 10.1397 20.921 10.2803 20.7803C10.421 20.6397 10.5 20.4489 10.5 20.25C10.5 20.0511 10.421 19.8603 10.2803 19.7197C10.1397 19.579 9.94891 19.5 9.75 19.5Z" fill="white"/>
  </svg>
</template>
