<script>
import NavPrimary from '@/components/navigation/primary.vue'
import NavHeader from '@/components/navigation/header.vue'
import NavAside from '@/components/navigation/aside.vue'
import Config from '@/config'

export default {
  name: 'LayoutBase',

  components: {
    NavHeader,
    NavAside,
    NavPrimary,
  },

  data() {
    return {
      qrVisible: false,
    }
  },

  watch: {
    '$route.name'() {
      this.qrVisible = false
    }
  },

  computed: {
    addToAppImage() {
      let image = this.isIos ? 'apple-wallet.svg' : 'wallet-passes.png'
      return this.$root.getSrc(`statics/images/${ image }`)
    },

    addToAppImageDesktop() {
      let image = 'apple-wallet.svg'
      return this.$root.getSrc(`statics/images/${ image }`)
    },

    passLink() {
      return `${ Config.transports.cp.api }user/pass`
    },
  },

  methods: {
    async init() {
      try {
        await this.$wallet.getQrCode()
      } catch(e) {
        $console.error(e)
      }
    },

    onCopy() {
      if(navigator.clipboard) {
        this.$wallet.newInteraction({ name: 'profile-copy-address' });
        navigator.clipboard.writeText(this.$basil.get(this.$user, 'user.walletAddress'))
          .then((r) => {
            this.$notification({
              title: this.$t('conn3ct-wallet.copy_to_clipboard_title'),
              type: this.$pepper.Intent.SUCCESS
            })
          })
          .catch((e) => $console.error('Async: Could not copy text: ', e));
      }
    },

    resize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    showQr() {
      this.qrVisible = true
    }
  },

  mounted() {
    window.addEventListener('resize', this.resize)
    this.resize()
    this.init()
  },

  beforeDestroy() {
    window?.removeEventListener('resize', this.resize)
  }
}
</script>

<template>
  <div class="layout-base">
    <header class="layout-base__header">
      <nav-header />
    </header>

    <div class="layout-base__body">
      <aside class="layout-base__aside">
        <nav-aside @showQr="showQr" />
      </aside>

      <main class="layout-base__route">
        <router-view />
      </main>
    </div>

    <footer class="layout-base__footer">
      <nav-primary @showQr="showQr" />
    </footer>

    <popins-modal
      :closable="false"
      position="middle-center"
      :size="$pepper.Size.S"
      :visible="qrVisible"
      scrollLocked
    >
      <template v-slot:header>
        <div class="qr-modal__header">
          <h2 class="qr-modal__title">{{ $t('conn3ct-wallet.qr_modal_title') }}</h2>

          <button
            @click="qrVisible = false"
            class="qr-modal__close"
          >
            <icon-cross />
          </button>
        </div>
      </template>

      <div>
        <div
          v-html="$wallet.qr"
          class="qr-modal__qr"
        ></div>

        <actions-button
          @click="onCopy"
          :appearance="$pepper.Appearance.SUBTLE"
          class="qr-modal__copy-button"
        >
          <div class="qr-modal__summary">
            <span class="address">{{ $basil.get($user, 'user.walletAddress') }}</span>

            <div class="qr-modal__copy-icon">
              <icon-copy />
            </div>
          </div>
        </actions-button>
      </div>

      <h2 class="qr-modal__download-pass-title">{{ $t(`conn3ct-wallet.download_pass_title_${ os }`) }}</h2>

      <section class="wallet-pass-modal__links">
        <actions-button
          :href="passLink"
          target="_blank"
          :appearance="$pepper.Appearance.LINK"
          :class="{
            'wallet-pass-modal__download-pass-action': true
          }"
        >
          <img
            :src="addToAppImage"
            class="wallet-pass-modal__download-pass-illustration"
          />
        </actions-button>

        <actions-button
          v-if="isDesktop"
          :href="passLink"
          target="_blank"
          :appearance="$pepper.Appearance.LINK"
          :class="{
            'wallet-pass-modal__download-pass-action': true
          }"
        >
          <img
            :src="addToAppImageDesktop"
            class="wallet-pass-modal__download-pass-illustration"
          />
        </actions-button>
      </section>

      <details class="wallet-pass-modal__instructions">
        <summary class="wallet-pass-modal__section-title">
          {{ $t('conn3ct-wallet.download_pass_instruction') }}

          <icon-carret
            class="icon"
            orientation="bottom"
          />
        </summary>

        <p
          v-html="$t(`conn3ct-wallet.download_pass_description_${ os }`)"
          class="wallet-pass-modal__download-pass-description"
        ></p>
      </details>
    </popins-modal>
  </div>
</template>
