export default [
  {
    component: () => import('./views/vouchers.vue'),
    path: '/vouchers',
    name: 'sayl-connect_vouchers-vouchers',
    meta: {
      module: 'vouchers',
      requireAuth: true
    }
  },
]
