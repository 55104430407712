export default class Social {
  constructor({
    type = null,
    url = null
  }) {
    this._type = type
    this._url = url
  }

  get type() {
    return this._type
  }

  get url() {
    return this._url
  }
}
