export default {
  fqn: 'ifm.conn3ct.notifications',
  endpoints: {
    notifications: {
      get: '/user/notifications?page=$page&limit=$limit',
      post: '/user/notifications/read'
    },

    unreadNotifications: {
      get: '/user/notifications/unread',
      post: '/user/notifications/$id/read'
    },

    unreadNotificationsCount: {
      get: '/user/notifications/unread/count'
    },
  },

  transport: 'cp'
}
