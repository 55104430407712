import { basil } from '@spices/basil'
import Attribute from './attribute'
import { DateTime } from 'luxon'

export default class SaylNft {
  constructor({
    id = null,
    name = null,
    description = null,
    attributes = [],
    modified = null,
  }) {
    this._id = id
    this._name = name
    this._description = description
    this._attributes = !basil.isNil(attributes) ? attributes.map(a => new Attribute(a)) : []
    this._modified = modified ? DateTime.fromISO(modified) : null
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  get description() {
    return this._description
  }

  get attributes() {
    return this._attributes
  }

  get modified() {
    return this._modified
  }
}
