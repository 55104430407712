import DataLayer from '../data'

export default ({ args, eventbus, i18n, logger, router, states, store, transports }) => {
  logger.group('bootstrap.cp');

  let cp = transports.cp;
  store.dispatch('bootstrap/status', states.APPLICATION_AUTH);

  return new Promise((resolve, reject) => {
    const dl = new DataLayer({ transports })
    eventbus.$dl = dl

    dl.bootstrapClientProject({ store, locale: i18n.locale })
      .then((config) => {
        i18n.setConfig(config.i18n)
        i18n.update()

        delete cp.defaults.headers.common['Cache-Control']

        logger.groupEnd('bootstrap.cp')
        return resolve()
      })
      .catch((error) => {
        logger.info('bootstrap.cp', error)
        logger.groupEnd('bootstrap.cp')
        reject(error)
      })
  })
}
