import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      l: state => state.bootstrap.i18n.locales,
      i18n: state => state.bootstrap.i18n
    }),

    locales() {
      let ret = this.l || []
      return ret.map(r => {
        return {
          label: this.$t(`conn3ct-wallet.lang_${r.lang}`),
          value: r.lang,
          active: r.lang === this.lang ?? 'en',
        }
      })
    },

    lang() {
      return this.i18n.locale.lang
    }
  },

  methods: {
    setLocale(lang) {
      let locale = this.l.find(l => l.lang === lang)
      this.i18n.setLocale(locale)
    },

    translate(field) {
      if(this.$basil.isNil(field) || typeof field === 'string') return field

      return field.hasOwnProperty(this.lang)
        ? field[this.lang]
        : field.all
    }
  }
}
