<script>
export default {
  name: 'UiPanel',

  computed: {
    hasFooter() {
      return !!this.$slots?.footer
    },

    hasHeader() {
      return !!this.$slots?.header
    }
  },
}
</script>

<template>
  <div class="ui-panel">
    <header v-if="hasHeader" class="ui-panel__header">
      <slot name="header"></slot>
    </header>

    <main class="ui-panel__main">
      <slot></slot>
    </main>

    <footer class="ui-panel__footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>
