import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import { Logger, Level } from '@spices/cayenne'
import { Anise, Field } from '@spices/anise'

import initApplication from './plugins/app'
import initBody from './plugins/body'
import initCP from './plugins/cp'
import initGinger from './plugins/ginger'
import badge from './plugins/badge'

import Error from '../helpers/error'
import { default as Config, isDevelopment } from '../config'

import eventbus from './eventbus'
import router from './router'
import store from './store'
import cp from './data/api/transports/cp'
import http from './data/api/transports/http'
import states from './states'

import i18n from './data/i18n/i18n'

import languageMixin from '@/mixins/language'
import osMixin from '@/mixins/os'

//
// Error Management
//

let bootstrap = {}

bootstrap.install = (Vue) => {
  Vue.mixin(languageMixin)
  Vue.mixin(osMixin)

  //
  // Badge
  //
  badge()

  //
  // Bugsnag
  //
  if (!isDevelopment()){
    Bugsnag.start({
      apiKey: 'f04d387f5ae0d83d81f63a4b9ebc073f',
      plugins: [
        new BugsnagPluginVue(),
      ],
      appType: 'client',
      appVersion: Config.version.client,
      releaseStage: Config.env
    })
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  }

  //
  // Args
  //
  let args = Anise.detect([
    // set Debug
    new Field({
      name: 'debug',
      fallback: 'off',
      persistent: true,
      search: /debug=(all|debug|info|warn|error)/
    }),

    // Lang
    new Field({
      name: 'lang',
      persistent: true,
      search: /lang=([\w]+)/,
    }),

    new Field({
      name: 'missing',
      persistent: true,
      search: /missing=(true|false)/
    })
  ])
  store.commit('bootstrap/setArgs', args)

  //
  // Logger
  //
  let logger = Logger.get()
  logger.level = Level.getFromName(args.debug.toUpperCase())
  logger.debug('args', args)
  Object.defineProperty(window, '$console', { get: () => logger })
  args.missing = args.missing === 'true' ? true : false

  //
  // Transports
  //
  let transports = {
    cp,
    http,
    getByName(name) {
      return this[name]
    }
  }

  //
  // Capacities
  //
  let capabilities = {
    args,
    eventbus,
    logger,
    router,
    states,
    store,
    transports,
    Vue
  }

  capabilities.i18n = new i18n({ locale: args.lang, transports, Vue, missing: args.missing })
  store.commit('bootstrap/setI18n', capabilities.i18n)

  //
  // Boostrap
  //
  Promise.resolve()
    .then(() => initApplication(capabilities))
    .then(() => initGinger(capabilities))
    .then(() => initCP(capabilities))
    .then(() => initBody(capabilities))
    // .then(() => initDatalayer(capabilities))
    // .then(() => initModules(capabilities))
    .then(() => {
      store.dispatch('bootstrap/status', states.APPLICATION_COMPLETE)
      store.commit('bootstrap/setLoading', false)
      Config.state = states.APPLICATION_COMPLETE
      // sayl.injekt.sp.apply('trackPageView')
    })
    .catch((error) => {
      let e = error

      if (!!error && !error.hasOwnProperty('slug')){
        e = Error.BOOTSTRAP_ERROR
        e.error = Object.assign({}, error)


        Bugsnag.notify(error)
      }
      logger.error(error)
      store.dispatch('bootstrap/error', e)
      // router.push({ name: 'errors_bootstrap' })
      //   .catch(() => {})
    })
}

const onError = (event) => {  }

window.addEventListener('error', onError)

export default bootstrap
