
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 16.6668H5C3.61917 16.6668 2.5 15.5477 2.5 14.1668V5.00016C2.5 4.07933 3.24583 3.3335 4.16667 3.3335H15.8333C16.7542 3.3335 17.5 4.07933 17.5 5.00016V14.1668C17.5 15.5477 16.3808 16.6668 15 16.6668Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5 9.5835H14.5833C14.1417 9.5835 13.7175 9.75933 13.405 10.0718L12.715 10.7618C12.4025 11.0743 11.9783 11.2502 11.5367 11.2502H8.9525C8.51083 11.2502 8.08667 11.0743 7.77417 10.7618L7.08333 10.0718C6.77083 9.75933 6.34667 9.5835 5.905 9.5835H2.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5 6.66667H2.5" stroke="#067384" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'WalletIcon'
}
</script>
