import Bugsnag from '@bugsnag/js'
import Config from '@/config'
import cp from './transports/cp'
import { basil } from '@spices/basil'

export default {
  bootstrap({ locale }) {
    let url = 'bootstrap'
    let args = ['convert=1']

    if (locale){
      args.push(`locale=${locale.iso}`)
    }

    args = args.join('&');
    url = [url, args].join('?');

    let timeout = setTimeout(() => Bugsnag.notify(new Error('It took more than 5 seconds to load the bootstrap.')), 5000)

    return new Promise((resolve, reject) => {
      cp.get(url)
        .then(response => {
          timeout != null && clearTimeout(timeout)
          let data = response.data

          if(data.sayl_app_brand?.id) {
            sessionStorage.setItem('saylAppBrandId', data.sayl_app_brand.id)
          }

          cp.defaults.baseURL = Config.transports.cp.api
          cp.defaults.headers.common['X-CSRF-TOKEN'] = data.csrf_token
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },

  keepAlive() {
    cp.get('keepalive')
      .then((response) => {
        cp.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf_token
      })
      .catch((error) => {
        Bugsnag.notify(error)
        console.error('bootstrap.api.keepalive errored with message:', error.message)
      })
  },

  logout() {
    return new Promise((resolve, reject) => {
      cp.defaults.baseURL = Config.transports.cp.api

      cp.get('/logout')
        .then((response) => {
          if(basil.get(response, 'data')) {
            cp.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf_token
          }
          resolve()
        })
        .catch((error) => {
          Bugsnag.notify(error)
          console.error('bootstrap.api.logout errored with message:', error.message)
          reject(error)
        })
    })
  }
}
