import Perk from "./perk"

export default class VoucherTemplate {
  constructor({
    amount = null,
    amount_type = null,
    type = null,
    perks = []
  }) {
    this._amount = amount
    this._amountType = amount_type
    this._type = type
    this._perks = perks.map(p => new Perk(p))
  }

  get amount() {
    return this._amount
  }

  get amountType() {
    return this._amountType
  }

  get type() {
    return this._type
  }

  get perks() {
    return this._perks
  }
}
