import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import Config from '@/config'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: Config.publicPath,
  routes,
})

router.beforeEach((to, from, next) => {
  const validActions = ['login', 'register']

  const { action, email, phone_number, uid } = to.query

  if(!validActions.includes(action)) {
    next()
    return
  }

  window.sessionStorage.setItem('navigationParams', JSON.stringify({ action, email, phoneNumber: phone_number, uid }))

  if(action === 'login') {
    next({ name: 'sayl-connect_landing-login' })
    return
  }

  if(action === 'register') {
    if(!phone_number) {
      next({ name: 'sayl-connect_landing-register' })
      return
    }

    next({ name: 'sayl-connect_landing-register-phone' })
    return
  }

  next()
})

export default router
