import { mix } from '@/factories'
import Cached from '@/factories/cached'
import Brand from './brand'

export default class Brands extends mix().with(Cached) {
  constructor(brands) {
    super()

    this.list = brands.map(b => new Brand(b))
  }
}
