import Vue from 'vue'
import UserController from './controller'
import Bootstrap from '@/bootstrap/data/api'
import User from './models/user'
import { CurryApi } from '@spices/curry'
import config from './config'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { getInstance as getWalletInstance } from '../wallet/instance'
import { getInstance as getChallengestInstance } from '../challenges/instance'

let instance

/** Returns the current instance */
export const getInstance = () => instance

/**
 * Creates an instance of the user controller.
 * If one has already been created, it returns that instance
 */
export const useUser = ({ transports, router }) => {
  if (instance) {
    return instance
  }

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        api: null,

        loading: false,

        initialized: false,

        ctrl: null,
        error: null,
        unlock: false,

        c: null,
        cLoading: true,
        uLoading: false,

        isAuthenticated: false,
        user: null,

        tutorialStep: null,
        // Total steps in the tutorial
        tutorialSteps: 6,

        terms: null
      }
    },

    computed: {
      walletAddress() {
        return this.$basil.get(this.user, 'walletAddress');
      }
    },

    methods: {
      changePassword({ confirmation, password, token }) {
        return this.ctrl.changePassword({ confirmation, password, token })
      },

      countries() {
        return new Promise((resolve, reject) => {
          this.cLoading = true
          if(this.$basil.isNil(this.c)) {
            this.ctrl.countries()
              .then((r) => resolve(this.c = r))
              .catch((e) => reject(e))
              .finally(() => this.cLoading = false)
          } else {
            this.cLoading = false
            resolve(this.c)
          }
        })
      },

      async getTerms() {
        if(this.terms) return this.terms

        try {
          this.terms = await this.ctrl.getTerms()

          return this.terms
        } catch {
          throw e
        }
      },

      getUser() {
        return new Promise((resolve, reject) => {
          this.loading = true
          this.ctrl.getUser()
            .then((u) => {
              this.user = u
              this.isAuthenticated = true

              if(u.seenTutorial === false) {
                this.tutorialStep = 1
              }

              this.$message.send('address', this.$basil.get(this.user, 'walletAddress'))
              this.$message.send('authenticated', true)
              resolve(this.user)
            })
            .catch((e) => {
              this.$message.send('address', null)
              this.$message.send('authenticated', false)
              reject(e)
            })
            .finally(() => this.loading = false)
        })
      },

      async login(user) {
        try {
          if(this.loading) throw 'already calling'

          const mode = user.email.includes('@') ? 'email' : 'phone'

          let u = null

          if(mode === 'email') {
            u = await this.ctrl.login(user)
          } else {
            u = await this.ctrl.loginPhone(user)
          }

          this.user = u
          this.isAuthenticated = true
          this.$message.send('ready', true)
          return this.user
        } catch(e) {
          throw e
        } finally {
          this.loading = false
        }
      },

      async logout(redirect = true, form = 'login') {
        try {
          this.loading = true

          await Bootstrap.logout()

          this.user = null
          this.isAuthenticated = false

          getWalletInstance().reset()
          getChallengestInstance().reset()

          if(redirect) {
            router.push({ name: `sayl-connect_landing-${ form }` }).catch(() => {})
          }

          return
        } catch(e) {
          $console.error(e)
          throw e
        } finally {
          this.loading = false
        }
      },

      pin(pin) {
        return new Promise((resolve, reject) => {
          this.ctrl.pin(pin)
            .then((r) => {
              this.unlock = true

              setTimeout(() => {
                this.unlock = false
              }, 60 * 60 * 1000)

              resolve()
            })
            .catch((e) => reject(e))
        })
      },

      register(payload) {
        return this.ctrl.register(payload)
      },

      requestPrivateKey(pin) {
        return this.ctrl.requestPrivateKey({ pin })
      },

      resendVerificationEmail() {
        return this.ctrl.resendVerificationEmail()
      },

      resendVerificationPhone() {
        return this.ctrl.resendVerificationPhone()
      },

      resetPassword({ email }) {
        return this.ctrl.resetPassword({ email })
      },

      setCountry(country) {
        return this.ctrl.setCountry(country)
      },

      setPin(pin) {
        return this.ctrl.setPin(pin)
      },

      setTerms(optin) {
        return this.ctrl.setTerms(optin)
      },

      setTutorialStatus() {
        return this.ctrl.setTutorialStatus()
          .then(() => this.getUser())
          .then((u) => Promise.resolve(u))
      },

      async setOnboardingStatus() {
        try {
          let { data } = await this.api.post({ type: 'onboarding' })
          this.user = new User(data)
          return data
        } catch(e) {
          throw e
        }
      },

      update(user) {
        user.phoneNumber = User.formatPhone(user)
        user.phoneNumber = user.phoneNumber

        if(user.phoneNumber && !isValidPhoneNumber(user.phoneNumber)) {
          throw {
            phone_number: ['invalid_phone_number_format']
          }
        }
        return this.ctrl.update(user)
      },

      updatePin({ newPin, newPinConfirm, oldPin }) {
        return this.ctrl.updatePin({ newPin, newPinConfirm, oldPin })
      },

      nextTutorialStep() {
        if(this.tutorialStep === this.tutorialSteps) {
          return this.skipTutorial()
        }

        this.tutorialStep++
      },

      async skipTutorial() {
        try {
          await this.setTutorialStatus()
          this.tutorialStep = null
        } catch(e) {
          $console.error(e)
        }
      },

      async verifyPhone({ code }) {
        try {
          return await this.api.get({ type: 'registerPhone', payload: { code } })
        } catch(e) {
          throw e
        }
      },

      async dismissWalletDownload() {
        try {
          let { data } = await this.api.post({ type: 'walletDownload' })

          this.user = new User(data)

          return this.user
        } catch(e) {
          throw e
        }
      },
    },

    created() {
      this.api = new CurryApi({ config, transports })
      this.ctrl = new UserController({ transports })
      this.initialized = true
    }
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const UserPlugin = {
  install(Vue, options) {
    Vue.prototype.$user = useUser(options)
  }
}

