export default [
  {
    component: () => import('./views/poaps.vue'),
    path: '/poaps',
    name: 'sayl-connect_poaps-poaps',
    meta: {
      module: 'poaps',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/poap.vue'),
    path: '/poap/:id',
    name: 'sayl-connect_poaps-poap',
    meta: {
      module: 'poaps',
      requireAuth: true
    }
  },

  {
    component: () => import('./views/poap.vue'),
    path: '/poap/collectible/:id',
    name: 'sayl-connect_poaps-collectible',
    meta: {
      module: 'poaps',
      requireAuth: true
    }
  },
]
