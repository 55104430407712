// src: https://infinitymobile.atlassian.net/wiki/spaces/~689657452/pages/3009970177/Blockchains

export default [
  {
    name: 'Hedera',
    value: 'hedera',
    values: ['sayl', 'hedera', 'native', 'hedera_mainnet'],
    explorer: 'https://app.dragonglass.me/hedera',
    nftLink(address) {
      return `${this.explorer}/tokens/${address}`
    },
    transactionLink(transactionId) {
      return `${ this.explorer }/transactions/${ transactionId.replaceAll(/\.|@+/g, '') }`
    }
  },

  {
    name: 'Hedera Testnet',
    value: 'hedera_testnet',
    values: ['hedera_testnet'],
    explorer: 'https://testnet.dragonglass.me/hedera',
    nftLink(address){
      return `${this.explorer}/tokens/${address}`
    },
    transactionLink(transactionId) {
      return `${ this.explorer }/transactions/${ transactionId.replaceAll(/\.|@+/g, '') }`
    }
  },

  {
    name: 'BSC',
    value: 'bsc',
    values: ['bsc'],
    explorer: 'https://bscscan.com',
    nftLink(address){
      return `${this.explorer}/address/${address}`
    }
  },

  {
    name: 'Ethereum',
    value: 'eth',
    values: ['eth', 'ethereum'],
    explorer: 'https://etherscan.io',
    nftLink(address){
      return `${this.explorer}/address/${address}`
    }
  },

  {
    name: 'Matic',
    value: 'matic',
    values: ['matic', 'polygon'],
    explorer: 'https://polygonscan.com',
    nftLink(address){
      return `${this.explorer}/address/${address}`
    }
  },

  {
    name: 'Polkadot',
    value: 'polkadot',
    values: ['polkadot'],
    explorer: 'https://explorer.polkascan.io/polkadot',
    nftLink(address){
      return `${this.explorer}/block/${address}`
    }
  },

  {
    name: 'Avalanche',
    value: 'avalanche',
    values: ['avalanche'],
    explorer: 'https://explorer.avax.network',
    nftLink(address){
      return `${this.explorer}/address/${address}`
    }
  },
]
