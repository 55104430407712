export default {
  data: () => {
    return {
      os: null
    }
  },

  computed: {
    isAndroid() {
      return this.os === 'android'
    },

    isIos() {
      return this.os === 'ios'
    },

    isMobile() {
      return this.isAndroid || this.isIos
    },

    isMac() {
      return this.os === 'mac_os'
    },

    isWindows() {
      return this.os === 'windows'
    },

    isLinux() {
      return this.os === 'linux'
    },

    isDesktop() {
      return this.isWindows || this.isLinux || this.isMac
    }
  },

  created() {
    let userAgent = window.navigator.userAgent
    let platform = window.navigator?.userAgentData?.platform || window.navigator.platform
    let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS']
    let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    let iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (macosPlatforms.indexOf(platform) !== -1) {
      this.os = 'mac_os';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      this.os = 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      this.os = 'windows';
    } else if (/Android/.test(userAgent)) {
      this.os = 'android';
    } else if (/Linux/.test(platform)) {
      this.os = 'linux';
    }
  }
}
