export { default as Wallet } from './wallet'
export { default as Nfts } from './nft/nfts'
export { default as Nft } from './nft/nft'
export { default as NftBrand } from './nft/brand'
export { default as NftMetadata } from './nft/nft_metadata'
export { default as Web3Trigger } from './nft/web3-trigger'

import Wallet from './wallet'

export default Wallet
