import { DateTime } from 'luxon'
import Wallet from './models/wallet'

export default class Dashboard {
  constructor({
    wallets = [],
    poaps_count = 0
  }) {
    this._wallets = (wallets ?? []).map(w => new Wallet(w))
    this._poapsCount = poaps_count ?? 0
    this._created = DateTime.now()
  }

  get wallets() {
    return this._wallets
  }

  get poapsCount() {
    return this._poapsCount
  }

  get totalMessages() {
    return this._wallets.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue?.unreadMessagesCount ?? 0)
    }, 0)
  }

  get totalVouchers() {
    return this._wallets.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue?.vouchersCount ?? 0)
    }, 0)
  }

  invalidate() {
    this._created = null
  }

  requireReload() {
    if(!this._created) return true

    let diff = DateTime.now().diff(this._created, 'minutes').toObject()

    if(diff.minutes >= 2) {
      return true
    }

    return false
  }
}
