import { DateTime } from 'luxon'

export default function(superClass) {
  return class extends superClass {
    constructor(args) {
      super(args)

      this._created = DateTime.now()
      this._cacheDuration = 10
    }

    get isCacheValid() {
      if(!this._created) return false

      const diff = DateTime.now().diff(this._created, 'minutes').toObject()

      if(diff.minutes >= this._cacheDuration) return false

      return true
    }

    invalidate() {
      this._created = null
    }
  }
}
