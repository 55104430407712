import LayoutBase from './base.vue'
import LayoutEntityList from './entity-list.vue'
import LayoutEntity from './entity.vue'
import LayoutPage from './page.vue'

const layouts = {}

layouts.install = Vue => {
  Vue.component('layout-base', LayoutBase)
  Vue.component('layout-entity-list', LayoutEntityList)
  Vue.component('layout-entity', LayoutEntity)
  Vue.component('layout-page', LayoutPage)
}

export default layouts
