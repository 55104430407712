export default {
  fqn: 'ifm.conn3ct.vouchers',

  endpoints: {
    web2Vouchers: {
      get: '/user/vouchers',
    },

    web3Vouchers: {
      get: '/user/nft/vouchers',
    }
  },

  transport: 'cp'
}
