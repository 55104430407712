import { CurryApi } from '@spices/curry'

import config from './config'

export default class MessagingController {
  constructor({ transports }) {
    this._api = new CurryApi({ config, transports })
  }

  async getOverview() {
    try {
      let r = await this._api.get({ type: 'overview' })

      return r.data
    } catch(e) {
      throw(e)
    }
  }

  async getMessagesList({ nftId, tokenId, limit = -1, page = 1 }) {
    try {
      let r = await this._api.get({ type: 'messages', payload: { nftId, tokenId, limit, page }})

      return r.data
    } catch(e) {
      throw e
    }
  }
}
