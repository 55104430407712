import { Nft } from "../../wallet/models/wallet"

export default class Wallet {
  constructor({
    id = null,
    address = null,
    type = 'sayl_wallet',
    chain = 'hedera',
    nft_count = 0,
    featured_nfts = [],
    unread_messages_count = 0,
    vouchers_count = 0,
    name = 'main_wallet'
  }) {
    this._id = id ?? null
    this._address = address ?? null
    this._type = type ?? 'sayl_wallet'
    this._chain = chain ?? 'hedera'
    this._nftCount = nft_count ?? 0
    this._featuredNfts = featured_nfts.map(featuredNft => new Nft(featuredNft))
    this._unreadMessagesCount = unread_messages_count ?? 0
    this._vouchersCount = vouchers_count ?? 0
    this._name = name ?? 'main_wallet'
  }

  get id() {
    return this._id
  }

  get address() {
    return this._address
  }

  get type() {
    return this._type
  }

  get chain() {
    return this._chain
  }

  get nftCount() {
    return this._nftCount
  }

  get featuredNfts() {
    return this._featuredNfts
  }

  get unreadMessagesCount() {
    return this._unreadMessagesCount
  }

  get vouchersCount() {
    return this._vouchersCount
  }

  get name() {
    return this._name
  }
}
