<script>
export default {
  name: 'LayoutEntityList',

  props: {
    empty: {
      type: Boolean,
      default: false
    },

    emptyActionLabel: {
      type: String | null,
      default: null
    },

    emptyDescription: {
      type: String | null,
      default: null
    },

    emptyTitle: {
      type: String | null,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onEmptyActionClick() {
      this.$emit('emptyAction')
    }
  }
}
</script>

<template>
  <div class="layout-entity-list">
    <div
      v-if="loading"
      class="layout-entity-list__loader"
    >
      <ui-loader size="l"></ui-loader>
    </div>

    <template v-else>
      <ui-panel
        v-if="empty && (emptyTitle || emptyDescription || emptyActionLabel)"
        class="layout-entity-list__empty"
      >
        <template v-slot:header>
          <h3
            v-if="emptyTitle"
            class="title"
          >{{ emptyTitle }}</h3>
        </template>

        <div class="body">
          <p
            v-if="emptyDescription"
          >{{ emptyDescription }}</p>

          <actions-button
            v-if="emptyActionLabel"
            @click="onEmptyActionClick"
            :appearance="$pepper.Appearance.PRIMARY"
            class="layout-entity-list__empty-cta"
          >{{ emptyActionLabel }}</actions-button>
        </div>
      </ui-panel>

      <div
        v-else
        class="layout-entity-list">
        <!-- TODO: add Filters component -->

        <div class="layout-entity-list__list">
          <slot></slot>
        </div>
      </div>
    </template>
  </div>
</template>
