import config from './config'
import { basil } from '@spices/basil'

// Object.defineProperty(this, entity, {
//   value: {
//     getValue: () => {
//       console.log('get', entity)
//       return this.parse(window.localStorage.getItem(entity))
//     },

//     setValue: (value) => {
//       if(basil.isNil(value)) {
//         return window.localStorage.removeItem(entity);
//       }

//       return window.localStorage.setItem(entity, JSON.stringify(value));
//     }
//   }
// });
export default class LocalStorageController {
  constructor() {
    for(let entity in basil.get(config, 'items', {})) {
      Object.defineProperty(this, entity, {
        get: () => {
          return this.parse(localStorage.getItem(entity))
        },

        set: (value) => {
          if(basil.isNil(value)) {
            return localStorage.removeItem(entity);
          }

          return localStorage.setItem(entity, JSON.stringify(value));
        }
      });
    }
  }

  // Methods
  parse(value) {
    try {
      return JSON.parse(value);
    } catch(e) {
      return value;
    }
  }
}
