import Vue from 'vue'
import Poap from './model'
import { CurryApi } from '@spices/curry'
import config from './config'
import PoapLocation from './models/poap-location'

let instance

export const getInstance = () => instance

export const usePoap = ({ transports }) => {
  if(instance) return instance

  instance = new Vue({
    data() {
      return {
        api: null,

        loading: true,
        collectedPoaps: [],
        discoveryPoaps: []
      }
    },

    methods: {
      async collect({ poapLocationId, long, lat }) {
        try {
          let { data } = await this.api.post({
            type: 'collect',
            payload: {
              item: {
                poap_location_id: poapLocationId,
                coordinates: {
                  long,
                  lat
                }
              }
            }
          })

          return data
        } catch(e) {
          throw e
        }
      },

      async discover({ long, lat, distance }) {
        try {
          let { data } = await this.api.get({ type: 'discover', payload: { long, lat, distance }})
          this.discoveryPoaps = (data ?? []).map(p => new PoapLocation(p))
          return this.discoveryPoaps
        } catch(e) {
          throw e
        }
      },

      async getCollected() {
        try {
          let { data } = await this.api.get({ type: 'collected' })
          this.collectedPoaps = data.map(poap => new Poap(poap))
          return this.collectedPoaps ?? []
        } catch(e) {
          throw e
        }
      },

    },

    created() {
      this.api = new CurryApi({ config, transports })
    }
  })

  return instance
}

export const PoapPlugin = {
  install(Vue, options) {
    Vue.prototype.$poaps = usePoap(options)
  }
}
