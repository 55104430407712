import { basil } from '@spices/basil'
import { CurryApi } from '@spices/curry'
import config from './config'
import User from './models/user'
import Country from './models/country'
import Config from '@/config'

/**
 * @class
 */
export default class UserController {
  /**
   * @param {Object} options
   * @param {Object} options.transports
   */
  constructor({ transports }) {
    this._api = new CurryApi({ config, transports })
    this._user = null
    this._countries = null
  }

  //////////////////////////////
  // Getters
  /**
   * @property {User} user
   * @readonly
   * @return {User}
   */
  get user() {
    return this._user
  }

  //////////////////////////////
  // Methods
  /**
   * Update the password of a user
   * Needs a valid token and a match between the 2 sent password to work
   *
   * @param {Object} data
   * @param {String} data.confirmation
   * @param {String} data.password
   * @param {String} data.token
   * @returns
   */
  changePassword({ confirmation, password, token }) {
    return new Promise((resolve, reject) => {
      this._api.put({ type: 'password', payload: { token, item: { password_confirmation: confirmation, password }}})
        .then((r) => resolve(r.data))
        .catch((e) => reject(e))
    })
  }

  /**
   * Get the countries
   *
   * @returns {Promise} Array of countries
   */
  countries() {
    return new Promise((resolve, reject) => {
      this._api.get({ type: 'countries' })
        .then((r) => Promise.resolve(r.data))
        .then((countries) => {
          let c = countries.map((c) => new Country(c))
            .sort((a, b) => {
              if (a.label > b.label) { return 1 }
              if (a.label < b.label) { return -1 }
              return 0
            })
          resolve(this._countries = c)
        })
        .catch((e) => reject(e))
    })
  }

  /**
   * Get the terms and conditions on the server
   * to display it as HTML
   *
   * @returns
   */
  getTerms() {
    return new Promise((resolve, reject) => {
      this._api.get({ type: 'terms' })
        .then((r) => resolve(r.data))
        .catch((e) => reject(e))
    })
  }

  /**
   * Get the user
   * Throw an error if
   *
   * @returns {Promise}
   */
  getUser() {
    return new Promise((resolve, reject) => {
      this._api.get({ type: 'entity', payload: {} })
        .then((r) => Promise.resolve(basil.get(r, 'data', null)))
        .then((u) => {
          if(u) {
            return resolve(this._user = new User(u))
          }

          return reject()
        })
        .catch((e) => reject(e))
    })
  }

  /**
   * Authenticate the user in sayl
   *
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.password
   * @returns
   */
  login({ email, password }) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'login', payload: { item: { email, password } }})
        .then((r) => Promise.resolve(r.data))
        .then((user) => resolve(this._user = new User(user)))
        .catch((e) => reject(e))
    })
  }

  loginPhone({ email, password }) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'loginPhone', payload: { item: { email, phone_number: email, password } }})
        .then((r) => Promise.resolve(r.data))
        .then((user) => resolve(this._user = new User(user)))
        .catch((e) => reject(e))
    })
  }

  /**
   * Logout the sayl user (remove it from cache)
   *
   * @returns
   */
  logout() {
    return new Promise((resolve, reject) => {
      this._api.get({ type: 'logout', payload: {} })
        .then(() => resolve())
        .catch((e) => reject(e))
    })
  }

  /**
   * Verify the pin for validation
   *
   * @param {String} pin
   * @returns
   */
  pin(pin) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'pin', payload: { item: { pin }}})
        .then((v) => resolve(v))
        .catch((e) => reject(e))
    })
  }

  /**
   * Register the user in sayl
   *
   * @param {Object} data
   * @param {String} data.confirmation
   * @param {String} data.email
   * @param {String} data.password
   * @returns
   */
  register({ confirmation, email, password, phone, lang, identifier }) {
    let devEnvs = ['development', 'next']

    let type = email ? 'register' : 'registerPhone'
    let override = devEnvs.includes(Config.env) ? true : false

    if(!basil.isNil(identifier)) {
      return new Promise((resolve, reject) => {
        this._api.post({ type: 'registerPassword', payload: { override, item: { identifier, password, password_confirmation: confirmation, lang } }})
          .then((r) => Promise.resolve(r.data))
          .then((user) => {
            resolve(this._user = new User(user))
          })
          .catch((e) => reject(e))
      })
    }

    return new Promise((resolve, reject) => {
      this._api.post({ type: type, payload: { override, item: { email, password, password_confirmation: confirmation, phone_number: phone, lang } }})
        .then((r) => Promise.resolve(r.data))
        .then((user) => {
          resolve(this._user = new User(user))
        })
        .catch((e) => reject(e))
    })
  }

  requestPrivateKey({ pin }) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'privateKey', payload: { item: { pin }}})
        .then((k) => Promise.resolve(k.data))
        .then((k) => resolve(k))
        .catch((e) => reject(e))
    })
  }

  /**
   * @returns {Promise}
   */
  resendVerificationEmail() {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'email', payload: {} })
        .then((v) => Promise.resolve(v.data))
        .then((v) => resolve(v))
        .catch((e) => reject(e))
    })
  }

  resendVerificationPhone() {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'phone', payload: {} })
        .then((v) => Promise.resolve(v.data))
        .then((v) => resolve(v))
        .catch((e) => reject(e))
    })
  }

  /**
   * Reset the user password
   *
   * @param {Object} data
   * @param {String} data.email
   * @returns
   */
  resetPassword({ email }) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'password', payload: { item: { email: email.replaceAll(' ', ''), } }})
        .then((r) => Promise.resolve(r.data))
        .then((user) => {
          resolve(this._user = new User(user))
        })
        .catch((e) => reject(e))
    })
  }

  /**
   * Set the country of the user
   *
   * @param {String} id
   * @returns
   */
  setCountry(id) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'country', payload: { item: { country_id: id }}})
        .then((v) => resolve(v))
        .catch((e) => reject(e))
    })
  }

  /**
   * Create the first pin for the user
   *
   * @param {String} pin
   * @returns
   */
  setPin(pin) {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'createPin', payload: { item: { pin }}})
        .then((v) => resolve(v))
        .catch((e) => reject(e))
    })
  }

  /**
   * Set the optin for the user to true
   *
   * @returns
   */
  setTerms() {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'terms', payload: { item: { optin: true }}})
        .then((v) => resolve(v))
        .catch((e) => reject(e))
    })
  }


  /**
   * Update the user on the server
   *
   * @param {Object} data
   * @param {String} data.firstname
   * @param {String} data.lastname
   * @param {String} data.email
   * @param {String} data.lang
   * @param {String} data.phoneNumber
   * @param {String} data.dateOfBirth
   * @returns
   */
  update({ firstname = null, lastname = null, email = null, lang = 'en', phoneNumber = null }) {
    return new Promise((resolve, reject) => {
      let item = {
        firstname,
        lastname,
        email,
        lang,
        phone_number: phoneNumber?.replaceAll(' ', ''),
      }

      this._api.put({ type: 'update', payload: { item }})
        .then((r) => Promise.resolve(r.data))
        .then((u) => resolve(this._user = new User(u)))
        .catch((e) => reject(e))
    })
  }

  updatePin({ newPin, newPinConfirm, oldPin}) {
    return new Promise((resolve, reject) => {
      let item = {
        pin: newPin,
        pin_confirmation: newPinConfirm,
        current_pin: oldPin
      }

      this._api.put({ type: 'pin', payload: { item }})
        .then((r) => resolve(r))
        .catch((e) => reject(e))
    })
  }

  setTutorialStatus() {
    return new Promise((resolve, reject) => {
      this._api.post({ type: 'tutorial'})
        .then((v) => resolve(v))
        .catch((e) => reject(e))
    })
  }
}
