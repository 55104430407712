<script>
export default {
  name: 'TransferIcon',
}
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 11H14M2 11L5 8M2 11L5 14M11 2L14 5M14 5L11 8M14 5L2 5" stroke="#131415" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
