<script>
import navigationData from '@/data/navigation'

export default {
  name: 'NavAside',

  computed: {
    menuItems() {
      return navigationData.filter(i => i.desktop)
    },

    emailVerified() {
      return this.$user.user?.emailVerified ?? false
    },
  },

  methods: {
    onShowQr() {
      this.$wallet.newInteraction({ name: 'navigation-show-qr' });
      this.$emit('showQr')
    },
  },
}
</script>

<template>
  <nav class="nav-aside">
    <div class="nav-aside__menu">
      <router-link
        v-for="(item, i) in menuItems"
        class="nav-aside__menu-item"
        :key="i"
        :to="{ name: item.route }"
      >
        <component
          class="icon"
          :is="item.icon"
        />

        <span class="label">{{ $t(item.label) }}</span>
      </router-link>

      <div class="nav-aside__menu-item">
        <button
          @click="onShowQr"
          class="nav-aside__qr-button"
        >
          <icon-qr class="qr-icon" />
        </button>
      </div>
    </div>

    <div class="nav-aside__footer">
      <router-link
        class="nav-aside__menu-item"
        :to="{ name: 'sayl-connect_user-settings' }"
      >
        <icon-cog class="icon" />

        <span class="label">{{ $t('conn3ct-wallet.route_settings') }}</span>
      </router-link>
    </div>
  </nav>
</template>
