<template>
  <div class="error">
    <div class="error__card">
      <h1 class="error__title">404 Not found</h1>
      <p class="error__description">The page you are looking for cannot be found. Click the button below to return to the home page</p>

      <actions-button
        class="error__action"
        :appearance="$pepper.Appearance.PRIMARY"
        icon-post="redo"
        :size="$pepper.Size.S"
        @click="onReload"
      >Go to the home page</actions-button>
    </div>

    <div class="error__card">
      <h1 class="error__title">404 Non trouvé</h1>
      <p class="error__description">La page que vous recherchez est introuvable. Cliquez sur le bouton ci-dessous pour revenir à la page d'accueil</p>

      <actions-button
        class="error__action"
        :appearance="$pepper.Appearance.PRIMARY"
        icon-post="redo"
        :size="$pepper.Size.S"
        @click="onReload"
      >Aller à la page d'accueil</actions-button>
    </div>

    <div class="error__card">
      <h1 class="error__title">404 Niet Gevonden</h1>
      <p class="error__description">De pagina die u zoekt kan niet worden gevonden. Klik op de onderstaande knop om terug te keren naar de startpagina</p>

      <actions-button
        class="error__action"
        :appearance="$pepper.Appearance.PRIMARY"
        icon-post="redo"
        :size="$pepper.Size.S"
        @click="onReload"
      >Ga naar de startpagina</actions-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onReload() {
      this.$router.push({ name: 'sayl-connect_user-home' })
    }
  }
}
</script>
