<script>
export default {
  name: 'ViewBlockedOrigin',
}
</script>

<template>
  <layout-page class="module-user blocked-origin">
    <ui-panel class="blocked-origin__panel">
      <template v-slot:header>
        <h5 class="title">{{ $t('conn3ct-wallet.origin_blocked_title') }}</h5>
      </template>

      <p class="body">{{ $t('conn3ct-wallet.origin_blocked_description', { origin: $message.origin }) }}</p>
    </ui-panel>
  </layout-page>
</template>
