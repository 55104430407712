export default {
  fqn: 'ifm.conn3ct.brands',
  endpoints: {
    entity: {
      get: '/brand/$id',
    },

    list: {
      get: '/user/brands',
    },
  },

  transport: 'cp'
}
