import App from '@/App'
import Pepper from '@spices/pepper'
import Datalayer from '@/datalayer'
import Icons from '@/icons'
import Layouts from '@/layouts'
import MixinImage from '@/helpers/image'

import { basil as $basil, install as Basil } from '@spices/basil'
import { install as basilI18n } from '@spices/basil-i18n'

let instance

export default ({ eventbus, i18n, logger, router, store, transports, Vue }) => {
  logger.group('bootstrap.app')
  // Plugins
  Vue.use(Pepper)
  Vue.use(Basil)
  Vue.use(Datalayer, { router, transports })
  Vue.use(Icons)
  Vue.use(Layouts)
  $basil.use(basilI18n, { locale: { persistent: true }})

  // Accessors
  Object.defineProperty(Vue.prototype, '$bus', {
    get: () => eventbus
  })

  return new Promise((resolve) => {
    instance = new Vue({
      mixins: [MixinImage],
      router,
      store,
      i18n: i18n.i18n,
      provide(){ return {} },
      render: h => h(App)
    }).$mount('#app')

    logger.groupEnd('bootstrap.app')
    return resolve()
  })
}

export { instance }
