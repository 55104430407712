import { DateTime } from 'luxon'
import Perk from './perk'
import { basil } from '@spices/basil'

/**
 * @class
 */
export default class Web3Trigger {
  /**
   *
   * @param {Object} data
   * @param {String} data.id
   * @param {Object} data.name
   * @param {Object} data.perk
   *
   * @param {Object} options
   * @param {String} options.locale
   */
  constructor(
    {
      id = null,
      name = { all: null },
      perk = {},
      from = null,
      to = null,
    },
    options = { locale: 'all' }
  ) {
    // Datas
    this._id = id
    this._name = name
    this._perk = new Perk(perk)
    this._from = from ? DateTime.fromISO(from) : null
    this._to = to ? DateTime.fromISO(to) : null

    // Options
    this._locale = options.locale
  }

  get totalLimit() {
    return this._perk.totalLimit
  }

  get frequencyLimit() {
    return this._perk.frequencyLimit
  }

  get frequencyUnit() {
    return this._perk.frequencyUnit
  }

  get from() {
    return this._from
  }

  get to() {
    return this._to
  }

  get isActive() {
    if(!this._from || !this._to) return true

    let lowerDiff = DateTime.now().diff(this._from).toObject()
    let highterDiff = DateTime.now().diff(this._to).toObject()

    if(lowerDiff.milliseconds > 0 && highterDiff.milliseconds < 0) {
      return true
    }

    return false
  }

  get hasTimeRestriction() {
    return !!(this._from && this._to)
  }

  get hasLimit() {
    if(this._triggerLimit === null) return false

    if(this._triggerLimit.triggerAmount || (this._triggerLimit.frequencyCount && this._triggerLimit.frequencyUnit)) {
      return true
    }

    return false
  }

  get hasTotalLimit() {
    return this._perk.hasTotalLimit
  }

  get hasFrequencyLimit() {
    const unit = basil.get(this._triggerLimit, 'frequencyUnit')
    const count = basil.get(this._triggerLimit, 'frequencyCount')

    return (!basil.isNil(unit) && !basil.isNil(count)) ? true : false
  }

  ////////////////////////////////
  // Getters
  /**
   * @property {String} id
   * @readonly
   * @return {String} id
   */
  get id() {
    return this._id
  }

  /**
   * @property {String} name
   * @readonly
   * @return {String} name
   */
  get name() {
    let lang = localStorage.getItem('lang') ?? 'all'

    return this._name.hasOwnProperty(lang) ?
      this._name[lang] :
      this._name['all']
  }

  /**
   * @property {Perk} perk
   * @readonly
   * @return {Perk} perk
   */
  get perk() {
    return this._perk
  }
}
