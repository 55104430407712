import Message from './message'
import Nft from '../../wallet/models/wallet/nft/nft'

export default class Overview {
  constructor({
    unreads_count = 0,
    last_message = null,
    nft = null
  }) {
    this._unreadsCount = unreads_count
    this._lastMessage = new Message(last_message)
    this._nft = new Nft(nft)
  }

  get unreadsCount() {
    return this._unreadsCount
  }

  get lastMessage() {
    return this._lastMessage
  }

  get nft() {
    return this._nft
  }
}
