<template>
  <div>LOGOUT</div>
</template>

<script>
export default {
  name: 'Logout',

  mounted() {
    this.$pusher.disconnect()
    this.$user.logout()
  }
}
</script>
