import { mix } from '@/factories'
import Cached from '@/factories/cached'
import Challenge from './challenge'

export default class Challenges extends mix().with(Cached) {
  constructor(challenges) {
    super()

    this.list = challenges.map(v => new Challenge(v))
  }
}
