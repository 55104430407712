import dashboardRouter from './dashboard'
import walletRouter from './wallet'
import userRouter from './user'
import onboardingRouter from './onboarding'
import landingRouter from './landing'
import challengesRouter from './challenges'
import poapsRouter from './poaps'
import vouchersRouter from './vouchers'
import messagingRouter from './messaging'

import { authGuard } from '@/datalayer/user/guard'

export default [
  {
    component: () => import('@/layouts/base.vue'),
    path: '/',
    beforeEnter: authGuard,
    redirect: { name: 'sayl-connect_dashboard-dashboard' },
    children: [
      ...dashboardRouter,
      ...walletRouter,
      ...userRouter,
      ...challengesRouter,
      ...poapsRouter,
      ...vouchersRouter,
      ...messagingRouter,
    ]
  },

  ...onboardingRouter,
  ...landingRouter
]
