/**
 *
 * @class
 */
export default class Metadata {
  /**
   * @constructor
   *
   * @param {Object} options
   * @param {String} options.description
   * @param {String} options.image
   * @param {String} options.name
   * @param {Object} options.properties
   * @param {String} options.version
   */
  constructor({
    description = null,
    image = null,
    name = null,
    properties = [],
    version = null
  }) {
    this._description = description
    this._image = image
    this._name = name
    this._properties = properties
    this._version = version
  }

  //////////////////////////////
  // Getters
  /**
   * @property {String} description
   * @readonly
   * @return {String} description
   */
  get description() {
    return this._description
  }

  /**
   * @property {String} image
   * @readonly
   * @return {String} image
   */
  get image() {
    return this._image
  }

  /**
   * @property {String} name
   * @readonly
   * @return {String} name
   */
  get name() {
    return this._name
  }

  /**
   * @property {Array} properties
   * @readonly
   * @return {Array} properties
   */
  get properties() {
    return this._properties
  }


  /**
   * @property {String} version
   * @readonly
   * @return {String} version
   */
  get version() {
    return this._version
  }

  //////////////////////////////
  // Methods
}
