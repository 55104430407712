import { DateTime } from 'luxon'

/**
 * @class
 */
export default class Notification {
  /**
   *
   * @param {Object} data
   * @param {String} data.action
   * @param {Object} data.created
   * @param {String} data.id
   * @param {Boolean} data.is_read
   * @param {String} data.message - TC Key to translate
   * @param {Object} data.modified
   * @param {Object} data.read_at
   * @param {Object} data.related_entity
   * @param {String} data.related_entity_id
   * @param {String} data.sayl_app_user_id
   * @param {String} data.slug
   * @param {String} data.title - TC key to translate
   * @param {Object} data.variables - Variable to inject in the message to be translated by i18n
   */
  constructor({
    action = null,
    created = null,
    id = null,
    is_read = false,
    message = null,
    modified = null,
    read_at = null,
    related_entity = null,
    related_entity_id = null,
    sayl_app_user_id = null,
    slug = null,
    title = null,
    variables = {}
  }) {
    this._action = action
    this._created = created ? DateTime.fromISO(created) : null
    this._id = id
    this._isRead = is_read
    this._message = message
    this._modified = modified
    this._readAt = read_at
    this._relatedEntity = related_entity
    this._relatedEntityId = related_entity_id
    this._saylAppUserId = sayl_app_user_id
    this._slug = slug
    this._title = title
    this._variables = variables
  }

  ////////////////////////////////
  // Getters
  /**
   * @property {String} action
   * @readonly
   * @return {String} action
   */
  get action() {
    return this._action
  }

  /**
   * @property {Object} created
   * @readonly
   * @return {Object} created
   */
  get created() {
    return this._created
  }

  /**
   * @property {String} id
   * @readonly
   * @return {String} id
   */
  get id() {
    return this._id
  }

  /**
   * @property {Boolean} isRead
   * @readonly
   * @return {Boolean} isRead
   */
  get isRead() {
    return this._isRead
  }

  /**
   * @property {String} message
   * @readonly
   * @return {String} message
   */
  get message() {
    return this._message
  }

  /**
   * @property {String} modified
   * @readonly
   * @return {String} modified
   */
  get modified() {
    return this._modified
  }

  /**
   * @property {Object} readAt
   * @readonly
   * @return {Object} readAt
   */
  get readAt() {
    return this._readAt
  }

  /**
   * @property {Object} relatedEntity
   * @readonly
   * @return {Object} relatedEntity
   */
  get relatedEntity() {
    return this._relatedEntity
  }

  /**
   * @property {Object} relatedEntityId
   * @readonly
   * @return {Object} relatedEntityId
   */
  get relatedEntityId() {
    return this._relatedEntityId
  }

  /**
   * @property {String} saylAppUserId
   * @readonly
   * @return {String} saylAppUserId
   */
  get saylAppUserId() {
    return this._saylAppUserId
  }

  /**
   * @property {String} slug
   * @readonly
   * @return {String} slug
   */
  get slug() {
    return this._slug
  }

  /**
   * @property {String} title
   * @readonly
   * @return {String} title
   */
  get title() {
    return this._title
  }

  /**
   * @property {Object} variables
   * @readonly
   * @return {Object} variables
   */
  get variables() {
    return this._variables
  }
}
