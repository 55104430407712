export default class Attribute {
  constructor({
    label = null,
    position = null,
    type = null
  }) {
    this._label = label
    this._position = position
    this._type = type
  }

  get label() {
    return this._label
  }

  get position() {
    return this._position
  }

  get type() {
    return this._type
  }
}
