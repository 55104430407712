export default {
  fqn: 'ifm.conn3ct.messages',
  endpoints: {
    origin: {
      get: '/post-message?origin=$origin',
    }
  },

  transport: 'cp'
}
