export default class Image {
  constructor({
    id = null,
    customer_id = null,
    url = { all: null },
    entity_type = null,
    field_name = null,
    title = { all: null },
    alt = { all: null },
    description = { all: null },
    position = null,
    entity_ids = [],
  }) {
    this._id = id
    this._url = url ?? { all: null }
    this._title = title ?? { all: null }
    this._alt = alt ?? { all: null }
    this._fieldName = field_name
  }

  get id() {
    return this._id
  }

  get url() {
    return this._url
  }

  get title() {
    return this._title
  }

  get alt() {
    return this._alt
  }

  get fieldName() {
    return this._fieldName
  }
}
