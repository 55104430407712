
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5062 7.56562C18.7319 7.88875 18.9295 8.23063 19.0968 8.5875L21.5249 9.9375C21.8281 11.2954 21.8313 12.7032 21.5343 14.0625L19.0968 15.4125C18.9295 15.7694 18.7319 16.1112 18.5062 16.4344L18.553 19.2187C17.524 20.1565 16.306 20.8629 14.9812 21.2906L12.5905 19.8562C12.1973 19.8844 11.8025 19.8844 11.4093 19.8562L9.02803 21.2812C7.69894 20.8613 6.47699 20.1576 5.44678 19.2187L5.49366 16.4437C5.26985 16.1161 5.07235 15.7713 4.90303 15.4125L2.47491 14.0625C2.17172 12.7046 2.16852 11.2968 2.46553 9.9375L4.90303 8.5875C5.07036 8.23063 5.26796 7.88875 5.49366 7.56562L5.44678 4.78125C6.47583 3.84353 7.69376 3.13706 9.01865 2.70937L11.4093 4.14375C11.8025 4.11562 12.1973 4.11562 12.5905 4.14375L14.9718 2.71875C16.3009 3.13865 17.5228 3.84239 18.553 4.78125L18.5062 7.56562Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CogIcon'
}
</script>
