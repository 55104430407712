export default class Grant {
  constructor(
    {
      type = null,
      subtype = null,
      message = { all: null }
    },
    options = { locale: 'all' }
  ) {
    this._type = type
    this._subtype = subtype
    this._message = message

    this._locale = options.locale
  }

  get type() {
    return this._type
  }

  get subtype() {
    return this._subtype
  }

  get message() {
    let lang = localStorage.getItem('lang') ?? 'all'

    return this._name.hasOwnProperty(lang) ?
      this._message[lang] :
      this._message[lang]
  }
}
