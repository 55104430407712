import NotFound from './views/errors/notfound'
import Bootstrap from './views/errors/bootstrap'

const routes = [
  {
    name: 'errors_bootstrap',
    path: '/conn3ct-wallet/errors/bootstrap',
    component: Bootstrap
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  },
]

export default routes
